<template>
  <div class="BidDisplay">
    <span class="title">
      <span v-if="!shared.gameConfig.hideBidInPlay || shared.game.state !== 'play'">Bid {{ bidDisplayNum }}</span>
      <span v-if="showMeld">{{ showForTeam ? 'Team' : '' }} Meld {{ meldDisplay }}</span>
      <span v-if="shared.game.state === 'play' && !shared.gameConfig.scoreByPoints">Won {{ tricks }}</span>
      <span v-if="shared.game.state === 'play' && shared.gameConfig.scoreByPoints">{{ showForTeam ? 'Team' : '' }} Taken {{ pointsDisplay }}</span>
    </span>
    <div
      v-if="showBidCircles"
      class="bids"
      :class="{roundFinished: shared.game.roundFinished}"
    >
      <template v-for="n in bidNum">
        <i
          :key="'ok'+n"
          :class="'el-icon-' + (n > tricks ? 'remove-outline' : 'circle-check')"
        ></i>
      </template>
      <template v-for="n in extraTricks">
        <i
          :key="'over'+n"
          class="el-icon-circle-plus-outline"
        ></i>
      </template>
    </div>
    <!-- 
    <span
      v-if="!lastNum"
      v-text="bid === 0 ? 0 : '-'"
    >
    </span> -->
  </div>
</template>

<script>


export default {
  name: "BidDisplay",
  props: {
    bid: [String, Number],
    meld: Number,
    tricks: Number,
    points: Number,
    showForTeam: Boolean
  },
  data: function () {
    return {
    };
  },
  computed: {
    shared() {
      return this.$root.shared;
    },
    bidDisplayNum() {
      return this.bid === '' || isNaN(this.bid) ? this.bid || '-' : this.bidNum;
    },
    bidNum() {
      // bid could be empty string
      return +this.bid || 0;
    },
    showBidCircles() {
      return this.shared.gameConfig.showBidCircles;
    },
    showMeld() {
      return this.shared.gameConfig.useMeld && (this.shared.game.state === 'meld' || this.shared.game.state === 'play');
    },
    meldDisplay() {
      if (this.meld === 0) return 0;
      return this.meld || '-';
    },
    pointsDisplay() {
      if (this.points === 0) return 0;
      return this.points || '-';
    },
    extraTricks() {
      return this.tricks > this.bidNum ? (this.tricks - this.bidNum) : 0;
    },
    lastNum() {
      return this.tricks > this.bidNum ? this.tricks : this.bidNum;
    }
  },
  methods: {
  }
};
</script>

<style lang="less">
.BidDisplay {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  .title {
    padding: 0 1em;
    white-space: normal;
    span {
      white-space: nowrap;
    }
  }

  i {
    font-size: 1.4rem;
    margin-top: 3px;
    font-weight: bold;

    &.el-icon-remove-outline {
      color: #e0e0e0;
    }

    &.el-icon-circle-check {
      color: #4a993e;
    }

    &.el-icon-circle-plus-outline {
      color: #d40600;
    }
  }

  .roundFinished {
    i.el-icon-remove-outline {
      color: #ed9b9f;
    }
  }

  .el-steps {
    flex-wrap: wrap;
    justify-content: center;
  }

  .el-step {
    margin: 0 0 3px 0;
    min-width: 24px;
  }

  .el-step__title {
    line-height: normal;
  }

  .el-step__line {
    display: none; // don't need it
  }
}
</style>
