export default {
    cardsInSuites: {
        first: 9,
        last: 13
    },
    cardName(key) {
        var number = +(key.split('_')[0]).substr(1);
        if (number > 1 && number < 11) {
            return number;
        }
        switch (number) {
            case 1:
                return 'Ace';
            case 11:
                return 'Jack';
            case 12:
                return 'Queen';
            case 13:
                return 'King';
        }
        return '?';
    },
    cardDesc(key) {
        var suit = key[0];
        return `${this.cardName(key)} of ${this.suits[suit].display}`;
    },
    makeCards() {
        var cards = {};
        var suitArray = Object.entries(this.suits);
        suitArray.sort((a, b) => a[1].sort > b[1].sort ? 1 : -1);
        suitArray.forEach(kvp => {
            for (let i = 1; i <= this.cardsInSuites.last; i++) {
                var key = kvp[0] + i;
                var power = i === 1 ? 15 : i === 10 ? 14 : i;

                cards[key] = {
                    focused: false,
                    where: null,
                    power: power,
                    sort: Math.random()
                };
                cards[key + '_2'] = {
                    focused: false,
                    where: null,
                    power: power,
                    sort: Math.random()
                };

                if (i === 1) {
                    i = this.cardsInSuites.first - 1;
                }
            }
        });

        return cards;
    },
    adjustOnLoad(card) {
        card.suit = card.key[0];
        card.playable = true;
        card.select = false;
        card.num = +(card.key.split('_')[0]).substr(1);
    },
    determineWinningCard(shared) {
        var sorter = this.cardSorter(shared);

        var trumpSuit = shared.game.trumpSuit;

        var cardsInTrump = shared.allCardsInPlay.filter(c => c.suit === trumpSuit);
        if (cardsInTrump.length) {
            if (cardsInTrump.length === 1) {
                return cardsInTrump[0];
            }
            cardsInTrump.sort(sorter);
            return cardsInTrump[0];
        }

        var firstCard = shared.firstCardPlayed;
        var suitInPlay = firstCard.suit;

        var cardsFollowingSuit = shared.allCardsInPlay.filter(c => c.suit === suitInPlay);
        if (cardsFollowingSuit.length) {
            if (cardsFollowingSuit.length === 1) {
                return cardsFollowingSuit[0];
            }
            cardsFollowingSuit.sort(sorter);
            return cardsFollowingSuit[0];
        }

        // none in trump, none in suit
        return firstCard;
    },
    gameIsFinished(shared) {
        return shared.topScore >= 150;
    },
    afterCardsChange(host) {
        // flag unplayable cards
        if (!host.allCardsInPlay.length) {
            host.numPlayable = 0;
            return;
        }

        var focusedCard = host.focusedCard;
        var firstCard = host.firstCardPlayed;
        var suitInPlay = firstCard.suit;

        var inHand = host.myCards.filter(c => !c.where);

        // must play trump if none in suit
        var inSuit = inHand.filter(c => c.suit === suitInPlay);
        var inTrump = inHand.filter(c => c.suit === host.game.trumpSuit);

        if (!inSuit.length && !inTrump.length) {
            return; // all playable
        }

        var alreadyPlayedInThisRound = host.game.state === 'meld' || !!host.allCardsInPlay.find(c => c.who === host.me.id);

        if (alreadyPlayedInThisRound) {
            inHand.forEach(c => {
                c.playable = true;
            });
            host.numPlayable = inHand.length;
            return;
        }

        var maxPlayedInSuit = host.allCardsInPlay.filter(c => c.suit === suitInPlay).reduce((acc, c) => Math.max(acc, c.power), 0);
        var maxPlayedInTrump = host.allCardsInPlay.filter(c => c.suit === host.game.trumpSuit).reduce((acc, c) => Math.max(acc, c.power), 0);
        var trumped = suitInPlay !== host.game.trumpSuit && maxPlayedInTrump > 0;

        var playableCards = [];

        inHand.forEach(c => {
            c.playable = c.suit === suitInPlay && (c.power > maxPlayedInSuit || trumped); // higher in suit

            if (c.playable) {
                playableCards.push(c);
            } else {
                c.focused = false;
            }

        });

        if (!playableCards.length) {
            inHand.forEach(c => {
                c.playable = c.suit === suitInPlay; // any in the suit

                if (c.playable) {
                    playableCards.push(c);
                } else {
                    c.focused = false;
                }
            });
        }

        if (!playableCards.length) {

            inHand.forEach(c => {
                // must have some in trump
                c.playable = c.suit === host.game.trumpSuit && c.power > maxPlayedInTrump; // higher in trump

                if (c.playable) {
                    playableCards.push(c);
                } else {
                    c.focused = false;
                }
            });
        }

        if (!playableCards.length) {
            inHand.forEach(c => {
                // must have some in trump
                c.playable = c.suit === host.game.trumpSuit; // any in trump
                if (c.playable) {
                    playableCards.push(c);
                } else {
                    c.focused = false;
                }
            });
        }

        // more cards are available... defocus any that were selected?
        // console.log(host.numPlayable, playableCards.length);
        // if (host.numPlayable > playableCards.length) {
        playableCards.forEach(c => c.focused = c.key === focusedCard);
        // }

        host.numPlayable = playableCards.length;

    },
    cardSorter(shared) {
        if (!shared) {
            return (a, b) => { };
        }
        var config = shared.gameConfig;

        var suits = this.suits;
        var aceIsHigh = config.aceIsHigh;

        // adjust order
        var offset = 0;

        // debugger;
        var suitOrder = {};
        Object.keys(suits).forEach(k => {
            var newSort = suits[k].sort + offset;
            if (newSort < 1) {
                newSort = newSort + 4;
            }
            suitOrder[k] = newSort; // negative
        });

        return (a, b) => {
            // console.log(a.suit, b.suit, a, b)
            if (a.suit === b.suit) {
                var aNum = a.power; // aceIsHigh && a.num === 1 ? 15 : a.num;
                var bNum = b.power; // aceIsHigh && b.num === 1 ? 15 : b.num;

                // if (aNum === 10) aNum = 14;
                // if (bNum === 10) bNum = 14;

                if (aNum === bNum) {
                    return a.playOrder < b.playOrder ? -1 : 1;
                }

                return aNum < bNum ? 1 : -1;
            }
            return suitOrder[a.suit] > suitOrder[b.suit] ? 1 : -1;
        }
    },
    scoreCards(cardKeys) {
        var target = ['1', '10', '13'];
        return cardKeys.filter(key => target.indexOf(key.split('_')[0].substr(1)) !== -1).length;
    },
    scoreMeld(cardKeys, trumpSuit) {
        var scoring = [];

        var cardInfos = cardKeys.map(key => {
            var base = key.split('_')[0];
            return {
                suit: key[0],
                base: base,
                rank: +base.substr(1),
                married: false, // for marriages
                inRun: false, // for marriages in trump when there is also a run
            }
        })
        // c1_1, c1_2, c11,d1,s11,c13...

        var addAround = function (rank, points, name) {
            var matching = cardInfos.filter(ci => ci.rank === rank);
            if (matching.length === 8) {
                scoring.push({
                    d: `Double ${name} around`,
                    p: points * 10
                });
            } else if (matching.length >= 4) {
                if (new Set(matching.map(ci => ci.suit)).size === 4) {
                    scoring.push({
                        d: `${name} around`,
                        p: points
                    });
                }
            }
        };

        addAround(1, 10, 'Aces');
        addAround(11, 4, 'Jacks');
        addAround(12, 6, 'Queens');
        addAround(13, 8, 'Kings');

        // run - make spots for what cards are needed
        var runSpotObj = {};
        var numRuns = 0;
        var runEligibleCards = 0;

        // define cards in a run; make spots to count them
        [1, 13, 12, 11, 10].map(rank => trumpSuit + rank).forEach(key => runSpotObj[key] = 1); // don't use 0 - can't check easily

        // find cards that could be in a run
        cardInfos.forEach(ci => {
            if (runSpotObj[ci.base]) {
                // this card could be in a run
                runSpotObj[ci.base]++;
                runEligibleCards++;
            }
        });

        // only bother to check for runs if found 5 cards that could be in a run
        if (runEligibleCards >= 5) {
            var runSpots = Object.entries(runSpotObj);
            var runSpotKeys = Object.keys(runSpotObj);

            // check how many cards are in each 'spot'
            runSpots.forEach(e => e[1]--); // remove the intial count of 1 for each card

            // if the 5 possible run spots each have two matches, we have a double run
            if (runSpots.filter(e => e[1] === 2).length === 5) {
                scoring.push({
                    d: 'Double Run',
                    p: 150
                });
                // cardInfos.filter(ci=>ci.base === )
                numRuns = 2;
            } else if (runSpots.filter(e => e[1] >= 1).length === 5) {
                // if the 5 possible run spots have at least one match, we have a run
                scoring.push({
                    d: 'Run',
                    p: 15
                });
                // mark one of each card as being in a run
                numRuns = 1;
                runSpotKeys.forEach(key => {
                    cardInfos.filter(ci => ci.base === key)[0].inRun = true;
                });
            }
        }

        // pinochle
        var cardsForPinochle = cardInfos.filter(ci => ci.rank === 11 && ci.suit === 'd' || ci.rank === 12 && ci.suit === 's');
        var numCardsForPinochle = cardsForPinochle.length;
        if (numCardsForPinochle === 4) {
            scoring.push({
                d: 'Double Pinochle',
                p: 30
            });
        } else if (numCardsForPinochle === 3) {
            scoring.push({
                d: 'Pinochle',
                p: 4
            });
        } else if (numCardsForPinochle === 2) {
            if (cardsForPinochle[0].suit !== cardsForPinochle[1].suit) {
                scoring.push({
                    d: 'Pinochle',
                    p: 4
                });
            }
        }

        // marriages (but not if trump in a run)
        var queens = cardInfos.filter(ci => ci.rank === 12 && (ci.suit !== trumpSuit || numRuns < 2) && !ci.inRun);
        queens.forEach(q => {
            var matchingKings = cardInfos.filter(k => k.rank === 13 && k.suit === q.suit && !k.married && (k.suit !== trumpSuit || !k.inRun));
            if (matchingKings.length >= 1) {
                matchingKings[0].married = true;
                matchingKings[0].inRun = true;
                var inTrump = q.suit === trumpSuit;
                scoring.push({
                    d: 'Marriage in ' + this.suits[q.suit].display + (inTrump ? ' (Trump)' : ''),
                    p: inTrump ? 4 : 2
                });
            }
        });

        var nineInTrump = cardInfos.filter(ci => ci.rank === 9 && ci.suit === trumpSuit);
        if (nineInTrump.length > 0) {
            scoring.push({
                d: '9 in trump',
                p: nineInTrump.length
            });
        }

        // sort and put a key on each line
        scoring.sort((a, b) => a.p < b.p ? 1 : -1);
        scoring.forEach((s, i) => s.key = i);

        return scoring;
    },
    suits: {
        //sort as per https://en.wikipedia.org/wiki/High_card_by_suit

        s: {
            code: 'spades',
            display: 'Spades',
            class: 'black',
            sort: 1,
        },
        d: {
            code: 'diams',
            display: 'Diamonds',
            class: 'red',
            sort: 2,
        },
        c: {
            code: 'clubs',
            display: 'Clubs',
            class: 'black',
            sort: 3,
        },
        h: {
            code: 'hearts',
            display: 'Hearts',
            class: 'red',
            sort: 4,
        },
    }
}