import Vue from 'vue'
import router from './router'
import './registerServiceWorker'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import './plugins/element.js'

import App from './App.vue'
import _shared from "@/shared.js";

// Vue.use(require("moment"));

Vue.use(ElementUI, {
    locale
})

Vue.config.productionTip = false

new Vue({
    router,
    computed: {
        shared() {
            return _shared;
        }
    },
    render: h => h(App),
}).$mount('#app')