import Stage3 from '../stages/Stage3.vue'
export default {
    code: 'draw1',
    name: 'Draw 1',
    summary: 'Similar to Uno (not complete)',
    deckType: 'draw1',
    play: 'toCenter',
    stage: Stage3,
    minPlayers: 4,
    maxPlayers: 8,
    questions: [],
    defaultAnswers() {
        return {
            numRounds: 5,
        }
    }
}