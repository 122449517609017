// Stage - multi player dealing
<template>
  <div
    class="Stage3"
    :class="{isAdmin: isAdmin, 
             isDealer: isDealer,
    }"
  >
    <div class="grid-container">
      <div class="title">
        <p>
          Stage 3 -
          {{ shared.deck.state }}
        </p>
      </div>
      <div class="topLeft"></div>
      <div class="topRight"></div>
      <div class="table">
        <div class="tableCenter">
          <div
            v-if="tableMessage"
            v-text="tableMessage"
          ></div>
        </div>

        <div class="playArea">
          <div class="cardArea">
            <draw1
              v-for="(c,i) in top3cards"
              :key="c.key"
              :class="'offset' + i"
              :card="c"
            ></draw1>
          </div>
        </div>
      </div>

      <div class="p1">
        {{ p1.name }} {{ p1.team }}

        <div class="miniCards">
          <span
            v-for="b in getOtherCardCount(p1)"
            :key="b"
          >🂠</span>
        </div>
      </div>
      <div class="p2">
        {{ p2.name }} {{ p2.team }}
        <div class="miniCards">
          <span
            v-for="b in getOtherCardCount(p2)"
            :key="b"
          >🂠</span>

        </div>
      </div>
      <div class="p3">
        {{ p3.name }} {{ p3.team }}
        <div class="miniCards">
          <span
            v-for="b in getOtherCardCount(p3)"
            :key="b"
          >🂠</span>
        </div>
      </div>
      <div class="myArea">
        <div class="myButtons">

          <button
            v-if="cardsAvailable"
            class="caution"
            v-on:click="dealNextCardTo(me)"
          >Draw a card</button>

          <button
            :disabled="!numCardsSelected"
            v-on:click="playFocusedCards"
          >
            Play
            <span
              v-if="numCardsSelected> 1"
              v-text="numCardsSelected + ' cards'"
            ></span></button>

          <button
            :disabled="!myCardsInPlay.length || lastPlayerId !== me.id"
            v-on:click="pullCardsBack"
          >
            Pull Back
          </button>

        </div>

        <div class="cardArea">
          <draw1
            v-for="c in myCardsInHand"
            :key="c.key"
            :card="c"
            class="myCard"
            v-on:click="cardClicked(c, 'inHand')"
          ></draw1>
        </div>
      </div>

    </div>
    <div class="dealer">
      <div class="dealerControls">
        <button
          class="caution"
          v-on:click="makeDeck"
        >Shuffle All Cards</button>

        {{ shared.cardsStillInDeck.length }} cards

        <button
          v-if="isDealer"
          :disabled="!cardsAvailable"
          v-on:click="dealSome(7)"
        >Deal 7 card to each</button>

      </div>
    </div>

    <div class="admin">
      <div class="adminControls">
      </div>
    </div>
  </div>
</template>

<script>

import firebaseDb from "@/firebaseInit";
import stageMixin from './stageMixin'
import draw1 from "../cards/draw1";

export default {
  name: "Stage3",
  components: {
    draw1
  },
  mixins: [stageMixin],
  data: function () {
    return {
      viewedPosition: null,
      stacked: false,
      tableMessage: '',
      backOfCard: {
        key: 'back'
      }
    };
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    },
    lastPlayerId: {
      get() { return this.shared.game.lastPlayer || ''; },
      set(v) {
        var path = `/games/${this.shared.gameKey}`;
        firebaseDb.ref(path).update({
          lastPlayer: v
        });
      }
    },
    cardsAvailable() {
      return this.shared.cardsStillInDeck.length;
    },
    me() {
      return this.shared.me;
    },
    top3cards() {
      return this.shared.allCardsInPlay.slice(-3);
    },
    isDealer() {
      return this.me.id && this.me.id === this.shared.game.dealer;
    },
    isAdmin() {
      return this.shared.me.isAdmin;
    },
    myCards() {
      return this.shared.myCards;
    },
    numCardsSelected() {
      return this.myCards.filter(c => !c.where && c.focused).length;
    },
    myCardsInPlay() {
      return this.myCards.filter(c => c.where === 'inPlay');
    },
    myCardsInHand() {
      return this.myCards.filter(c => !c.where);
    },
    mySittingOrder() {
      return this.me.sortOrder;
    },
    p1() {
      return this.getPlayerInSpot(1);
    },
    p2() {
      return this.getPlayerInSpot(2);
    },
    p3() {
      return this.getPlayerInSpot(3);
    }
  },
  mounted: function () {
  },
  beforeDestroy: function () {
  },
  methods: {
    makeDeck() {
      // recreate deck
      var cards = this.makeCards();

      // store them
      var deck = {
        cards: cards,
        state: 'sorted'
      }

      firebaseDb
        .ref(`/deck/${this.shared.gameKey}`)
        .set(deck);
    },
    makeCards() {
      var deckType = this.shared.deckType;
      if (deckType) {
        var cards = deckType.makeCards(this.shared.configAnswers.includeJokers, 1);
        //var deck2 = deckType.makeCards(this.shared.configAnswers.includeJokers, 2);
        // Array.prototype.push.apply(cards, deck2);

        return cards;
      }
      return {};
    },
    cardClicked(c, where) {
      // var faceDown = !c.faceDown;
      // c.faceDown = faceDown;
      if (where === 'inHand') {
        var focused = !c.focused;

        // if (focused && (this.numCardsSelected || this.lastPlayerId === this.me.id)) {
        if (focused && this.numCardsSelected) {
          this.myCardsInHand
            .filter(c => c.focused)
            .forEach(c => c.focused = false)
        }
        c.focused = focused;
      }

      if (where === 'inPlay') {
        //?
      }


      // same for everyone
      // firebaseDb
      //   .ref(`/deck/${this.shared.gameKey}/cards/${c.key}`)
      //   .update({ faceDown: faceDown });
    },
    playFocusedCards() {
      this.myCardsInHand
        .filter(c => c.focused)
        .forEach(c => {
          var nextInPlayOrder = 1 + this.shared.allCardsInPlay.length;
          firebaseDb
            .ref(`/deck/${this.shared.gameKey}/cards/${c.key}`)
            .update({
              where: 'inPlay',
              playOrder: nextInPlayOrder,
              focused: false,
            });
        });
      this.lastPlayerId = this.me.id;
    },
    pullCardsBack() {
      this.myCardsInPlay
        .slice(-1) // only last card
        //.filter(c => c.focused)
        .forEach(c => {
          var path = `/deck/${this.shared.gameKey}/cards/${c.key}`;
          firebaseDb
            .ref(path)
            .update({
              where: '',
              playOrder: 0,
              newCard: true
            });
        });
      this.lastPlayerId = null;
    }
  }
};
</script>

<style lang="less">
.Stage3 {
  height: 100%;
  display: flex;
  flex-direction: column;

  @myArea: 240px;

  .grid-container {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 0.7fr 2.5fr 0.7fr;
    grid-template-rows: 0.2fr 0.6fr 4fr @myArea;
    gap: 3px 3px;

    button {
      align-self: center;
    }

    grid-template-areas:
      "title title title"
      "topLeft p2 topRight"
      "p1 table p3"
      "myArea myArea myArea";
  }

  .table {
    grid-area: table;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: #e8f9e6;
    background-clip: padding-box;
    border: solid 5px transparent;
    border-radius: 1em;

    .tableCenter {
      grid-area: tableCenter;
      height: 2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }

    .playArea {
      flex-grow: 1;
      margin: 0 auto;

      .cardArea {
        position: relative;
        .CardHolder {
          position: absolute;
          &.offset0 {
            left: -50px;
          }
          &.offset1 {
            left: -25px;
          }
          &.offset2 {
            left: 0;
          }
        }
      }
    }

    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -5px;
      border-radius: inherit;
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(red),
        to(orange)
      );
      background: linear-gradient(to right, red, orange);
    }
  }
  .myArea {
    grid-area: myArea;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .cardArea {
      margin-top: 20px;
      padding: 0 1em;
      .CardHolder {
        margin: 0 1px;
      }
    }
  }

  .cardArea {
    display: flex;
    justify-content: center;
  }

  .p1 {
    grid-area: p1;
    background-color: lightskyblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .p2 {
    grid-area: p2;
    background-color: lightsteelblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .p3 {
    grid-area: p3;
    background-color: lightblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .dealer {
    display: none;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 0.5em;
    position: relative;
    &::before {
      content: "Dealer";
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }

  &.isDealer {
    .dealer {
      display: block;
    }
  }

  .admin {
    display: none;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0.5em;
    position: relative;
    &::before {
      content: "Admin";
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }

  &.isAdmin {
    .admin {
      display: block;
    }
  }

  .title {
    grid-area: title;
  }

  .topLeft {
    grid-area: topLeft;
  }

  .topRight {
    grid-area: topRight;
  }

  .miniCards {
    margin: 0 1em;
    font-size: 130%;
    span {
      margin: 0 1px;
    }
  }
  .myButtons {
    position: relative;
    height: 30px;
    button {
      margin: 0 2em;
    }
  }
  .CardHolder.focused {
    margin: -10px 3px 0;
    box-shadow: 0px 0px 3px 0px black;
    flex-shrink: 0;
    // .Card {
    // }
  }

  .trumpInfo {
    transition: 0.2s;
  }

  .trumpSymbol {
    font-size: 150%;
  }
}
</style>
