// All cards laid out and clickable
<template>
  <div class="Stage1">
    <p>
      Stage 1 -
      {{ shared.deck.state }}
    </p>
    <div class="cards">
      <standard1
        v-for="c in shared.cardsStillInDeck"
        :key="c.key"
        :card="c"
        :class="{focused: c.focused}"
        v-on:click="cardClicked(c)"
      ></standard1>
    </div>
    <div>
      <standard1
        :card="card"
        v-on:click="cardClicked(card)"
      ></standard1>
    </div>
    <div class="adminControls">
      <button v-on:click="makeDeck">Get the Deck</button>
      <button v-on:click="shuffleDeck">Shuffle</button>
      <button v-on:click="pickCard">View Next Card</button>
    </div>
  </div>
</template>

<script>

import firebaseDb from "@/firebaseInit";
import stageMixin from './stageMixin'
import standard1 from "../cards/standard1";

export default {
  name: "Stage1",
  components: {
    standard1
  },
  mixins: [stageMixin],
  data: function () {
    return {
      viewedPosition: null,
      cards: [],
      card: {},
      nextCardNum: 0,
      stacked: false,
      backOfCard: {
        key: 'back'
      }
    };
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    },
    viewedPositionId: function () {
      return (this.viewedPosition && this.viewedPosition.id) || 0;
    }
  },
  mounted: function () {
    this.makeCards();
    this.pickCard();
    this.shared.$on('config-changed', this.makeCards);
  },
  beforeDestroy: function () {
    this.shared.$off('config-changed', this.makeCards);
  },
  methods: {
    makeDeck() {
      // recreate deck
      var cards = this.makeCards();

      // remove what players hold??

      // store them
      var deck = {
        cards: cards,
        state: 'sorted'
      }

      firebaseDb
        .ref(`/deck/${this.shared.gameKey}`)
        .set(deck);
    },

    makeCards() {
      var deckType = this.shared.deckType;
      if (deckType) {
        var cards = deckType.makeCards(this.shared.configAnswers.includeJokers, 1);
        //var deck2 = deckType.makeCards(this.shared.configAnswers.includeJokers, 2);
        // Array.prototype.push.apply(cards, deck2);

        return cards;
      }
      return {};
    },
    pickCard() {
      var cards = this.shared.cardsStillInDeck;
      if (!cards.length) {
        return;
      }

      if (this.card) {
        this.card.focused = false;
      }

      this.card = cards[this.nextCardNum];

      this.card.focused = true;

      this.nextCardNum++;
      if (this.nextCardNum > cards.length - 1) {
        this.nextCardNum = 0;
      }
    },
    cardClicked(c) {
      var faceDown = !c.faceDown;

      // local only
      c.faceDown = faceDown;

      // same for everyone
      firebaseDb
        .ref(`/deck/${this.shared.gameKey}/cards/${c.key}`)
        .update({ faceDown: faceDown });
    },
    nameOf: function (id) {
      if (!id) return "";
      var member = this.shared.members.find(m => m.id === id);
      if (!member) return "";
      return member.name;
    }
  }
};
</script>

<style lang="less">
.Stage1 {
  .CardHolder.focused div.Card {
    background-color: lightgreen;
  }
}
</style>
