<template>
  <div id="nav">
    <div class="image">
      <span
        v-if="gameConfigName"
        class="gameConfigName"
      >
        {{ gameConfigName }}
      </span>
      <img
        v-if="logoSymbolUrl"
        class="logoSymbolUrl"
        :class="logoSymbolClass"
        :src="logoSymbolUrl"
      >
    </div>
    <div
      v-if="$route.name !== 'createGame'"
      class="middle"
    >
      <span v-if="!shared.me.id">
        <router-link
          to="claim"
          v-text="'Claim'"
        ></router-link>
      </span>
      <span v-if="shared.me.isAdmin">
        <router-link
          to="admin"
          v-text="'Setup'"
        ></router-link>
      </span>
      <span v-if="shared.me.id">
        <router-link to="home">Play</router-link>
      </span>
      <span v-if="shared.me.id">
        <router-link to="share">Share / Talk</router-link>
      </span>
      <!-- <span>
        <router-link to="/faq">Help</router-link>
      </span> -->
    </div>
    <div v-else></div>

    <div
      class="myName"
      :title="iconTip"
      :class="{isViewer: shared.isViewer}"
    >
      <!-- <el-button
        type="success"
        icon="el-icon-question"
        title="Information about this Game"
        v-on:click="go('/faq')"
      >FAQ</el-button> -->

      <!-- <el-button
        type="success"
        icon="el-icon-share"
        title="Share the secret link with others"
        v-on:click="toggleFullScreen"
      >Share</el-button> -->

      <span class="name">{{ shared.me.name }}</span>

      <el-button
        type="info"
        icon="el-icon-full-screen"
        title="Toggle Full Screen view"
        circle
        v-on:click="toggleFullScreen"
      ></el-button>

      <el-button
        v-if="shared.me.id"
        type="warning"
        icon="el-icon-right"
        circle
        title="Leave the table"
        v-on:click="shared.forgetMe"
      ></el-button>

      <!-- <button
        v-if="shared.isMember || shared.isViewer"
        v-on:click="forgetMe"
      >
        Leave
      </button> -->
    </div>
  </div>
</template>

<script>

import firebaseDb from "../firebaseInit";

export default {
  name: "Nav",
  computed: {
    shared: function () {
      return this.$root.shared;
    },
    version: function () {
      return _version;
    },
    logoSymbolClass() {
      if (this.shared.game.trumpSuit) {
        return 'logo-' + this.shared.game.trumpSuit;
      }
      return '';
    },
    gameConfigName() {
      if (this.shared.gameConfig && this.shared.gameConfig.name) {
        return this.shared.gameConfig.name;
      }
      return '';
    },
    logoSymbolUrl() {
      var suitKey = this.shared.game.trumpSuit;
      if (suitKey === 'e' || suitKey === 'w') {
        return '';
      }
      if (suitKey) {
        return require(`@/assets/standard2/suit-${suitKey}-s.png`);
      }
      return '';
    },
    iconTip() {
      var lines = [
        'Version ' + this.version,
        'uid ' + (this.shared.firebaseRawAuthUser ? this.shared.firebaseRawAuthUser.uid.substr(0, 3) : '?'),
        'game ' + this.shared.gameKeyAbbrev(this.shared.gameKey),
        'id ' + (this.shared.myId || '(not connected)'),
      ];

      return lines.join('\n')
    }
  },
  mounted() {
    this.shared.$on('toggleFullScreen', this.toggleFullScreen);
  },
  beforeDestroy: function () {
    this.shared.$off('toggleFullScreen', this.toggleFullScreen);
  },
  methods: {
    go(path) {
      this.$router.replace(path);
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        if (document.documentElement && document.documentElement.requestFullscreen) {
          // fails on some ipads
          document.documentElement.requestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
  }
};
</script>

<style lang="less">
#nav {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  // display: flex;
  // flex-shrink: 0;
  // justify-content: space-between;
  background: #000;
  color: #fff;
  align-items: center;
  min-height: 2.5em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  > div {
    flex: 1 1 auto;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  // .logoSymbol {
  //   font-size: 1.7em;
  //   margin: -13px 0.5em 0;
  // }
  .logoSymbolUrl {
    height: 20px;
    margin-left: 12px;
    margin-right: 12px;

    &.logo-c,
    &.logo-s {
      filter: invert(1);
    }
  }

  div.middle {
    flex-grow: 19;
    white-space: normal;
    padding: 2px 0 6px;
    span {
      margin: 0 15px;
    }
  }

  .image {
    text-align: left;
    justify-content: left;
    align-items: center;
  }

  a {
    color: #999;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: #ddd;
    }

    &.router-link-exact-active {
      color: #fff;
      &:hover {
        cursor: default;
        color: #fff;
      }
    }

    .logoSymbol {
      color: #fff;
    }
  }

  span {
    display: inline-block;
    margin: 3px 0.5em;
    vertical-align: middle;
  }

  button {
    font-size: 0.9em;
  }

  .myName {
    justify-content: flex-end;
    padding-right: 3px;
    align-items: center;
    .name {
      margin-left: 2em;
    }
  }
}
</style>