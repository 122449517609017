import Stage2 from '../stages/Stage2.vue'
export default {
    code: 'estimation',
    name: 'Estimation',
    publish: true,
    summary: 'Estimate how many tricks you will win!',
    stage: Stage2,
    deckType: 'standard',
    aceIsHigh: true,
    useTrump: true,
    minPlayers: 4,
    maxPlayers: 6,
    tableSize: 4,
    useTeams: false,
    dealAfterShuffle: true,
    useBidding: true,
    bidBeforeRound: true,
    showBidCircles: true,
    questions: [
        // {
        //         code: 'numCards',
        //         display: 'Number of cards in next round',
        //         type: 'NumberSlider',
        //         min: 1,
        //         max: 13
        //     },
        {
            code: 'counterClockwise',
            display: `Play proceeds:`,
            true: 'Counter-clockwise',
            false: 'Clockwise',
            type: 'BooleanSwitch'
        }, {
            code: 'viewersSeeAll',
            display: `Show cards to non-players`,
            type: 'Boolean'
        }, {
            //     code: 'dealerBidOff',
            //     display: `Total bids cannot match number of cards`,
            //     type: Boolean
            // }, {
            //     code: 'simultaneousBidding',
            //     display: `All players bid simultaneously`,
            //     type: Boolean
            // }, {
            //     code: 'incrementCards',
            //     display: `Increase the number of cards after each round`,
            //     type: Boolean
            // }, {
            //     code: 'addWizJest',
            //     display: `Include "Wizard" and "Jester" cards`,
            //     type: Boolean
        }
    ],
    adjustAnswers(newAnswers, oldAnswers) {
        var adjustmentsMade = false;
        if (newAnswers.incrementCards && !oldAnswers.incrementCards && newAnswers.numCards === 13) {
            newAnswers.numCards = 1;
            adjustmentsMade = true;
        }
        if (!newAnswers.incrementCards && oldAnswers.incrementCards && newAnswers.numCards === 1) {
            newAnswers.numCards = 13;
            adjustmentsMade = true;
        }
        return adjustmentsMade;
    },
    calcScore(bid, tricksTaken, numCardsInRound) {
        var scoreForRound;
        if (bid === tricksTaken) {
            scoreForRound = numCardsInRound + bid;
        } else {
            scoreForRound = 0 - Math.abs(bid - tricksTaken);
        }
        return scoreForRound;
    },
    defaultAnswers() {
        return {
            numCards: 13,
            incrementCards: false,
            viewersSeeAll: true,
            dealerBidOff: true,
            simultaneousBidding: false,
            counterClockwise: true,
            addWizJest: false
        }
    }
}