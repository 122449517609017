<template>
  <component :is="stage"></component>
</template>


<script>


export default {
  name: "Home",
  data: function () {
    return {
    };
  },
  computed: {
    shared() {
      return this.$root.shared;
    },
    stage() {
      var config = this.shared.gameConfig;
      if (!config) {
        return null;
      }
      return config.stage;
    }
  },
  mounted: function () {
  },
  beforeDestroy: function () {
  },
  methods: {
  }
};
</script>