// Stage - multi player dealing
<template>
  <div
    class="Stage2Pinochle"
    :class="[
      {
        isAdmin: isAdmin,
        isDealer: isDealer,
        isBidWinnerPartner: isBidWinnerPartner,
        isViewer: shared.isViewer,
        isMember: shared.isMember,
        alertMyTurn: alertMyTurn,
        next: isNext,
      },
      'trump_' + trumpSuit.key,
      'players_4',
      shared.game.state,
    ]"
  >
    <div class="seatingWithTable">
      <div class="topLeft">
        <div v-if="game.roundNum">{{ roundDesc }}</div>
        <div v-if="showTrickNumber">Trick {{ shared.tricks.length + 1 }}</div>
        {{ stateDesc[shared.game.state] || shared.game.state }}
      </div>
      <div class="topRight">
        <div v-if="showCurrentBid" class="totalBids">
          Bid: {{ shared.game.currentBid }}
        </div>
        <div v-if="showBidWinner">
          <div>{{ bidWinner.name }}</div>
          <div>Bid {{ bidWinner.bid }}</div>
        </div>
      </div>

      <seat-area
        v-if="p0"
        class="seatB1 bottom"
        :player="p0"
        :stage="this"
      ></seat-area>

      <seat-area
        :class="shared.seatingFor(1)"
        :player="p1"
        :stage="this"
        :hide-team-scoring="counterClockwise"
        :show-for-team="!counterClockwise"
        :team-meld="otherTeamMeld"
        :team-points="otherTeamPoints"
      ></seat-area>

      <seat-area
        v-if="p2.id"
        :class="shared.seatingFor(2)"
        :player="p2"
        :stage="this"
        :hide-team-scoring="true"
      ></seat-area>

      <seat-area
        v-if="p3.id"
        :class="shared.seatingFor(3)"
        :player="p3"
        :hide-team-scoring="!counterClockwise"
        :show-for-team="counterClockwise"
        :team-meld="otherTeamMeld"
        :team-points="otherTeamPoints"
        :stage="this"
      ></seat-area>

      <div class="table">
        <div v-if="showLogo" class="mainLogo">
          <img src="../../../public/img/logo700x700.png" />
        </div>
        <div v-if="tableMessage" ref="tableCenter" class="tableCenter">
          <div v-html="tableMessage"></div>
          <button
            v-if="shared.personWhoTookTrick.id === me.id && me.id"
            v-on:click="clearTable"
            v-text="'Take the Trick'"
          ></button>

          <div v-if="bidWinnerChoosingTrump">
            <el-radio-group v-model="newTrump">
              <el-radio-button label="s">
                <img class="logoSymbolUrl" :src="getSuitUrl('s')" />
              </el-radio-button>
              <el-radio-button label="d">
                <img class="logoSymbolUrl" :src="getSuitUrl('d')" />
              </el-radio-button>
              <el-radio-button label="c">
                <img class="logoSymbolUrl" :src="getSuitUrl('c')" />
              </el-radio-button>

              <el-radio-button label="h">
                <img class="logoSymbolUrl" :src="getSuitUrl('h')" />
              </el-radio-button>
            </el-radio-group>
            <button v-on:click="bidWinnerChoosingTrumpDone">Confirm</button>
          </div>
        </div>

        <div ref="tableButtons" class="tableButtons">
          <button v-if="canPassToNextDealer" v-on:click="startNextRound(false)">
            Pass to the<br />Next Dealer
          </button>

          <button
            v-if="
              isDealer &&
              !shared.cardsInUse &&
              !(shared.game.roundFinished || shared.personWhoTookTrick.id)
            "
            v-on:click="makeDeck"
          >
            Deal the Cards
          </button>
        </div>

        <card-area v-if="p0" class="p0table" :player="p0"></card-area>
        <card-area :player="p1" :class="shared.tableAreaFor(1)"></card-area>
        <card-area
          v-if="p2.id"
          :class="shared.tableAreaFor(2)"
          :player="p2"
        ></card-area>
        <card-area
          v-if="p3.id"
          :class="shared.tableAreaFor(3)"
          :player="p3"
        ></card-area>

        <div class="myPlay">
          <span v-if="myTurn && playing" class="YourTurn">Your Turn!</span>
          <div v-if="bidding && me.bidSet" class="bidNum">
            <div>
              <div v-if="me.bid !== 'Pass'">Bid</div>
              <div>{{ me.bid }}</div>
            </div>
          </div>
          <div v-if="showBidReady || me.readyToExchange" class="bidNum">
            <span>Ready</span>
          </div>

          <div class="cardArea">
            <standard2
              v-for="c in myCardsInPlay"
              :key="c.key"
              :card="c"
              v-on:click="cardClicked(c, 'inPlay')"
            ></standard2>
          </div>
          <div
            v-if="
              shared.game.state === 'meld' &&
              me.meldDisplay &&
              me.meldDisplay.length
            "
            class="meldDisplay"
          >
            <div v-for="m in me.meldDisplay" :key="m.key">
              <div>{{ m.d }}</div>
              <div>{{ m.p }}</div>
            </div>
          </div>
        </div>

        <div class="trumpSuit" :class="[trumpSuit.class]">
          <div v-if="trumpSuit.display">Trump</div>
          <div class="trumpInfo">
            <img
              v-if="trumpSuit.url"
              class="trumpSymbol"
              :src="trumpSuit.url"
            />
          </div>
        </div>

        <div class="leadSuit">
          <div v-if="leadSuit.display" :class="[leadSuit.class]" class="trump">
            <div v-if="leadSuit.symbol || leadSuit.url" class="trumpInfo">
              <img
                v-if="leadSuit.url"
                class="trumpSymbol"
                :src="leadSuit.url"
              />
            </div>
            <div>Trick</div>
          </div>
        </div>
      </div>

      <div v-if="p0" class="p0left"></div>
      <div v-if="p0" class="p0right"></div>

      <div ref="myArea" class="myArea">
        <div class="myButtons">
          <div class="bidding">
            <!-- <bid-display
              v-if="me.bidSet"
              :bid="me.bid"
              :tricks="numTricks(me)"
              :meld="me.meld"
              :points="me.points"
            ></bid-display> -->
            <div v-if="playing" class="BidDisplay">
              <div v-if="playerIsBidWinner(me)" class="dealer">
                Won Bid - {{ me.bid }}
              </div>
              <span class="title">
                <span>Team Meld {{ teamMeld }}</span>
                <span>Team Taken {{ teamPoints }}</span>
                <span>{{ toTake }}</span>
              </span>
            </div>
          </div>

          <div ref="myCenterButtons" class="myCenterButtons">
            <span v-if="settingBid">
              My Bid
              <el-input-number
                v-model="me.rebid"
                :disabled="roundIsActive"
                :min="minToBid"
                :max="maxToBid"
              ></el-input-number>
              &nbsp;
              <el-button
                :type="bidChanging ? 'primary' : ''"
                icon="el-icon-right"
                :disabled="!canSetBid"
                :title="canSetBid ? '' : 'Not your turn'"
                v-on:click="setBid()"
                >Set Bid</el-button
              >
              <el-button
                icon="el-icon-right"
                :type="bidChanging ? 'primary' : ''"
                :disabled="!myTurnToBid"
                v-on:click="setBidPass"
                >Pass/Help</el-button
              >
            </span>

            <button
              v-if="isBidWinner && game.state === 'meld' && allMeldCounted"
              :disabled="shared.allCardsInPlay.length > 0"
              v-on:click="finishMeld"
              class="startTrick"
            >
              Start This Trick
            </button>

            <button
              v-if="
                game.state === 'exchangeCards' &&
                (isBidWinner || isBidWinnerPartner)
              "
              :disabled="!readyToExchangeCards"
              v-on:click="exchangeCards"
            >
              Exchange 4 Cards
            </button>

            <button
              v-if="game.state === 'meld'"
              :disabled="!numCardsFocused"
              v-on:click="showMeld"
            >
              Show for Meld
            </button>

            <button
              v-if="
                game.state === 'meld' &&
                !numCardsFocused &&
                !myCardsInPlay.length
              "
              v-on:click="showMeld"
            >
              No Meld
            </button>

            <button
              v-if="game.state === 'meld' && myCardsInPlay.length"
              class="notUsual"
              v-on:click="recallMeld"
            >
              Return Meld Cards
            </button>

            <button
              v-if="roundIsActive && !numCardsInPlay && myCardsInHand.length"
              :disabled="
                !numCardsSelected || (playing && shared.nextPlayer.id !== me.id)
              "
              v-on:click="playFocusedCards"
            >
              <span
                v-if="numCardsSelected > 1"
                v-text="'Play ' + numCardsSelected + ' cards'"
              ></span>
              <span
                v-if="numCardsSelected === 1"
                v-text="'Play the Card'"
              ></span>
              <span v-if="!numCardsSelected && myCardsInHand.length">
                Select a Card
              </span>
            </button>

            <button
              v-if="numCardsFocused > 0"
              class="notUsual"
              v-on:click="defocusAllCards"
            >
              Put card{{ shared.plural(numCardsFocused) }} down
            </button>

            <!-- <button
              v-if="myCardsInPlay.length && lastPlayerId === me.id"
              v-on:click="pullCardsBack"
            >
              Pull Back
            </button> -->
          </div>

          <div class="myInfo">
            <span>{{ me.name }}</span>
            <span>{{ me.team }}</span>

            <div class="chimeSet" title="Play a sound when it is my turn">
              Sound
              <el-checkbox
                v-model="me.chime"
                border
                size="small"
                v-on:input="updateChime"
              ></el-checkbox>
              <select
                v-model="me.chimeNum"
                class="chimeNum"
                v-on:change="updateChime(true)"
              >
                <option
                  v-for="n in 9"
                  :key="n"
                  :value="n"
                  v-text="String.fromCharCode(64 + n)"
                ></option>
              </select>
              <!-- <i
                class="el-icon-video-play"
                title="Listen now"
                v-on:click="playChime"
              ></i> -->
              <div class="chimeVolumeHolder" title="Volume">
                <i class="el-icon-phone-outline"></i>
                <el-slider
                  v-model="me.chimeVolume"
                  class="chimeVolume"
                  vertical
                  :min="0"
                  :max="100"
                  height="200px"
                  v-on:change="updateChime"
                >
                </el-slider>
              </div>
            </div>
          </div>
        </div>

        <div class="cardArea">
          <standard2
            v-for="c in myCardsInHand"
            :key="c.key"
            :card="c"
            class="myCard"
            v-on:click="cardClicked(c, 'inHand')"
          ></standard2>
        </div>
      </div>
    </div>
    <div v-if="isAdmin && false" class="admin">
      <div class="adminControls"></div>
    </div>

    <audio ref="alert1" preload="auto" autobuffer="autobuffer">
      <source src="../../assets/chime1.mp3" />
    </audio>
    <audio ref="alert2" preload="auto" autobuffer="autobuffer">
      <source src="../../assets/chime2.mp3" />
    </audio>
    <audio ref="alert3" preload="auto" autobuffer="autobuffer">
      <source src="../../assets/chime3.mp3" />
    </audio>
    <audio ref="alert4" preload="auto" autobuffer="autobuffer">
      <source src="../../assets/chime4.mp3" />
    </audio>
    <audio ref="alert5" preload="auto" autobuffer="autobuffer">
      <source src="../../assets/chime5.mp3" />
    </audio>
    <audio ref="alert6" preload="auto" autobuffer="autobuffer">
      <source src="../../assets/chime6.mp3" />
    </audio>
    <audio ref="alert7" preload="auto" autobuffer="autobuffer">
      <source src="../../assets/chime7.mp3" />
    </audio>
    <audio ref="alert8" preload="auto" autobuffer="autobuffer">
      <source src="../../assets/chime8.mp3" />
    </audio>
    <audio ref="alert9" preload="auto" autobuffer="autobuffer">
      <source src="../../assets/chime9.mp3" />
    </audio>
    <key-tips
      :visible="showKeyTips"
      v-on:closeMe="showKeyTips = false"
    ></key-tips>
    <score-sheet
      :visible="shared.showScoreSheet"
      v-on:closeMe="shared.showScoreSheet = false"
    ></score-sheet>
    <game-rules
      :visible="shared.showGameRules"
      v-on:closeMe="shared.showGameRules = false"
    ></game-rules>
  </div>
</template>

<script>
import firebaseDb from "@/firebaseInit";
import stageMixin from "./stageMixin";
import standard2 from "../cards/standard2";
import cardArea from "./Stage2CardArea";
import seatArea from "./Stage2SeatArea";
// import bidDisplay from "../controls/BidDisplay";
import keyTips from "../controls/KeyTips";
import * as moment from "moment/moment";
import scoreSheet from "./../controls/ScoreSheet_pinochle";
import gameRules from "./../controls/GameRules";

export default {
  name: "Stage2Pinochle",
  components: {
    standard2,
    cardArea,
    seatArea,
    scoreSheet,
    gameRules,
    keyTips,
  },
  mixins: [stageMixin],
  data: function () {
    return {
      viewedPosition: null,
      stacked: false,
      // pendingBid: '',
      alertMyTurn: false,
      playingCard: false,
      newTrump: "",
      bidChanging: true,
      showKeyTips: false,
      currentCardSelectorIndex: -1,
      simultaneousBidding: false,
      justSentCards: false,
      stateDesc: {
        bid: "Bidding",
        manualTrump: "Choosing Trump",
        exchangeCards: "Exchange Cards",
        meld: "Showing Meld",
        play: "Playing",
      },
      backOfCard: {
        key: "back",
      },
    };
  },
  computed: {
    shared() {
      return this.$root.shared;
    },
    game() {
      return this.shared.game;
    },
    showLogo() {
      return !this.shared.game.state;
    },
    counterClockwise() {
      return this.shared.configAnswers.counterClockwise;
    },
    playing() {
      return this.game.state === "play";
    },
    bidding() {
      return this.game.state === "bid";
    },
    showBidWinner() {
      return !(
        this.game.state === "bid" || this.game.state === "startingRound"
      );
    },
    showBidDisplay() {
      return !(
        this.game.state === "bid" || this.game.state === "startingRound"
      );
    },
    bidsSet() {
      return this.game.state === "bidsSet";
    },
    minToBid() {
      if (this.shared.gameConfig.code === "pinochle") {
        return this.game.currentBid + 1;
      }
      return 0;
    },
    maxToBid() {
      if (this.shared.gameConfig.code === "pinochle") {
        return 500;
      }
      return this.myCards.length;
    },
    allowOnlyOneCard() {
      return this.playing ? true : false;
    },
    chimeNum() {
      return this.shared.me.chimeNum || 1;
    },
    numCardsPerPlayer() {
      return (
        (this.shared.gameConfig && this.shared.configAnswers.numCards) || 13
      );
    },
    stillBidding() {
      // bid until all but one have passed
      return this.shared.members.filter((m) => m.bid === "Pass").length < 3;
    },
    settingBid() {
      return this.bidding && this.me.bid !== "Pass";
    },
    showTrickNumber() {
      return false; // not needed
    },
    showBidReady() {
      return this.shared.game.state === "bid" && this.me.bidReady;
    },
    showCurrentBid() {
      if (this.shared.gameConfig.code === "pinochle") {
        return this.bidding;
      }
      return false;
    },
    showBiddingStatus() {
      if (this.shared.gameConfig.code === "pinochle") {
        return false;
      }
      if (this.playing) {
        return true;
      }
      return true;
    },
    numCardsInPlay() {
      return this.shared.allCardsInPlay.filter(
        (c) => c.who === this.shared.me.id
      ).length;
    },
    roundDesc() {
      var s = `Round ${this.game.roundNum || 1}`;
      if (this.shared.maxRounds) {
        s += ` of ${this.shared.maxRounds}`;
      }
      return s;
    },
    allMeldCounted() {
      return (
        this.shared.members.filter(
          (m) => m.meldDisplay && m.meldDisplay.length > 0
        ).length === 4
      );
    },
    otherTeamMembers() {
      return this.shared.members.filter(
        (m) => m.id !== this.me.id && m.id !== this.myPartner.id
      );
    },
    otherTeamMeld() {
      return this.otherTeamMembers.reduce((acc, m) => (acc += +m.meld || 0), 0);
    },
    otherTeamPoints() {
      return this.otherTeamMembers.reduce(
        (acc, m) => (acc += +m.points || 0),
        0
      );
    },
    teamMeld() {
      return (+this.me.meld || 0) + (+this.myPartner.meld || 0);
    },
    teamPoints() {
      return (this.me.points || 0) + (this.myPartner.points || 0);
    },
    teamBid() {
      return this.isBidWinner
        ? this.me.bid
        : this.isBidWinnerPartner
        ? this.bidWinner.bid
        : 0;
    },
    toTake() {
      var points = this.teamPoints + this.teamMeld;
      var bid = this.teamBid;
      if (this.isBidWinner || this.isBidWinnerPartner) {
        return points >= bid ? "(Bid Made)" : "Needed " + (bid - points);
      }

      return this.teamMeld
        ? this.teamPoints === 0
          ? "Need 1"
          : "(Meld Secured)"
        : "";
    },
    tableMessage() {
      var lines = [];
      if (this.game.roundFinished) {
        lines.push(`Round ${this.game.roundNum} Complete`);
      }
      // else if (this.game.roundStarting) {
      //   lines.push(`Starting Round ${this.game.roundNum}`);
      // }
      else if (this.game.state === "startingRound") {
        var dealerId = this.game.dealer;
        var dealer = this.shared.people.find((m) => m.id === dealerId) || {};
        if (dealer.name) {
          if (dealer.id === this.me.id) {
            lines.push("Your Deal");
          } else {
            lines.push(dealer.name);
            lines.push("Deals");
          }
        }
      } else if (this.game.state === "manualTrump") {
        if (this.isBidWinner) {
          lines.push("Choose Trump!");
        } else {
          lines.push(this.bidWinner.name + " chooses trump");
        }
      } else if (this.game.state === "exchangeCards") {
        lines.push(
          `${this.shared.getPlayerName(
            this.bidWinner
          )} & ${this.shared.getPlayerName(this.bidWinnerPartner)}`
        );
        lines.push("exchanging cards");
      } else {
        if (this.shared.personWhoTookTrick.id) {
          if (this.shared.personWhoTookTrick.id === this.me.id) {
            lines.push("You won!");
          } else {
            lines.push("Won by " + this.shared.personWhoTookTrick.name);
          }
        } else {
          if (this.game.state === "bid") {
            if (this.shared.nextPlayer.id === this.me.id) {
              lines.push("Your turn to bid");
            } else {
              lines.push(this.shared.nextPlayer.name);
              lines.push("is bidding");
            }
          } else if (this.playing) {
            // if (this.shared.nextPlayer) lines.push();
            if (!this.shared.allCardsInPlay.length) {
              if (this.shared.nextPlayer.id === this.me.id) {
                lines.push("You play first");
                this.setFirstPlayer(this.shared.nextPlayer);
                this.preselectCards();
              } else {
                if (this.shared.nextPlayer.name) {
                  lines.push(this.shared.nextPlayer.name);
                  lines.push("Starts");
                }
              }
            }
            // if (this.shared.allCardsInPlay.length) {
            //   lines[lines.length - 1] += ' Next';
            // };
          }
        }
      }
      return lines.join("<br>");
    },
    lastPlayerId: {
      get() {
        return this.game.lastPlayer || "";
      },
      set(v) {
        firebaseDb.ref(`games/${this.shared.gameKey}`).update({
          lastPlayer: v,
        });
      },
    },
    bidWinnerChoosingTrump() {
      return this.isBidWinner && this.game.state === "manualTrump";
    },
    canPassToNextDealer() {
      return (
        this.isDealer &&
        this.game.roundFinished &&
        !this.shared.personWhoTookTrick.id &&
        !this.shared.deckType.gameIsFinished(this.shared)
      );
    },
    trumpSuit() {
      var suitKey = this.game.trumpSuit;
      if (!suitKey) return {};
      if (suitKey === "e") {
        return {
          url: "",
          key: suitKey,
          symbol: "-",
        };
      }
      if (suitKey === "w") {
        return {
          url: "",
          key: suitKey,
          symbol: suitKey,
        };
      }
      var info = this.shared.deckType.suits[suitKey];
      info.url = require(`@/assets/standard2/suit-${suitKey}-s.png`);
      info.key = suitKey;
      return info;
    },
    leadSuit() {
      var firstCard = this.shared.firstCardPlayed;
      if (!firstCard.suit || this.game.state !== "play") return {};

      var suitKey = firstCard.suit;
      if (suitKey === "e" || suitKey === "w") {
        return {
          url: "",
          key: suitKey,
          symbol: "-",
        };
      }
      var info = this.shared.deckType.suits[suitKey];
      info.url = require(`@/assets/standard2/suit-${firstCard.suit}-s.png`);
      info.key = firstCard.suit;
      return info;
    },
    pendingBid() {
      return this.me.rebid || 0;
    },
    isNext() {
      return (
        (this.shared.game.state === "play" || this.bidding) &&
        this.me.id === this.shared.game.nextPlayer
      );
    },
    canSetBid() {
      var valid =
        !isNaN(this.me.rebid) &&
        this.settingBid &&
        this.me.rebid > this.game.currentBid &&
        this.me.id === this.game.nextPlayer;

      if (!valid) {
        return false;
      }

      if (!this.isDealer) {
        return true;
      }

      return true;
    },
    myTurnToBid() {
      return this.settingBid && this.me.id === this.game.nextPlayer;
    },
    cardsAvailable() {
      return this.shared.cardsStillInDeck
        ? this.shared.cardsStillInDeck.length
        : 0;
    },
    me() {
      return this.shared.me;
    },
    roundIsActive() {
      return (
        this.game.state === "play" &&
        this.shared.tricks.length !== this.numCardsPerPlayer
        // && (!!this.shared.allCardsInPlay.length
        //   || !!this.shared.tricks.length)
      );
    },
    isDealer() {
      return this.me.id && this.me.id === this.game.dealer;
    },
    isBidWinner() {
      return this.me.id && this.me.id === this.bidWinner.id;
    },
    bidWinner() {
      return (
        this.shared.members.find((m) => m.id === this.game.bidWinner) || {}
      );
    },
    isBidWinnerPartner() {
      return this.me.id && this.me.id === this.bidWinnerPartner.id;
    },
    bidWinnerPartner() {
      if (!this.bidWinner.id) {
        return {};
      }
      var members = this.shared.members;
      var i = members.findIndex((m) => m.id === this.game.bidWinner);
      i += 2;
      if (i > members.length - 1) {
        i -= members.length;
      }
      return this.shared.members[i];
    },
    myPartner() {
      return this.shared.personOpposite(this.me);
    },
    isAdmin() {
      return this.shared.me.isAdmin;
    },
    readyToExchangeCards() {
      // how to prevent pulling back cards after ready?
      return this.numCardsFocused === 4;
    },
    numCardsFocused() {
      return this.myCardsInHand.filter((c) => c.focused).length;
    },
    myCards() {
      return this.shared.myCards;
    },
    numCardsSelected() {
      return this.myCards.filter((c) => !c.where && c.focused).length;
    },
    myCardsInPlay() {
      return this.myCards.filter((c) => c.where === "inPlay");
    },
    myCardsInHand() {
      return this.myCards.filter((c) => !c.where);
    },
    p0() {
      // used by viewers
      return this.shared.isViewer ? this.getPlayerInSpot(0) : null;
    },
    p1() {
      return this.getPlayerInSpot(1);
    },
    p2() {
      return this.getPlayerInSpot(2);
    },
    p3() {
      return this.getPlayerInSpot(3);
    },
    myTurn() {
      return (
        (((this.playing && this.shared.allCardsInPlay.length) ||
          this.bidding) &&
          this.shared.nextPlayer.id === this.me.id &&
          !this.playingCard &&
          !this.shared.personWhoTookTrick.id &&
          !this.game.roundFinished) ||
        this.canPassToNextDealer
      );
    },
  },
  watch: {
    myTurn(a, b) {
      if (a && !b) {
        this.becameMyTurn();
      }
    },
    isDealer(a, b) {
      if (a && !b) {
        this.becameMyTurn();
      }
    },
    // 'shared.nextPlayer': function (a, b) {
    //   var meId = this.me.id;
    //   if (a.id === meId && b.id !== meId && this.roundIsActive) {
    //     this.alertMyTurnNow();
    //   }
    // },
    "shared.personWhoTookTrick": function (a, b) {
      var meId = this.me.id;
      if (a.id === meId) {
        // person who wins takes it
        this.alertMyTurnNow();
      }
    },
    myCardsInHand: function (a) {
      this.adjustMyCardsOverlap();
    },
    "me.rebid": function (a, b) {
      this.bidChanging = true;
    },
  },
  mounted: function () {
    this.shared.$on("readyToDeal", this.onReadyToDeal);
    this.shared.$on("makeDeck", this.makeDeck);
    this.shared.$on("startNextRound", this.startNextRoundSameDealer);
    this.shared.$on("showLastTrick", this.showLastTrick);
    this.shared.$on("restartTrick", this.restartTrick);
    this.shared.$on("restartBidding", this.restartBidding);
    this.shared.$on("restartMelding", this.restartMelding);
    this.shared.$on("trumpChanged", this.trumpChanged);
    // this.shared.$on('currentBidChanged', this.currentBidChanged);
    this.shared.$on("sendCards", this.sendCards);
    this.shared.$on("showScores", this.showScores);
    this.shared.$on("showRules", this.showRules);

    window.addEventListener("keydown", this.keyPressed);
    window.addEventListener("resize", this.adjustMyCardsOverlap);

    this.adjustMyCardsOverlap();
  },
  beforeDestroy: function () {
    this.shared.$off("readyToDeal", this.onReadyToDeal);
    this.shared.$off("makeDeck", this.makeDeck);
    this.shared.$off("showLastTrick", this.showLastTrick);
    this.shared.$off("restartTrick", this.restartTrick);
    this.shared.$off("restartBidding", this.restartBidding);
    this.shared.$off("restartMelding", this.restartMelding);
    this.shared.$off("startNextRound", this.startNextRoundSameDealer);
    this.shared.$off("trumpChanged", this.trumpChanged);
    // this.shared.$off('currentBidChanged', this.currentBidChanged);
    this.shared.$off("sendCards", this.sendCards);
    this.shared.$off("showScores", this.showScores);
    this.shared.$off("showRules", this.showRules);

    window.removeEventListener("keydown", this.keyPressed);
    window.removeEventListener("resize", this.adjustMyCardsOverlap);
  },
  methods: {
    becameMyTurn() {
      this.alertMyTurnNow(true);
      if (this.bidding) {
        this.currentBidChanged();
      }
      this.preselectCards();
    },
    alertMyTurnNow(force) {
      if (!this.me.chime) {
        return;
      }
      if (this.shared.personWhoTookTrick.id === this.me.id) {
        this.playChime();
      } else if (this.myTurn) {
        this.playChime();
      } else if (force) {
        this.playChime();
      } else if (this.playing && !this.roundIsActive) {
        return;
      }

      this.alertMyTurn = true;
      setTimeout(() => (this.alertMyTurn = false), 1000);
    },
    playChime() {
      // chimeVolume : 0-9
      var vol =
        typeof this.me.chimeVolume === "undefined" ? 5 : this.me.chimeVolume;
      this.playSound(this.$refs["alert" + (this.me.chimeNum || 1)], vol);
    },
    updateChime(playNow) {
      if (!this.me.id || !this.me.chimeNum) return;

      firebaseDb.ref(`/people/${this.shared.gameKey}/${this.me.id}`).update({
        chime: this.me.chime,
        chimeNum: this.me.chimeNum,
        chimeVolume: this.me.chimeVolume,
      });

      if (this.me.chime || playNow) {
        this.playChime();
        window.focus();
      }
    },
    playSound(s, volume) {
      try {
        s.currentTime = 0;
        s.pause();
        s.currentTime = 0;
      } catch (e) {
        console.log("catch 1", e);
      }
      try {
        s.volume = volume / 100; // 0-100
        s.play().catch(function (e) {
          console.log("catch 2", e);
        });
      } catch (e) {
        console.log("catch 3", e);
      }
    },
    onReadyToDeal() {
      this.dealAll();
    },
    adjustMyCardsOverlap() {
      var availableWidth = this.$refs.myArea.clientWidth;

      var width = availableWidth - 30; // leave some margin
      var numCards = this.myCardsInHand.length;

      var spaceForCard = width / numCards;

      var cardWidth = 115; // approx image size with some margin
      var ratio = 1 - spaceForCard / cardWidth;

      var margin =
        spaceForCard > cardWidth ? 1 : Math.floor(-1.1 * ratio * cardWidth);

      // console.log(availableWidth, spaceForCard, numCards, margin);

      document.documentElement.style.setProperty("--cardMargin", margin + "px");
    },
    playerIsDealer(player) {
      return player.id === this.game.dealer;
    },
    playerIsBidWinner(player) {
      return player.id === this.bidWinner.id;
    },
    numTricks(player) {
      return this.shared.tricks.filter((t) => t.takenBy === player.id).length;
    },
    currentBidChanged() {
      // ensure my bid is not less than the currentBid
      if (
        this.shared.nextPlayer.id === this.me.id &&
        (!this.me.bidSet || !isNaN(this.me.bid))
      ) {
        //this.me.bid = this.game.currentBid + 1;
        this.me.rebid = this.game.currentBid + 1;
        // don't save it;
      }
    },
    setBidPass() {
      this.setBid("Pass");
    },
    setBid(bidOverride) {
      this.bidChanging = false;

      var updates = {};

      var bid = this.me.rebid;
      if (bidOverride) {
        bid = bidOverride;
      } else {
        if (bid <= this.game.currentBid) {
          return;
        }
      }

      updates[`/people/${this.shared.gameKey}/${this.me.id}/bid`] = bid;
      updates[`/people/${this.shared.gameKey}/${this.me.id}/bidSet`] = true;

      firebaseDb.ref().update(updates);
      updates = {};

      // move to next player
      var gamePath = `/games/${this.shared.gameKey}`;

      if (this.stillBidding) {
        var nextPlayer = this.moveToNextPlayer();
        if (nextPlayer) {
          if (this.shared.gameConfig.bidFrom) {
            // bidding is building from last person's bid
            if (!isNaN(bid)) {
              updates[`${gamePath}/currentBid`] = bid;
              updates[`/people/${this.shared.gameKey}/${nextPlayer}/rebid`] =
                bid + 1;
            }
          }
        }
      } else {
        // determine who has highest bid
        var lastBidder = this.shared.members.find(
          (m) => m.bid === this.game.currentBid
        );
        if (!lastBidder) {
          lastBidder = this.shared.members.find((m) => m.bid !== "Pass");
          var bid = this.game.currentBid;
          updates[
            `/people/${this.shared.gameKey}/${lastBidder.id}/rebid`
          ] = bid;
          updates[`/people/${this.shared.gameKey}/${lastBidder.id}/bid`] = bid;
          updates[
            `/people/${this.shared.gameKey}/${lastBidder.id}/bidSet`
          ] = true;
        }

        updates[`${gamePath}/bidWinner`] = lastBidder.id;
        updates[`${gamePath}/meldComplete`] = false;
        updates[`${gamePath}/state`] = "manualTrump";
        updates[`${gamePath}/firstPlayer`] = lastBidder.id;
        updates[`${gamePath}/nextPlayer`] = lastBidder.id;

        // determine first player, after the dealer
        // var id = this.shared.getNextPlayer(true).id;
        // updates[`${gamePath}/nextPlayer`] = id;
      }

      firebaseDb.ref().update(updates);
    },
    setFirstPlayer(player) {
      if (player.id === this.me.id && this.game.firstPlayer !== player.id) {
        // only have one player record this
        var updates = {};
        var gamePath = `/games/${this.shared.gameKey}`;
        updates[`${gamePath}/previousFirstPlayer`] = this.game.firstPlayer;
        updates[`${gamePath}/firstPlayer`] = player.id;
        firebaseDb.ref().update(updates);
      }
    },
    exchangeCards() {
      // give 4 cards to partner
      if (!this.readyToExchangeCards) {
        return;
      }

      var updates = {};

      var otherPerson = this.isBidWinner
        ? this.bidWinnerPartner
        : this.bidWinner;

      if (otherPerson.readyToExchange) {
        // both are ready
        updates = {};
        updates[
          `/people/${this.shared.gameKey}/${this.me.id}/sendCards`
        ] = true;
        updates[
          `/people/${this.shared.gameKey}/${otherPerson.id}/sendCards`
        ] = true;
      } else {
        updates[
          `/people/${this.shared.gameKey}/${this.me.id}/readyToExchange`
        ] = true;
      }
      firebaseDb.ref().update(updates);
    },
    sendCards() {
      // I've been told to send the cards I have ready
      if (!this.isBidWinner && !this.isBidWinnerPartner) {
        console.log("invalid sendCards");
        return;
      }
      // if (!this.readyToExchangeCards) {
      //   console.log('invalid sendCards 2')
      //   return;
      // }
      var cards = this.myCardsInHand.filter((c) => c.focused);
      var otherPersonId = this.isBidWinner
        ? this.bidWinnerPartner.id
        : this.bidWinner.id;

      var updates = {};
      var gameKey = this.shared.gameKey;

      cards.forEach((c) => {
        // console.log('sendig', c.key)
        updates[`/deck/${gameKey}/cards/${c.key}/whoFrom`] = this.me.id;
        updates[`/deck/${gameKey}/cards/${c.key}/who`] = otherPersonId;
        updates[`/deck/${gameKey}/cards/${c.key}/focused`] = true;
      });

      updates[`/people/${gameKey}/${this.me.id}/readyToExchange`] = false;
      updates[`/people/${gameKey}/${this.me.id}/sendCards`] = false;

      this.justSentCards = true;
      firebaseDb.ref().update(updates);

      if (this.isBidWinner) {
        // delay this
        setTimeout(() => {
          updates = {};
          updates[`/games/${gameKey}/state`] = "meld";
          firebaseDb.ref().update(updates);
        }, 5000);
      }
    },
    showMeld() {
      // if (!this.numCardsFocused) {
      //   if (!this.myCardsInPlay) {
      //     this.setMeldPoints([]);
      //   }
      //   return;
      // }
      var updates = {};
      this.myCardsInHand.forEach((c) => {
        var path = `/deck/${this.shared.gameKey}/cards/${c.key}`;
        updates[`${path}/where`] = c.focused ? "inPlay" : "";
        updates[`${path}/focused`] = false;
      });
      // console.log(updates)
      firebaseDb
        .ref()
        .update(updates)
        .then((p) => {
          var meldShowing = this.myCardsInPlay;
          this.setMeldPoints(meldShowing);
        });
    },
    setMeldPoints(cards) {
      var cardKeys = cards.map((c) => c.key);
      var scoring = this.shared.deckType.scoreMeld(
        cardKeys,
        this.game.trumpSuit
      );
      var score = scoring.reduce((acc, s) => acc + s.p, 0);
      if (!scoring.length) {
        scoring = [{ d: "No meld", p: 0 }];
      }

      var updates = {};
      updates[`/people/${this.shared.gameKey}/${this.me.id}/meld`] = score;
      updates[
        `/people/${this.shared.gameKey}/${this.me.id}/meldDisplay`
      ] = scoring;
      firebaseDb.ref().update(updates);
    },
    recallMeld() {
      if (!this.myCardsInPlay.length) return;
      var updates = {};
      this.myCardsInPlay.forEach((c) => {
        c.focused = true;

        var path = `/deck/${this.shared.gameKey}/cards/${c.key}`;
        updates[`${path}/where`] = "";
        updates[`${path}/focused`] = true;
      });

      firebaseDb.ref().update(updates);
    },
    defocusAllCards() {
      var updates = {};
      this.myCardsInHand
        .filter((c) => c.focused)
        .forEach((c) => {
          c.focused = false;
          updates[
            `/deck/${this.shared.gameKey}/cards/${c.key}/focused`
          ] = false;
        });

      firebaseDb.ref().update(updates);
    },
    finishMeld() {
      if (this.shared.allCardsInPlay.length) {
        return;
      }

      var updates = {};

      var gamePath = `/games/${this.shared.gameKey}`;

      updates[`${gamePath}/meldComplete`] = true;
      updates[`${gamePath}/state`] = "play";
      updates[`${gamePath}/nextPlayer`] = this.bidWinner.id;

      // put all cards down
      this.shared.allCards
        .filter((c) => c.focused)
        .forEach((c) => {
          c.focused = false;
          updates[
            `/deck/${this.shared.gameKey}/cards/${c.key}/focused`
          ] = false;
        });

      firebaseDb.ref().update(updates);
    },
    getSuitUrl(suitKey) {
      return require(`@/assets/standard2/suit-${suitKey}-s.png`);
    },
    endTrick() {
      // determine trick-winning card
      var winningCard = this.shared.deckType.determineWinningCard(this.shared);

      firebaseDb
        .ref(`/deck/${this.shared.gameKey}/cards/${winningCard.key}`)
        .update({
          tookTrick: true,
        });
    },
    // finishBidding() {
    //   firebaseDb
    //     .ref(`/games/${this.shared.gameKey}`)
    //     .update({
    //       state: 'play'
    //     });
    // },
    clearTable() {
      // end the trick by the trick taker
      var winner = this.shared.personWhoTookTrick;
      if (!winner.id) {
        return;
      }
      this.takesTrick(winner); // sets the winner to start next

      var cardsTaken = this.shared.allCards
        .filter((c) => c.takenBy === winner.id)
        .map((c) => c.key);
      var points = this.shared.deckType.scoreCards(cardsTaken);

      firebaseDb.ref(`/people/${this.shared.gameKey}/${this.me.id}`).update({
        points: points,
      });

      // done?
      if (!this.myCardsInHand.length) {
        // this is run on the computer of the current dealer
        // I'm out of cards... round must be done!

        this.saveRoundResults();

        firebaseDb.ref(`/games/${this.shared.gameKey}`).update({
          roundFinished: true,
          nextPlayer: "",
        });
      }
    },
    startNextRoundSameDealer() {
      this.startNextRound(true);
    },
    startNextRound(keepSameDealerAndDealNow) {
      if (!this.shared.canPlayAnotherRound) {
        return;
      }

      var updates = {};
      var gamePath = `/games/${this.shared.gameKey}`;
      updates[`${gamePath}/state`] = "startingRound";
      updates[`${gamePath}/roundNum`] = (this.game.roundNum || 0) + 1;
      updates[`${gamePath}/roundFinished`] = false;
      updates[`${gamePath}/nextPlayer`] = "";
      updates[`${gamePath}/trumpSuit`] = "";

      // increase cards?
      if (this.shared.configAnswers.incrementCards) {
        var numCards = this.shared.configAnswers.numCards;
        if (
          numCards > 0 &&
          numCards < this.shared.configAnswers.incrementCardsMax
        ) {
          updates[
            `/configAnswers/${this.shared.gameKey}/${this.shared.game.code}/numCards`
          ] = numCards + 1;
        }
      }

      if (!keepSameDealerAndDealNow) {
        // move dealership to next person
        var oldDealerId = this.game.dealer;
        var newDealerId = this.shared.getNextPlayer(true).id;

        this.rotateDealerOut(oldDealerId, newDealerId, updates);

        updates[`${gamePath}/dealer`] = newDealerId;
      }

      if (this.shared.gameConfig.useBidding) {
        // clear out bids
        this.shared.members.forEach((m) => {
          updates[`/people/${this.shared.gameKey}/${m.id}/bidSet`] = false;
          updates[`/people/${this.shared.gameKey}/${m.id}/bidReady`] = false;
          updates[`/people/${this.shared.gameKey}/${m.id}/bid`] = "";
          updates[`/people/${this.shared.gameKey}/${m.id}/rebid`] = "";
          updates[
            `/people/${this.shared.gameKey}/${m.id}/readyToExchange`
          ] = false;
          updates[`/people/${this.shared.gameKey}/${m.id}/sendCards`] = false;
        });
      }

      if (this.shared.gameConfig.useMeld) {
        this.shared.members.forEach((m) => {
          updates[`/people/${this.shared.gameKey}/${m.id}/meld`] = 0;
          updates[`/people/${this.shared.gameKey}/${m.id}/points`] = 0;
          updates[`/people/${this.shared.gameKey}/${m.id}/meldDisplay`] = [];
        });
      }

      firebaseDb.ref().update(updates);

      firebaseDb.ref(`/tricks/${this.shared.gameKey}`).remove();

      // "collect" the cards, but do not shuffle
      var cards = this.shared.deck.cards;
      Object.keys(cards).forEach((k) => {
        var c = cards[k];
        c.who = "";
        c.where = "";
      });

      firebaseDb.ref(`/deck/${this.shared.gameKey}`).set({
        cards: cards,
        state: "collected",
      });

      if (keepSameDealerAndDealNow) {
        this.makeCards();
      }
    },
    rotateDealerOut(oldDealerId, newDealerId, updates) {
      // round was done. if > 4 players, adjust order and put this person at the end
      var numPlayers = this.shared.people.length;
      var tableSize =
        this.shared.gameConfig.tableSize || this.shared.gameConfig.maxPlayers;

      if (numPlayers > tableSize) {
        //
        if (!oldDealerId) return;
        var oldDealer = this.shared.people.find((p) => p.id === oldDealerId);
        if (!oldDealer) return;

        // put the new dealer as #0
        var newDealer = this.shared.people.find((p) => p.id === newDealerId);

        // find the next order
        var tempOrder1 = numPlayers;
        var tempOrder2 = 0;

        var foundNewDealer = false;
        // debugger;
        this.shared.people.forEach((p) => {
          if (p.id === newDealerId) {
            foundNewDealer = true;
          }
          if (!foundNewDealer) {
            p.sortOrder = ++tempOrder1;
          } else {
            p.sortOrder = ++tempOrder2;
          }
          if (p.id === oldDealerId) {
            p.sortOrder = 99;
          }
        });

        this.shared.people.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1));

        var numWanted = 4;

        var path = `/people/${this.shared.gameKey}`;
        // debugger;
        this.shared.people.forEach((p, i) => {
          updates[path + `/${p.id}/sortOrder`] = i;
          updates[path + `/${p.id}/active`] = i < numWanted;
        });
      }
    },
    saveRoundResults() {
      // should only be done by dealer
      // could move to a server function

      var bid = this.game.currentBid;

      var roundInfo = {
        id: this.game.roundNum || 1,
        suit: this.game.trumpSuit,
        doneTime: moment().toISOString(),
        dealer: this.game.dealer,
        bidWinner: this.game.bidWinner,
        bid: bid,
        players: {},
      };

      // last trick
      var lastTrickNum = this.shared.tricks.length - 1;
      var tookLastTrick = this.shared.tricks[lastTrickNum].takenBy;

      // determine score for each member - pinochle version
      this.shared.members.forEach((m) => {
        var scoreForRound = 0;

        var isBidTeam =
          m.id === this.game.bidWinner || m.id === this.bidWinnerPartner.id;
        var partner = this.shared.personOpposite(m);

        var gotLastTrick =
          tookLastTrick === m.id || tookLastTrick === partner.id;

        var myMeld = m.meld || 0;
        var myPoints = m.points || 0;

        var partnerMeld = partner.meld || 0;
        var partnerPoints = partner.points || 0;

        var score =
          myMeld +
          partnerMeld +
          myPoints +
          partnerPoints +
          (gotLastTrick ? 1 : 0);
        var failedScore = 0;

        if (isBidTeam && score < bid) {
          // failed
          failedScore = score;
          score = -bid;
        }

        if (!isBidTeam && myPoints + partnerPoints === 0) {
          score = 0; // forfeit meld
        }

        var tricksTaken = this.shared.tricks.filter((t) => t.takenBy === m.id)
          .length;

        roundInfo.players[m.id] = {
          bidWinner: m.id === this.game.bidWinner,
          lastTrick: gotLastTrick,
          taken: tricksTaken,
          myMeld: myMeld,
          myPoints: myPoints,
          score: score,
          failedScore: failedScore,
        };
      });

      var path = `/rounds/${this.shared.gameKey}`;
      firebaseDb.ref(path).push(roundInfo);
    },
    moveToNextPlayer() {
      var nextPlayer = this.shared.getNextPlayer();

      if (this.bidding && nextPlayer.bid === "Pass") {
        // already bid
        if (this.stillBidding) {
          this.game.nextPlayer = nextPlayer.id;
          return this.moveToNextPlayer();
        }
        return false;
      }

      if (
        this.playing &&
        this.shared.allCardsInPlay.find((c) => c.who === nextPlayer.id)
      ) {
        // already played in this trick
        this.endTrick();
        return false;
      }

      var gamePath = `/games/${this.shared.gameKey}`;
      var updates = {};

      updates[`${gamePath}/nextPlayer`] = nextPlayer.id;

      firebaseDb.ref().update(updates);

      return nextPlayer.id;
    },
    restartTrick() {
      // pull back any cards that were played
      // put nextPlayer back to who started the trick

      if (!this.shared.allCardsInPlay.length) {
        // no cards in play... should not be trying to restart it
        return;
      }
      var updates = {};

      this.shared.allCardsInPlay.forEach((c) => {
        var path = `/deck/${this.shared.gameKey}/cards/${c.key}`;
        updates[`${path}/where`] = "";
        updates[`${path}/tookTrick`] = "";
        updates[`${path}/playOrder`] = 0;
        updates[`${path}/focused`] = false;
      });

      // if viewing last trick, use previousFirstPlayer

      var gamePath = `/games/${this.shared.gameKey}`;

      updates[`${gamePath}/nextPlayer`] = this.game.firstPlayer || "";

      firebaseDb.ref().update(updates);
    },
    restartMelding() {
      var updates = {};

      var gamePath = `/games/${this.shared.gameKey}`;

      updates[`${gamePath}/meldComplete`] = false;
      updates[`${gamePath}/state`] = "meld";

      this.shared.members.forEach((m) => {
        updates[`/people/${this.shared.gameKey}/${m.id}/meld`] = 0;
        updates[`/people/${this.shared.gameKey}/${m.id}/points`] = 0;
        updates[`/people/${this.shared.gameKey}/${m.id}/meldDisplay`] = [];
      });

      firebaseDb.ref().update(updates);
    },
    restartBidding() {
      // wipe all bids
      // put nextPlayer back to player after dealer
      if (this.shared.numCardsPlayed) {
        return;
      }

      var updates = {};

      var playerPath = `/people/${this.shared.gameKey}`;
      this.shared.members.forEach((m) => {
        updates[`${playerPath}/${m.id}/rebid`] = m.bid;
        updates[`${playerPath}/${m.id}/bid`] = "";
        updates[`${playerPath}/${m.id}/bidSet`] = false;
        updates[`${playerPath}/${m.id}/bidReady`] = false;
        updates[`${playerPath}/${m.id}/readyToExchange`] = false;
        updates[`${playerPath}/${m.id}/sendCards`] = false;
        updates[`${playerPath}/${m.id}/meld`] = 0;
        updates[`${playerPath}/${m.id}/points`] = 0;
        updates[`${playerPath}/${m.id}/meldDisplay`] = [];
      });

      // next player is dealer's partner
      var nextPlayer = this.myPartner.id; //this.shared.getNextPlayer(true).id;
      var gamePath = `/games/${this.shared.gameKey}`;

      if (this.shared.gameConfig.bidFrom) {
        updates[`${gamePath}/currentBid`] = this.shared.gameConfig.bidFrom;
        updates[`${gamePath}/meldComplete`] = false;
        updates[`${gamePath}/bidWinner`] = "";
        updates[`${gamePath}/trumpSuit`] = "";
        updates[
          `/people/${this.shared.gameKey}/${nextPlayer}/bid`
        ] = this.shared.gameConfig.bidFrom;
        updates[
          `/people/${this.shared.gameKey}/${nextPlayer}/rebid`
        ] = this.shared.gameConfig.bidFrom;
      }

      this.shared.allCards
        // give cards back
        .filter((c) => c.whoFrom)
        .forEach((c) => {
          updates[`/deck/${this.shared.gameKey}/cards/${c.key}/who`] =
            c.whoFrom;
        });

      updates[`${gamePath}/nextPlayer`] = nextPlayer || "";
      updates[`${gamePath}/state`] = "bid";

      firebaseDb.ref().update(updates);
    },
    trumpChanged() {
      var sorter = this.shared.deckType.cardSorter(this.shared);
      this.shared.myCards.sort(sorter);
    },
    makeDeck() {
      // recreate deck (result is shuffled)
      var redealing = this.shared.cardsInUse;
      var cards = this.makeCards();

      var updates = {};
      var gamePath = `/games/${this.shared.gameKey}`;
      var nextState = "";

      if (redealing) {
        this.restartBidding();
      }

      // determine first bidder, after the dealer
      // next player is dealer's partner
      var playerId = this.myPartner.id; //this.shared.getNextPlayer(true).id;
      updates[`${gamePath}/nextPlayer`] = playerId;

      updates[`${gamePath}/currentBid`] = this.shared.gameConfig.bidFrom;
      updates[
        `/people/${this.shared.gameKey}/${playerId}/rebid`
      ] = this.shared.gameConfig.bidFrom;

      updates[`/deck/${this.shared.gameKey}/cards`] = cards;
      updates[`/deck/${this.shared.gameKey}/state`] = "shuffled";
      updates[`/deck/${this.shared.gameKey}/trumpSuit`] = "";

      updates[`${gamePath}/roundNum`] = this.game.roundNum || 1;
      updates[`${gamePath}/roundFinished`] = false;
      updates[`${gamePath}/state`] = "bid";
      updates[`${gamePath}/bidWinner`] = "";

      firebaseDb.ref().update(updates);

      // clear out tricks
      firebaseDb.ref(`/tricks/${this.shared.gameKey}`).remove();
    },
    makeCards() {
      var deckType = this.shared.deckType;
      if (deckType) {
        var cards = deckType.makeCards(this.shared.configAnswers);

        return cards;
      }
      return {};
    },
    bidWinnerChoosingTrumpDone() {
      // set new trump
      var updates = {};
      var gamePath = `/games/${this.shared.gameKey}`;
      updates[`${gamePath}/trumpSuit`] = this.newTrump;
      updates[`${gamePath}/state`] = "exchangeCards";

      firebaseDb.ref().update(updates);
    },
    preselectCards() {
      // do nothing if something is selected
      if (this.myCardsInHand.find((c) => c.focused)) {
        return;
      }

      // if only one is playable, select it
      if (this.myCardsInHand.filter((c) => c.playable).length === 1) {
        this.myCardsInHand.find((c) => c.playable).focused = true;
      }
    },
    cardClicked(c, where) {
      var allowOnlyOneCard = this.allowOnlyOneCard;

      if (this.justSentCards) {
        this.myCardsInHand
          .filter((card) => card.key !== c.key)
          .forEach((card) => (card.focused = false));
        this.justSentCards = false;
      }

      // if (this.game.state !== 'play' && this.game.state !== 'meld') {
      //   return;
      // }

      if (c.focused) {
        // don't allow deselect if only one is playable
        var playable = this.myCardsInHand.filter((c) => c.playable);
        if (
          allowOnlyOneCard &&
          playable.length === 1 &&
          playable[0].key === c.key
        ) {
          return;
        }

        c.focused = false;
        return;
      }
      if (this.game.state === "bid") {
        return;
      }
      if (where === "inHand" && c.playable) {
        if (this.numCardsInPlay && allowOnlyOneCard) {
          // I already have a card in play
          return;
        }

        // if only playable card and it is focused, leave it
        if (
          c.focused &&
          allowOnlyOneCard &&
          this.myCardsInHand.filter((c) => c.playable).length === 1
        ) {
          return;
        }

        if (
          this.game.state === "exchangeCards" &&
          (this.isBidWinner || this.isBidWinnerPartner)
        ) {
          if (this.numCardsFocused >= 4) {
            return;
          }
        }

        var focused = !c.focused;
        c.focused = focused;

        if (allowOnlyOneCard) {
          this.shared.focusedCard = c.key;
        }

        if (c.focused && allowOnlyOneCard) {
          // ensure no other card is focused
          this.myCardsInHand
            .filter((card) => card.key !== c.key)
            .forEach((card) => (card.focused = false));
        }
      }
    },
    keyPressed(ev) {
      var key = ev.key;

      // console.log(`>${key}<`);

      switch (key) {
        case "?": // show tips
        case "/": // show tips
          this.showKeyTips = true;
          break;
        case "a": // toggle alert chime
          this.me.chime = !this.me.chime;
          break;
        case "s": // toggle scores
          this.shared.$emit("showScores");
          break;
        case "r": // toggle rules
          this.shared.$emit("showRules");
          break;
        case "f": // toggle
          this.shared.$emit("toggleFullScreen");
          break;
        case "Enter":
          var ref = this.$refs.tableButtons;
          if (ref) {
            var btn = ref.querySelector("button");

            if (btn) {
              btn.click();
              return;
            }
          }

          ref = this.$refs.tableCenter;
          if (ref) {
            btn = ref.querySelector("button");

            if (btn) {
              btn.click();
              return;
            }
          }
          // if (this.numCardsSelected && this.playing && this.shared.nextPlayer.id === this.me.id) {
          //   this.playFocusedCards();
          //   return;
          // }
          // if (this.shared.personWhoTookTrick.id === this.me.id) {
          //   this.clearTable();
          //   return;
          // }
          // if (this.isDealer
          //   && !this.shared.cardsInUse
          //   && !(this.shared.game.roundFinished || this.shared.personWhoTookTrick.id)
          // ) {
          //   this.makeDeck();
          // }
          break;
      }

      switch (key) {
        case "Escape":
          // deselect all cards
          this.myCardsInHand.forEach((c) => {
            c.select = false;
            c.focused = false;
          });

          if (allowOnlyOneCard) {
            this.shared.focusedCard = "";
          }
          this.currentCardSelectorIndex = -1;

          break;
      }

      if (this.settingBid && !this.roundIsActive) {
        switch (key) {
          case "Enter":
            if (this.canSetBid) {
              this.setBid();
            }
            break;
          case "+":
          case "ArrowUp":
          case "ArrowRight":
            if (this.me.rebid < this.myCards.length) {
              this.me.rebid++;
            }
            break;
          case "-":
          case "ArrowDown":
          case "ArrowLeft":
            if (this.me.rebid > 0) {
              this.me.rebid--;
            }
            break;
        }
      }

      var allowOnlyOneCard = this.allowOnlyOneCard;

      if (this.playing && this.roundIsActive) {
        var delta = 0;
        var selectThis = false;
        var ref = this.$refs.myCenterButtons;

        switch (key) {
          case "ArrowRight":
            delta = 1;
            break;
          case "ArrowLeft":
            delta = -1;
            break;
          case " ":
            if (allowOnlyOneCard) {
              // var btn = ref && ref.querySelector('button');
              // if (btn) {
              //   btn.click();
              //   return;
              // }
            } else {
              selectThis = true;
            }
            ev.preventDefault();
            break;
          case "Enter":
            var btn = ref && ref.querySelector("button");

            if (btn) {
              btn.click();
              return;
            }
            break;
        }

        if (delta || selectThis) {
          // select a card
          var cards = this.myCardsInHand;
          var lastCardIndex = cards.length - 1;

          // if only allow one, find the one focused and move the focus
          if (allowOnlyOneCard && delta) {
            var i = cards.findIndex((c) => c.focused);
            var next = i === -1 ? (delta === 1 ? 0 : lastCardIndex) : i + delta;

            while (true) {
              if (next > lastCardIndex || next < 0) {
                // ignore
                break;
              } else {
                var nextCard = cards[next];
                if (nextCard.playable) {
                  nextCard.focused = true;
                  if (i !== -1) {
                    cards[i].focused = false;
                  }

                  if (allowOnlyOneCard) {
                    this.shared.focusedCard = nextCard.key;
                  }
                  break;
                }
              }
              next += delta;
            }
          }
          if (!allowOnlyOneCard) {
            // multiselect
            var i = this.currentCardSelectorIndex;

            if (selectThis) {
              var card = cards[i];
              if (card && card.playable) {
                card.focused = !card.focused;
                card.select = !card.focused;
              }
            } else {
              var next =
                i === -1 ? (delta === 1 ? 0 : lastCardIndex) : i + delta;

              while (true) {
                if (next > lastCardIndex || next < 0) {
                  // ignore
                  break;
                } else {
                  var nextCard = cards[next];
                  if (i !== -1) {
                    cards[i].select = false;
                  }
                  if (nextCard.playable) {
                    this.currentCardSelectorIndex = next;
                    nextCard.select = true;
                    break;
                  }
                }
                next += delta;
              }
            }
          }
        }
      }
    },
    playFocusedCards() {
      var nextInPlayOrder = 1 + this.shared.allCardsInPlay.length;
      this.playingCard = true;
      this.shared.focusedCard = "";
      var updates = {};
      this.myCardsInHand
        .filter((c) => c.focused)
        .forEach((c) => {
          var path = `/deck/${this.shared.gameKey}/cards/${c.key}`;
          updates[`${path}/where`] = "inPlay";
          updates[`${path}/playOrder`] = nextInPlayOrder;
          // updates[`${path}/faceDown`] = false;
          updates[`${path}/focused`] = false;
        });

      firebaseDb
        .ref()
        .update(updates)
        .then((p) => {
          this.moveToNextPlayer();
          this.playingCard = false;
          this.lastPlayerId = this.me.id;
          this.currentCardSelectorIndex = -1;
          this.adjustMyCardsOverlap();
        });
    },
    // pullCardsBack() {
    //   var updates = {};

    //   this.myCardsInPlay
    //     .forEach(c => {
    //       var cardPath = `/deck/${this.shared.gameKey}/cards/${c.key}`;
    //       updates[`${cardPath}/where`] = '';
    //       updates[`${cardPath}/playOrder`] = 0;
    //       updates[`${cardPath}/newCard`] = true;
    //     });

    //   updates[`/games/${this.shared.gameKey}/nextPlayer`] = this.me.id;

    //   debugger;

    //   // if trick was ended, need to undo -- very complex

    //   firebaseDb.ref().update(updates);

    // }
  },
};
</script>

<style lang="less">
.Stage2Pinochle {
  //display: flex;
  //flex-direction: column;
  min-height: 100%;
  height: 100%;
  position: relative;

  &.next {
    .myArea {
      border-color: #de2626;
    }
  }

  .drawPile {
    position: relative;
    padding: 0.5em;
    width: 98px;
    height: 147px;

    border: 1px solid black;

    .CardHolder {
      position: absolute;
      top: 0.5em;
      left: 0.5em;
    }
  }

  @myArea: 240px;
  @myAreaViewer: auto;

  .seatingWithTable {
    flex-grow: 1;
    display: grid;
    // max-height: 100%;
    min-height: 400px;
    // height: 100%;
    gap: 3px 3px;
    grid-template-columns: minmax(0, 0.7fr) 2fr minmax(0, 0.7fr);
    grid-template-rows: auto minmax(330px, auto) @myArea;
    grid-template-areas:
      "topLeft seatT1 topRight"
      "seatL1 table seatR1"
      "myArea myArea myArea";

    button {
      align-self: center;
    }
  }

  .table {
    grid-area: table;
    min-height: 320px;

    display: grid;
    gap: 1px 1px;
    grid-template-columns: 1fr 115px 1fr;
    grid-template-rows: 1.4fr auto 1.5fr auto; // old edge - don't use fr for middle
    grid-template-areas:
      "tableL1 tableT1 tableR1"
      "tableL1 tableCenter tableR1"
      "tableL1 myPlay tableR1"
      "trumpSuit tableButtons leadSuit";

    position: relative;

    background: #e8f9e6;
    background-clip: padding-box;
    border: solid 5px transparent;
    border-radius: 25px;

    // border-radius: 1em;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -5px;
      border-radius: inherit;
      // background: linear-gradient(to right, red, orange);
      background: darkgreen;
    }

    &.isViewer {
      .seatingWithTable {
        grid-template-rows: auto minmax(320px, 1.3fr) @myAreaViewer;
      }
    }
    .mainLogo {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 40%;
      transition: all 0.5s;
      img {
        height: 140%;
      }
    }
    .cardArea {
      display: flex;
      // justify-content: center;
      align-items: center;
      position: relative;

      .CardHolder {
        display: block;
        margin-right: -96px;

        &:last-child {
          margin-right: 5px;
        }
      }

      &.left {
        .meldDisplay {
          right: 30%;
        }
      }

      &.right {
        .meldDisplay {
          left: 30%;
        }
      }

      &.top {
        .meldDisplay {
          top: 37%;
        }
      }

      &.bottom {
        .meldDisplay {
          bottom: 0;
        }
      }
    }
  }

  &.players_4 {
    .seatingWithTable {
      grid-template-areas:
        "topLeft seatT1 topRight"
        "seatL1 table seatR1"
        "myArea myArea myArea";
    }
    .table {
      grid-template-rows: 1.4fr auto 1.5fr auto; // old edge - don't use fr for middle
      grid-template-areas:
        "tableL1 tableT1 tableR1"
        "tableL1 tableCenter tableR1"
        "tableL1 myPlay tableR1"
        "trumpSuit tableButtons leadSuit";
    }
    &.isViewer {
      .seatingWithTable {
        grid-template-rows: auto minmax(200px, 1fr) @myAreaViewer;
      }
      .table {
        grid-template-areas:
          "tableL1 tableT1 tableR1"
          "tableL1 tableCenter tableR1"
          "tableL1 tableB1 tableR1"
          "trumpSuit tableButtons leadSuit";
      }
    }
  }

  .p0table,
  .seatB1 {
    display: none;
  }

  .tableButtons {
    grid-area: tableButtons;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 5px;
    z-index: 2;
    button {
      white-space: nowrap;
    }
  }
  .trumpSuit {
    grid-area: trumpSuit;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 3px;
    .trumpInfo {
      margin: -1rem 0 -0.5rem 10px;
      font-size: 2.1rem;
    }
  }
  .leadSuit {
    grid-area: leadSuit;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 3px;
    .trumpInfo {
      margin: -1rem 10px -0.5rem 0;
      font-size: 2.1rem;
    }
  }

  &.isViewer {
    .cardArea.p0table {
      grid-area: tableB1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;

      .meldDisplay {
        bottom: 3px;
        left: 50%;
        transform: translate(-50%);
      }
    }

    .smallCardsGroups {
      .CardHolder {
        max-height: 40px; // flex not working
      }
    }

    // .p0table {
    //   display: flex;
    //   grid-area: p0table;
    // }
    // .p0left {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   grid-area: p0left;
    // }
    // .p0right {
    //   display: flex;
    //   grid-area: p0right;
    // }

    .seatB1 {
      grid-area: myArea;
    }

    .myPlay {
      display: none;
    }
    .myArea {
      display: none;
    }
  }

  .tableCenter {
    // grid-area: tableCenter;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-self: center;
    font-size: 1.7em;
    overflow: visible;
    position: absolute;
    z-index: 1;
    left: 25%;
    right: 25%;
    // min-height: 2em;
    width: auto;
    text-align: center;
    padding: 0.25em 0.5em 0.25em;
    overflow: visible;
    background: white;
    border: 2px solid grey;
    border-radius: 5px;
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 1);

    button {
      margin-top: 1em;
    }
  }

  .myPlay {
    grid-area: myPlay;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    .meldDisplay {
      bottom: 3px;
      left: 50%;
      transform: translate(-50%);
    }
  }

  .tableT1 {
    grid-area: tableT1;
    display: flex;
    justify-content: stretch;
    align-content: center;
    padding-top: 6px;
    .bidNum {
      // justify-self: flex-start;
      height: 100%;
    }
  }

  .tableL1 {
    grid-area: tableL1;
    .bidNum {
      padding-left: 8%;
      text-align: left;
      width: 100%;
    }
  }

  .tableR1 {
    grid-area: tableR1;
    .bidNum {
      padding-right: 10%;
      text-align: right;
      width: 100%;
    }
  }

  .tableL1,
  .tableL2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .tableR1,
  .tableR2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .myArea {
    grid-area: myArea;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 0 2px;
    border: lightblue 6px solid;
    background: #fbfbf9;
    border-radius: 5px;

    .cardArea {
      margin-top: 20px;
      padding: 0 5px;
      height: 150px;
      align-items: center;
    }

    .suit_c + .suit_s,
    .suit_s + .suit_c,
    .suit_d + .suit_h,
    .suit_h + .suit_d {
      margin-left: 5px;
    }
    .BidDisplay {
      .title {
        span + span {
          margin-left: 1em;
        }
      }
    }
  }

  .seatArea {
    // for other players, or all for viewers
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    align-content: center;
    padding: 5px 5px 0;
    border: lightblue 6px solid;
    background: #fbfbf9;
    border-radius: 5px;

    &.next {
      border-color: #de2626;
    }
    .name {
      text-align: left;
      font-weight: bold;
    }

    .nameAndDealer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-self: top;
      align-items: center;
      .dealer {
        padding: 1px 5px;
      }
    }

    .allSmallCards,
    .smallCardsGroups {
      .CardHolder {
        overflow: hidden;
      }
    }

    &.top,
    &.bottom {
      display: grid;
      grid-template-areas: ". ." "cards cards";
      grid-template-columns: 1fr auto;
      grid-template-rows: auto auto;
      padding: 5px 5px;
      min-height: 65px;

      .nameAndDealer {
        display: flex;
        align-self: top;
        align-items: center;
        .dealer {
          margin: 0 0 0 15px;
          padding: 1px 5px;
        }
      }

      .miniCards {
        grid-area: cards;
      }
      .BidDisplay {
        justify-content: flex-end;
        .title {
          span + span {
            margin-left: 1em;
          }
        }
      }
    }
    &.top {
      .dealer {
        align-self: flex-end;
      }
    }
    &.bottom {
      .dealer {
        align-self: flex-start;
      }
    }

    &.left,
    &.right {
      .BidDisplay {
        margin: 3px auto;
        .title {
          span {
            display: block;
          }
        }
      }
    }

    &.viewAllCards {
      &.top,
      &.bottom {
        display: grid;
        grid-template-areas: ". ." "cards cards";
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        max-height: 106px;
        overflow: hidden;
        align-content: flex-start;
        align-items: center;

        .allSmallCards {
          grid-area: cards;
          display: flex;
          padding-top: 5px;
          max-width: 100%;
        }
        .smallCardsGroups {
          display: none;
        }
        .BidDisplay {
          justify-content: flex-end;
        }
      }

      &.left,
      &.right {
        justify-content: space-between;
        max-height: 100%;

        .allSmallCards {
          display: none;
        }
        .smallCardsGroups {
          display: flex;
          overflow: auto;
          align-items: flex-start;
          justify-content: center;
          padding: 2px 0 2px 0;
          margin: 0 -3px 0 -5px;

          & > div {
            padding: 0 2px;
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
            overflow-x: hidden;
          }
        }
      }
    }
  }

  .cardArea {
    display: flex;
    justify-content: center;

    .CardHolder {
      display: block;
      // margin-right: calc(var(--numCards) * -5px); //TODO
      margin-right: var(--cardMargin);

      &:last-child {
        margin-right: 5px;
      }
    }
  }
  .bidNum {
    font-size: 1.25rem;
    span {
      display: inline-block;
      text-align: center;
    }
  }

  .seatL1 {
    grid-area: seatL1;
  }

  .seatL2 {
    grid-area: seatL2;
  }

  .seatT1 {
    grid-area: seatT1;
  }

  .seatR1 {
    grid-area: seatR1;
  }

  .seatR2 {
    grid-area: seatR2;
  }

  .bottomDealerDisplay {
    display: none;
    grid-area: dealer;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 0.5em;
    position: relative;
    &::before {
      content: "Dealer";
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }

  &.isDealer {
    .bottomDealerDisplay {
      display: block;
    }
  }

  .admin {
    display: none;
    grid-area: admin;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0.5em;
    position: relative;
    &::before {
      content: "Admin";
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }

  &.isAdmin {
    .admin {
      display: block;
    }
  }

  .title {
    grid-area: title;
  }

  .topLeft {
    grid-area: topLeft;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .topRight {
    grid-area: topRight;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .miniCards {
    display: flex;
    div {
      height: 30px;
      width: 20px;
      background-color: green;
      overflow: hidden;
      margin: 1px 1px 1px 0;
    }
  }

  .trump {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .startTrick {
    margin-right: 2em;
  }
  .myButtons {
    position: relative;
    min-height: 30px;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 2fr auto 2fr;
    align-items: center;

    // .bidding {
    //   justify-self: start;
    // }

    .myInfo {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .myCenterButtons {
      display: flex;
      justify-content: space-evenly;
      white-space: nowrap;
      align-items: center;
      .BidDisplay {
        display: inline-block;
        margin: 0 40px 0 0;
      }
      .el-input-number {
        margin-right: 0.5em;
      }
    }
  }
  .CardHolder {
    &.notPlayable {
      // opacity: 0.3;
      img {
        filter: brightness(75%);
        animation: all 0.1s;
      }
    }
    &.playable {
      img {
        filter: brightness(100%); // needed!
      }
      cursor: pointer;
    }
    &.tookTrick {
      img {
        animation: pulseWinner 0.3s 9; // use odd times
        animation-direction: alternate;
      }
    }
  }

  // &.trump_d {
  //   .suit_d {
  //     .Card {
  //       background-color: #fff2f2;
  //     }
  //   }
  // }

  // &.trump_c {
  //   .suit_c {
  //     .Card {
  //       background-color: #f3f3ee;
  //     }
  //   }
  // }

  // &.trump_h {
  //   .suit_h {
  //     .Card {
  //       background-color: #fff2f2;
  //     }
  //   }
  // }

  // &.trump_s {
  //   .suit_s {
  //     .Card {
  //       background-color: #f3f3ee;
  //     }
  //   }
  // }

  .red {
    color: #de2626;
    // background: #fff2f2;
  }
  .black {
    color: black;
  }
  .dealer {
    font-weight: bold;
    display: block;
    margin: 3px 0;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &.alertMyTurn {
    .table {
      animation: alertTable 0.2s 7; // use odd times
      animation-direction: alternate;
    }
  }

  .YourTurn {
    font-size: 1.1em;
  }

  .el-checkbox.is-bordered.el-checkbox--small {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    height: 25px;
    padding: 1px 10px 1px 0;
    margin: 0 5px 0 10px;
    border: none;
    background-color: #4a993e;
    color: white;
    .el-checkbox__input {
      margin: 2px 0 0 8px;
    }
    .el-checkbox__label {
      color: white;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #006400;
      border-color: #006400;
    }
  }

  .chimeSet {
    display: flex;
    position: relative;
    align-items: center;
    background: #4a983e;
    color: white;
    border-radius: 5px;
    margin: 0 0 0 1em;
    padding: 0 5px;
    .el-checkbox.is-bordered.el-checkbox--small {
      margin: 0;
    }
    select {
      background: #4a983e;
      color: white;
      margin: 0 2px 0 0;
    }
    .chimeVolumeHolder {
      margin: 0 1px 0 6px;
      position: relative;
      &:hover,
      &:active {
        .chimeVolume {
          display: block;
        }
      }
    }
    .chimeVolume {
      position: absolute;
      display: none;
      right: -10px;
      bottom: -5px;
      background: white;
      padding: 15px 0;
      box-shadow: 0 0 3px 1px black;
    }
  }

  .totalBids {
    span {
      white-space: nowrap;
    }
  }

  // &.bid {
  //   .CardHolder.Standard2.focused {
  //     flex-shrink: 1; // selected, but not playable
  //   }
  // }

  .meldDisplay {
    position: absolute;
    z-index: 1;
    background: white;
    border: 1px solid darkgreen;
    box-shadow: 0 0 3px 0 darkgreen;
    padding: 3px 10px 3px 4px;
    overflow: visible;
    > div {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      div {
        white-space: nowrap;
        padding: 2px 7px;

        &:last-child {
          padding-left: 15px;
        }
      }
    }
  }
}

@keyframes pulseWinner {
  0% {
    box-shadow: 0px 0px 0 0 #e6a23c;
  }

  100% {
    box-shadow: 0px 0px 6px 3px #e6a23c;
  }
}
@keyframes alertTable {
  0% {
    background-color: #e8f9e6;
  }

  100% {
    background-color: #006400;
  }
}
:root {
  --cardMargin: 0px;
}
</style>
