<template>
  <el-dialog
    class="GameRules"
    title="Rules of the Game"
    :visible="visible"
    fullscreen
    :before-close="handleClose"
    v-on:opened="opened"
  >
    <div
      id="body"
      tabindex="1"
    >
      <div v-if="shared.game.code==='estimation'">
        <h1>Estimation</h1>
        <h2>Overview</h2>
        <p>In each round, estimate how many of the 13 tricks you will be able to win, then try to win exactly that number.</p>
        <h2>Cards</h2>
        <p>A deck of 52 standard playing cards is dealt out so that each player starts with 13 cards. The trump suit changes each round. Ace is the highest card in its suit.</p>
        <h2>Bidding</h2>
        <p>Before starting a round, each player bids in turn. The dealer bids last and is not allowed to bid such that the combined bids total the number of tricks (13).</p>
        <h2>Play</h2>
        <p>The player to the right of the dealer starts the first trick by playing any card. </p>
        <p>Each player plays one card, following the suit first played. If they do not have a card in that suit, they may play any card, including any trump card.</p>
        <p>The trick is won by the person playing the highest trump card or the highest card in the suit of the first card played.</p>
        <p>The winner of the trick starts the next trick, until all the cards have been played and the round is finished.</p>
        <h2>Scoring</h2>
        <p>Each person who matched their bid will get 13 + the number of tricks they won.</p>
        <p>Others will lose the difference between their bid and the number of tricks they won.</p>
        <h2>Players</h2>
        <p>For 4 players.</p>
        <p>
        </p>
        <p>If 5 or 6 people are connected to the game, the extra people will be able to view the game. After each round, the dealer will rotate out. The next player will become the dealer
          and one of the viewers will take the 4<sup>th</sup> position.</p>
        <p>Named guests can also join in to watch.</p>
        <h2>Similar To</h2>
        <ul>
          <li>See <a href="https://en.wikipedia.org/wiki/Oh_Hell">this Wikipedia article</a> for 100 other names and variations of this game!</li>
          <li>Wizard</li>
          <li>9-Up, 9-Down</li>
        </ul>
      </div>

      <div v-if="shared.game.code==='wiz'">
        <h1>Wiz</h1>
        <h2>Overview</h2>
        <p>In each round, estimate how many of the tricks you will be able to win,
          then try to win exactly that number. The rounds start with 1 card dealt per person.
          The number of cards increases by 1 in each round.</p>
        <h2>Cards</h2>
        <p>The deck has 52 standard playing cards with the addition of 4 Wizard and 4 Jester cards.
          Ace is the highest card in its suit.
          The Wizard is the highest of all cards. The Jester is lowest of all cards.
        </p>
        <p>The trump suit randomly changes each round.
          If a Wizard is selected for trump, the dealer chooses which suit to use.
          If a Jester is selected, and on the last round, there is no trump.
        </p>
        <h2>Bidding</h2>
        <p>Bidding options (configured on the Setup page):</p>
        <ol>
          <li>Everyone bids at the same time.</li>
          <li>Before starting a round, each player bids in turn with the dealer bidding last.</li>
          <li>Before starting a round, each player bids in turn with the dealer bidding last
            and the total of all bids cannot equal the number of cards dealt to each person.</li>
        </ol>
        <h2>Play</h2>
        <p>The player next to the dealer starts the first trick by playing any card.
          If a Jester is played first, the next regular card sets the suit for the trick.
          If a Wizard is played first, there is no suit to follow.</p>
        <p>The next players play either a Jester or Wizard or follow the suit first played.
          If they do not have a card in that suit, they may play any card, including any trump card.</p>
        <p>The trick is won by the person who played either (1) the first Wizard,
          or (2) the highest trump card,
          or (3) the highest card in the suit of the first card played.
          If everyone plays a Jester, the first player wins the trick.</p>
        <p>The winner of the trick starts the next trick, and so on, until all the cards have been played
          and the round is finished.</p>
        <p>Rounds continue until on the final round, all cards are dealt out.</p>
        <h2>Scoring</h2>
        <p>Each person who matched their bid will get 2 plus the number of tricks they won.</p>
        <p>Others will lose the difference between their bid and the number of tricks they won.</p>
        <h2>Players</h2>
        <p>For 3 to 6 players.</p>
        <p>Named guests can join in to watch.</p>
        <h2>More Info</h2>
        <ul>
          <li>Wikipedia article about
            <a
              target="wiki"
              href="https://en.wikipedia.org/wiki/Wizard_(card_game)"
            >Wizard</a>.</li>
          <li>Community Forum and Tournaments (<a
              target="wiki"
              href="http://www.wizardcards.com/"
            >link</a>)</li>
        </ul>
        <h2>Similar To</h2>
        <ul>
          <li>Estimation</li>
          <li>9-Up, 9-Down</li>
          <li>See
            <a
              target="wiki"
              href="https://en.wikipedia.org/wiki/Oh_Hell"
            >this Wikipedia article</a> for 100 other names and variations of this game!</li>
        </ul>
      </div>

      <div v-if="shared.game.code==='pinochle'">
        <h1>Pinochle</h1>
        <h2>Overview</h2>
        <p>In each round, bid for how many points you will be able to win, then with help from your partner try to win at least that much.</p>
        <h2>Cards</h2>
        <p>The deck has 48 playing cards with two each of Ace, 10, King, Queen, Jack, and 9 (ranked in that order).
        </p>
        <h2>Bidding</h2>
        <p>Bids start at 30 and increase until all but one player has passed.</p>
        <p>The winner of the bid selects the trump suit for the round and exchanges 4 cards of their choice with their partner.</p>
        <h2>Melding</h2>
        <p>All players lay out cards that count for meld points. (See scoring.) Once all meld has been counted, cards are returned to the hand and play begins.</p>
        <h2>Play</h2>
        <p>The bid winner starts the first trick by playing any card.</p>
        <p>The next players must follow the suit played (with a higher card, if they have one).
          If they do not have a card in that suit, they must play a trump card (with a higher trump card than any trump card already played, if they have one).
          If they have no trump, they may play any card.</p>
        <p>The trick is won by the person who played either (1) the highest trump card or (2) the highest card in the suit of the first card played.</p>
        <p>The winner of the trick starts the next trick, and so on, until all the cards have been played and the round is finished.</p>
        <p>Rounds continue until one team's score is greater than 150.</p>
        <h2>Scoring</h2>
        <p>Each Ace, 10 or King card taken in tricks counts for 1 point. The winner of the last trick receives one additional point. There are 25 points in each round.</p>
        <p>Meld is scored as follows:</p>
        <ul>
          <li><strong>Run</strong> in trump (Ace, 10, King, Queen, Jack) - 15 points (if double run, 150 points)</li>
          <li><strong>Aces around (1 from each suit)</strong> - 10 points (if double around, 100 points)</li>
          <li><strong>Kings around</strong> - 8 points (if double around, 80 points)</li>
          <li><strong>Queens around</strong> - 6 points (if double around, 60 points)</li>
          <li><strong>Jacks around</strong> - 4 points (if double around, 40 points)</li>
          <li><strong>Pinochle</strong> (Jack of Diamonds and Queen of Spades) - 4 points (if double Pinochle, 30 points)</li>
          <li><strong>Marriage</strong> (King and Queen in a suit) - 2 points (if in trump, 4 points)</li>
          <li><strong>9 in Trump</strong> - 1 point</li>
        </ul>
        <p>The score in a round for the highest bidder's team is their meld + points. If this is not more than their bid, they go down by the amount bid.
          The other team's score, if they took a trick, is their meld + points; otherwise they score 0.</p>
        <h2>Players</h2>
        <p>For 4 players.</p>
        <p>Named guests can join in to watch.</p>
        <h2>More Info</h2>
        <ul>
          <li>Wikipedia article about
            <a
              target="wiki"
              href="https://en.wikipedia.org/wiki/Pinochle"
            >Pinochle</a>.</li>
          <li></li>
        </ul>
      </div>
    </div>
    <div
      slot="footer"
      class="dialogFooter"
    >
      <el-button v-on:click="handleClose">Close</el-button>
    </div>

  </el-dialog>
</template>

<script>


export default {
  name: "GameRules",
  props: {
    visible: Boolean
  },
  data: function () {
    return {
    };
  },
  computed: {
    shared() {
      return this.$root.shared;
    },
  },
  mounted() {
    window.addEventListener('keyup', this.keyPressed);
  },
  beforeDestroy: function () {
    window.removeEventListener('keyup', this.keyPressed);
  },

  methods: {
    keyPressed(ev) {
      if (!this.visible) return;

      switch (ev.key) {
        case 'Enter':
          this.handleClose();
          break;
      }
    },
    handleClose() {
      this.$emit('closeMe');
    },
    opened() {
      this.$el.querySelector('#body').focus({ preventScroll: true });
    }
  }
};
</script>

<style lang="less">
.GameRules {
  .el-dialog__body {
    text-align: left;
  }
  .dialogFooter {
    text-align: right;
  }
}
</style>
