<template>
  <div class="SetupNames">
    <!-- <div class="panel">
      <p class="gameActive">Play is in progress.</p>
      <p>Modifying players and settings cannot be done while a game is active.</p>
    </div> -->
    <div class="panel">
      <h1>Name the Players</h1>
      <p>Use short names!<br>Move people into their sitting order.<br>The first person will be the dealer.</p>

      <slick-list
        v-if="editing"
        v-model="list"
        class="list"
        helper-class="moving"
        lock-axis="y"
        :use-drag-handle="true"
        v-on:input="listSorted"
      >
        <slick-item
          v-for="(item,i) in list"
          :key="item.id"
          class="itemHolder"
          :class="{active: item.active, inactive: !item.active}"
          :index="i"
        >
          <div>
            <span class="num">{{ i+1 }}</span>

            <span data-title="Name (required)">
              <input
                v-model="item.name"
                type="text"
                :class="{missing: !item.name}"
                v-on:change="updated"
              >
            </span>

            <button
              v-handle
              class="moveMe icon"
            >
              <i class="material-icons">arrow_upward</i>
              <i class="material-icons">arrow_downward</i>
              <span>Move</span>
            </button>

            <span
              v-if="shared.gameConfig ? shared.gameConfig.useTeams : false"
              data-title="Team (optional)"
            >
              <input
                v-model="item.team"
                class="teamName"
                type="text"
                v-on:change="updated"
              >
            </span>
          </div>
          <div class="part1">
            <label>
              <input
                v-model="item.isAdmin"
                :disabled="item.id === shared.me.id"
                type="checkbox"
                v-on:change="updated"
              >
              Admin
            </label>
          </div>
          <!-- <div class="part1">
            <button
              :disabled="item.id === shared.game.dealer"
              class=""
              v-on:click="makeDealer(item)"
            >
              Set as Dealer
            </button>
          </div> -->
          <div class="part2">
            <span
              v-if="duplicatedNames[item.name.toLocaleLowerCase()]"
              class="isDup"
            >
              <span>Duplicate!</span>
            </span>

            <button
              :disabled="!item.connected || item.id === shared.me.id"
              class="forgetBtn"
              v-on:click="forgetPerson(item)"
              v-text="item.id === shared.me.id ? 'You' : (item.connected ? 'Has Left' : 'Not Here')"
            >

            </button>

            <button
              class="icon remove caution"
              :disabled="item.id === shared.me.id"
              title="Delete from this game"
              v-on:click="remove(i)"
            >
              <i class="material-icons">delete</i>
            </button>
          </div>
          <span
            class="nameWhileMoving"
            v-text="item.name"
          ></span>
        </slick-item>
      </slick-list>

      <p
        v-if="warning"
        class="warning"
      >{{ warning }}</p>
      <button
        v-if="showAllowMore"
        :disabled="!allowMore"
        v-on:click="addPerson"
      >Add Another Player</button>
      <p>You must have at least one person marked as an admin.</p>

    </div>

    <div class="panel">
      <h1>Name the Guests</h1>
      <p>If you want to allow guests to watch the game but not play, name them here.</p>
      <div
        v-for="(item,i) in shared.guests"
        :key="item.id"
        class="guest"
        :index="i"
      >
        <div>
          <span data-title="Name (required)">
            <input
              v-model="item.name"
              type="text"
              :class="{missing: !item.name}"
              v-on:change="updatedGuest"
            >
          </span>
        </div>
        <div class="part2">
          <button
            :disabled="!item.connected || item.id === shared.me.id"
            class="forgetBtn"
            v-on:click="forgetGuest(item)"
            v-text="item.connected ? 'Has Left' : 'Not Here'"
          >
          </button>

          <button
            class="icon remove caution"
            title="Delete from this game"
            v-on:click="removeGuest(i)"
          >
            <i class="material-icons">delete</i>
          </button>
        </div>
      </div>
      <button v-on:click="addGuest">Add a Guest</button>
    </div>
  </div>
</template>

<script>

import firebaseDb from "../firebaseInit";
import { SlickList, SlickItem, HandleDirective } from "vue-slicksort";

export default {
  name: "SetupNames",
  components: {
    SlickList,
    SlickItem
  },
  directives: { handle: HandleDirective },
  data: function () {
    return {
      duplicatedNames: {},
      warning: "",
      list: [],
      editing: true
      // editsMade: false
    };
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    },
    // list: {
    //   get: function () {
    //     return this.shared.people;
    //   },
    //   set: function (a) {
    //     this.shared.people = a;
    //   }
    // },
    allowMore() {
      return this.list.length < (this.shared.gameConfig ? this.shared.gameConfig.maxPlayers : 4 || 4);
    },
    showAllowMore() {
      return this.shared.gameConfig && (this.shared.gameConfig.maxPlayers > this.shared.gameConfig.minPlayers);
    }
  },
  watch: {
  },
  mounted() {
    this.shared.$on("game-changed", this.gameLoaded);
    this.shared.$on("people-updated", this.peopleUpdated);
    this.testForDuplicates();
    this.list = this.shared.people;
  },
  beforeDestroy() {
    this.shared.$off("game-changed", this.gameLoaded);
    this.shared.$off("people-updated", this.peopleUpdated);
  },
  methods: {
    peopleUpdated() {
      this.list = this.shared.people;
      this.updated();
    },
    gameLoaded: function () {
      this.updated();
    },
    listSorted: function (list) {
      var tableSize = this.shared.gameConfig.tableSize || list.length;

      if (list && list.length) {
        // debugger;
        list.forEach((p, i) => {
          p.sortOrder = i;
          p.active = i < tableSize;
        });
        this.updated(true);

        this.makeDealer(list[0]);
      }
    },
    makeDealer(person) {
      if (person && person.id) {
        var path = `/games/${this.shared.gameKey}`;
        firebaseDb.ref(path)
          .update({
            dealer: person.id
          });

        // debugger;
        // var list = this.shared.people;
        // var i = list.findIndex(p => p.id === person.id);
        // if (i !== -1) {
        //   list.unshift(list.splice(i, 1)[0]);
        //   this.listSorted(list);
        // }
      }
    },
    forgetPerson(person) {
      if (person.id === this.shared.me.id) {
        // ignore request to forget myself
        return;
      }
      if (person && person.id) {
        var path = `/people/${this.shared.gameKey}/${person.id}`;
        firebaseDb.ref(path)
          .update({
            connected: false
          });
      }
    },
    remove: function (i) {
      var vue = this;
      var toRemove = this.shared.people[i];

      var adminFound =
        this.shared.people.filter(m => m.id !== toRemove.id && m.isAdmin)
          .length > 0;
      if (!adminFound) {
        this.warning = "Cannot remove an Admin!";
        return;
      }
      this.warning = "";

      var path3 = `/people/${this.shared.gameKey}/${toRemove.id}`;
      console.log("remove", path3);
      firebaseDb
        .ref(path3)
        .remove()
        .then(() => {
          this.testForDuplicates();
          this.listSorted(vue.shared.people);
        });

    },
    addPerson: function (i) {
      var newMember = this.shared.makePerson("", this.shared.people, this.shared.people.length);
      var tableSize = this.shared.gameConfig.tableSize || this.shared.gameConfig.maxPlayers;

      newMember.active = this.shared.people.length + 1 <= tableSize;
      this.shared.people.push(newMember);
    },
    updated: function (wasUpdated) {
      // this.shared.members.sort((a, b) =>
      //   (a.name || "Z") < (b.name || "Z") ? -1 : 1
      // );

      var dupFound = this.testForDuplicates();

      var adminFound = this.shared.people.filter(m => m.isAdmin).length > 0;
      if (!adminFound) {
        this.warning = "Please mark at least one Admin!";
        return;
      }

      if (this.shared.gameConfig) {
        var minPlayers = this.shared.gameConfig.minPlayers || 4;
        var maxPlayers = this.shared.gameConfig.maxPlayers || 4;
        var numPlayers = this.shared.people.length;
        if (numPlayers < minPlayers) {
          this.warning = `Need at least ${minPlayers} players.`
          // return;
        }
        // if (numPlayers > maxPlayers) {
        //   this.warning = `Too many. Maximum ${maxPlayers} players.`
        //   return;
        // }
      }

      // this.warning = "";

      if (!dupFound && wasUpdated) {
        var ref = this.shared.dbGameRef;
        if (ref) {
          this.shared.people.forEach(m =>
            firebaseDb.ref(`/people/${this.shared.gameKey}/${m.id}`).set(m)
          );
          // this.shared.members.forEach(item => {
          //   dbList.doc(item.id).set(item);
          // });
          // this.editsMade = false;
        }
      }
    },
    testForDuplicates: function () {
      var nameCount = {};
      this.shared.people.forEach(item => {
        var name = item.name.toLocaleLowerCase();
        if (!nameCount[name]) {
          nameCount[name] = 1;
        } else {
          nameCount[name]++;
        }
      });

      var vue = this;
      vue.duplicatedNames = {};
      Object.keys(nameCount).forEach(name => {
        name = name.toLocaleLowerCase();
        if (name && nameCount[name] > 1) {
          vue.duplicatedNames[name] = true;
        }
      });

      return Object.keys(vue.duplicatedNames).length !== 0;
    },
    updatedGuest() {
      this.shared.guests.forEach(g =>
        firebaseDb.ref(`/guests/${this.shared.gameKey}/${g.id}`).set(g)
      );
    },
    forgetGuest(person) {
      if (person && person.id) {
        var path = `/guests/${this.shared.gameKey}/${person.id}`;
        firebaseDb.ref(path)
          .update({
            connected: false
          });
      }

    },
    removeGuest(i) {
      var toRemove = this.shared.guests[i];
      if (!toRemove.id) {
        return;
      }

      var path = `/guests/${this.shared.gameKey}/${toRemove.id}`;

      firebaseDb
        .ref(path)
        .remove();
    },
    addGuest() {
      this.shared.guests.push({
        id: this.shared.getRandomId('g', this.shared.guests),
        name: '',
        connected: false
      })
      // var newGuest = {
      // };
      // var path = `/guests/${this.shared.gameKey}/${newGuest.id}`;
      // firebaseDb.ref(path).set(newGuest);
    },
  }
};
</script>

<style lang="less">
.SetupNames {
  .gameActive {
    color: red;
    font-weight: bold;
    font-size: 1.1em;
  }
  .list-move {
    transition: transform 1s;
  }
  p.warning {
    color: red;
  }

  .list {
    margin: 20px 0;

    .isDup {
      display: inline-block;
      width: 100px;
      color: red;
    }
    input[type="text"] {
      width: 5em;
      margin: 0 20px 0 10px;
      position: relative;

      &.missing {
        box-shadow: 0 0 3px 1px red;
      }

      &.teamName {
        width: 2em;
      }
    }
    .num {
      display: inline-block;
      width: 20px;
      text-align: right;
      margin: 0 5px 0 5px;
      font-size: 75%;
      color: grey;
    }
    label {
      display: inline-block;
      margin: 0 5px;
    }
  }

  [data-title] {
    position: relative;
    &::before {
      content: attr(data-title);
      // More Style Rules
      position: absolute;
      top: -14px;
      left: 10px;
      font-size: 60%;
      color: grey;
    }
  }

  .guest {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px 15px;
    padding: 5px 0;

    .part2 {
      display: flex;
    }
    input {
      margin: 0;

      &.missing {
        box-shadow: 0 0 3px 1px red;
      }
    }
  }

  .forgetBtn {
    width: 90px;
  }

  button.moveMe {
    cursor: ns-resize;
  }
  button.moveMe,
  button.remove {
    margin: 0;
    padding: 0;
    display: inline-block;
    box-shadow: none;
    i {
      vertical-align: middle;
    }
    &:hover {
      background-color: #fcfcfc;
    }
  }
}

// at a global level
.itemHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 15px;
  padding: 5px 0;
  box-sizing: border-box; // match what SlickList uses

  &.active + &.inactive {
    border-top: 3px double red;
    padding-top: 30px;
    position: relative;

    &::before {
      content: "Sitting out this round";
      position: absolute;
      top: -1px;
      left: 0;
      font-size: 75%;
      font-style: italic;
      color: red;
    }
  }

  > div {
    white-space: nowrap;
    margin: 2px;
    display: flex;
    align-items: center;
  }
  .nameWhileMoving {
    display: none;
  }
  &.moving {
    text-align: center;
    background-color: lightblue;
    margin: 5px auto;
    // input {
    //   background-color: lightblue;
    // }
    > * {
      display: none;

      &.nameWhileMoving {
        display: block;
      }
    }
  }
}
</style>
