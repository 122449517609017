<template>
  <el-dialog
    class="KeyTips"
    title="Keyboard Shortcuts"
    :visible="visible"
    center
    width="70%"
    top="1vh"
    :before-close="handleClose"
  >
    <table>
      <thead>
        <tr>
          <th colspan="2">General</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="left"><span class="key">s</span> :</td>
          <td class="right">Show/hide the score sheet.</td>
        </tr>
        <tr>
          <td class="left"><span class="key">r</span> :</td>
          <td class="right">Show/hide the rules for this game.</td>
        </tr>
        <tr>
          <td class="left"><span class="key">a</span> :</td>
          <td class="right">Turn the alert chime on or off.</td>
        </tr>
        <tr>
          <td class="left"><span class="key">f</span> :</td>
          <td class="right">Toggle fullscreen display.</td>
        </tr>
        <tr>
          <td class="left"><span class="key">&lt;Esc&gt;</span> :</td>
          <td class="right">Close any popup dialog (like this one).</td>
        </tr>
        <tr>
          <td class="left"><span class="key">?</span> or <span class="key">/</span> :</td>
          <td class="right">Show this "Keyboard Shortcuts" popup dialog.</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colspan="2">Game Play</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="left">
            <span class="key">&larr;</span>
            <span class="key">&rarr;</span>
            :</td>
          <td class="right">Choose a card to play.</td>
        </tr>
        <tr>
          <td class="left"><span class="key">&lt;Enter&gt;</span> :</td>
          <td class="right">
            Play the chosen card(s).
          </td>
        </tr>
        <tr>
          <td class="left"><span class="key">&lt;Space&gt;</span> :</td>
          <td class="right">
            If allowed to choose multiple cards, select the current card.
          </td>
        </tr>
        <tr>
          <td class="left"><span class="key">&lt;Esc&gt;</span> :</td>
          <td class="right">De-select all cards</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colspan="2">When Dealing</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="left"><span class="key">&lt;Enter&gt;</span> or <span class="key">&lt;Space&gt;</span> :</td>
          <td class="right">
            Click the button in the center of the playing area.
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colspan="2">When Bidding</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="left">
            <span class="key">&larr;</span>
            <span class="key">&rarr;</span>
            <span class="key">&uarr;</span>
            <span class="key">&darr;</span>
            <span class="key">+</span>
            <span class="key">-</span>
            :</td>
          <td class="right">Increase or decrease your bid.</td>
        </tr>
        <tr>
          <td class="left"><span class="key">&lt;Enter&gt;</span> :</td>
          <td class="right">
            Set your bid.
          </td>
        </tr>
      </tbody>
    </table>

  </el-dialog>
</template>

<script>


export default {
  name: "KeyTips",
  props: {
    visible: Boolean
  },
  data: function () {
    return {
    };
  },
  computed: {
    shared() {
      return this.$root.shared;
    },
  },
  methods: {
    handleClose() {
      this.$emit('closeMe');
    },
  }
};
</script>

<style lang="less">
.KeyTips {
  .el-dialog--center .el-dialog__body {
    padding: 0 25px 30px;
  }

  .key {
    padding: 0 5px 3px;
    background-color: #1f1f1f;
    color: #faf67a;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    min-width: 9px;
  }
  .key + .key {
    margin-left: 1px;
  }

  td,
  th {
    vertical-align: top;
  }
  td.left {
    text-align: right;
    white-space: nowrap;
  }
  thead {
    th {
      text-align: left;
      padding: 1em 0 0 0;
      border-bottom: 1px solid black;
    }
  }
}
</style>
