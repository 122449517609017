<template>
  <div
    class="CardHolder Standard1"
    :class="classList"
    v-on:click="clicked"
  >
    <div
      class="Card"
      :class="color"
      v-html="cardHtml"
    ></div>
    <!-- <div
      class="Back"
      v-html="backHtml"
    ></div> -->
  </div>
</template>

<script>

//    {{ cardUnicode }} {{ suit.display }} {{ cardName }}

export default {
  name: "Card",
  props: {
    card: {
      type: Object,// {key:'s13'}
      required: true,
    },
    size: String
  },
  data: function () {
    return {
      backHtml: '&#127136;'
    };
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    },
    deckType() {
      return this.shared.configs.deckType.standard;
    },
    suitKey() {
      // could be 'j' for joker
      return this.baseKey[0] || '';
    },
    cardName() {
      return this.deckType.cardName(this.baseKey);
    },
    suit() {
      if (this.baseKey === 'back') { return ''; }
      if (this.suitKey === 'j') {
        return this.deckType.jokers[this.baseKey];
      }
      return this.deckType.suits[this.suitKey];
    },
    baseKey() {
      if (!this.card.key) {
        return '';
      }
      // if (this.card.faceDown) {
      //   return 'back';
      // }
      return this.card.key.split('_')[0];
    },
    number() {
      return +this.baseKey.substr(1);
    },
    cardUnicode() {
      return this.deckType.getUnicodeFor(this.baseKey);
    },
    cardHtml() {
      return `&#${this.cardUnicode};`;
    },
    color() {
      return this.suit ? this.suit.class : null;
    },
    classList() {
      var list = [];

      if (this.card.focused || this.card.newCard) {
        list.push('focused')
      }

      if (this.card.tookTrick) {
        list.push('tookTrick')
      }

      if (this.size !== 'small') {
        if (!this.card.playable) {
          list.push('notPlayable');
        } else {
          list.push('playable');
        }
      }

      list.push('suit_' + this.card.suit);

      return list.join(' ');
    }
  },
  mounted: function () { },
  methods: {
    clicked() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="less">
.CardHolder.Standard1 {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 98px;
  height: 147px;
  box-sizing: content-box;
  border-radius: 3px;
  user-select: none;

  transition: 0.1s;
  transform-style: preserve-3d;

  &.focused {
    margin: -10px 3px 0;
    box-shadow: 0px 0px 3px 0px black;
    flex-shrink: 0;
  }

  // &:hover {
  //   transform: rotateY(180deg;);
  // }
  .Card,
  .Back {
    display: block;
    position: absolute;
    font-size: 160px;
    height: 190px;
    top: -43px;
    width: 106px;
    left: -4px;
    background-color: white;

    backface-visibility: hidden;

    &.red {
      color: #de2626;
      // background: #fff2f2;
    }
    &.black {
      color: black;
    }
  }

  .Card {
    z-index: 2;
    transform: rotateY(0deg);
  }
  .Back {
    transform: rotateY(180deg);
  }
}
</style>
