/* eslint-disable space-in-parens */
/* eslint-disable func-call-spacing */
import Vue from 'vue'
import Router from 'vue-router'
import Public from './views/Public.vue'
import CreateGame from './components/CreateGame.vue'
import Faq from './components/FAQ.vue'
import Shell from './views/GameShell.vue'
import Join from './components/Join.vue'
import Home from './components/Home.vue'
import Claim from './components/ClaimName.vue'
import AdminPanel from './components/AdminPanel.vue'
import ShareTalk from './components/ShareTalk.vue'

Vue.use(Router)

var router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: '/',
            name: 'public',
            component: Public
        }, {
            path: '/faq',
            name: 'faq',
            meta: {
                tags: {
                    description: 'Questions and Answers about Our Card Game.'
                }
            },
            component: Faq
        },
        {
            path: '/g',
            name: 'gameShell',
            component: Shell,
            children: [{
                path: '/j',
                name: 'join',
                component: Join
            }, {
                path: '/g/home',
                name: 'home',
                component: Home
            }, {
                path: '/g/claim',
                name: 'claim',
                component: Claim
            }, {
                path: '/g/create',
                name: 'createGame',
                component: CreateGame
            }, {
                path: '/g/admin',
                name: 'adminPanel',
                component: AdminPanel
            }, {
                path: '/g/share',
                name: 'share',
                component: ShareTalk
            }]
        },
    ]
});

router.afterEach((to, from) => {
    // console.log('route to', to)
    gtag('event', 'screen_view', {
        screen_name: to.name,
    });
    if (Tawk_API && Tawk_API.showWidget) {
        if (to.name === 'home') {
            Tawk_API.hideWidget();
        } else {
            Tawk_API.showWidget();
        }
    }
    setTimeout(function() {
        var eb = window.document.getElementById('gameBody');
        if (eb && eb.scrollTo) {
            // console.log('scrollTo 0');
            eb.scrollTo(0, 0);
        }
    }, 0);
})

router.beforeEach((to, from, next) => {
    var metaTags = to.meta.tags;
    if (metaTags) {
        var allMeta = Array.from(document.getElementsByTagName('meta'));
        Object.keys(metaTags).forEach(key => {
            var tag = document.createElement('meta');
            tag.setAttribute('name', key);
            tag.setAttribute('content', metaTags[key]);
            var existing = allMeta.find(m => m.name === key);
            document.head.insertBefore(tag, existing);
        })
    }
    next();
})

export default router;