<template>
  <div class="gameShell">
    <Nav />
    <div
      id="gameBody"
      class="gameBody"
    >
      <router-view />
    </div>
    <MemberStatus v-if="$route.name !== 'createGame'" />
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import MemberStatus from "@/components/MemberStatus.vue";


export default {
  name: "GameShell",
  components: {
    MemberStatus,
    Nav
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    }
  },
  watch: {
    $route: function (a, b) {
      // console.log("from", b && b.name, "to", a.name);
      // console.log(this.shared.me);
      if (!this.shared.me.id
        && a.name !== "createGame"
        && a.name !== "adminPanel"
        && this.shared.gameKey) {
        // console.log("go claim 1");
        this.$router.replace("/g/claim").catch(err => { });
      } else if (a.name === "gameShell") {
        this.goCurrentHome();
      }
    }
  },
  mounted: function () {
    // console.log("mounted");
    if (this.$route.name === "join") {
      // console.log("mounted join");
      this.$router.replace("/g/claim").catch(err => { });
      return;
    }
    this.shared.$on("game-changed", this.gameChanged);
    this.shared.$on("goto", this.goto);

    var scrollingDiv = document.getElementById("gameBody");
    scrollingDiv.addEventListener("touchmove", function (event) {
      event.stopPropagation();
    });

    this.goCurrentHome();
  },
  beforeDestroy: function () {
    this.shared.$off("game-changed", this.gameChanged);
    this.shared.$off("goto", this.goto);
  },
  methods: {
    goto(path) {
      this.$router.replace(path).catch(err => {
        console.log('goto', err)
      });
    },
    gameChanged: function () {
      // console.log("loaded");
      var currentRoute = this.$route.name;
      // console.log("already on", currentRoute);
      if (currentRoute !== "adminPanel") {
        // this.goCurrentHome();
      }
    },
    goCurrentHome: function () {
      // console.log(
      //   "go home",
      //   this.$route.name,
      //   this.shared.gameLoadAttempted,
      //   this.shared.gameKey
      // );
      if (!this.shared.firebaseRawAuthUser) {
        return;  // don't go anywhere 
      }

      if (!this.shared.gameKey) {
        // console.log("go create");
        this.$router.replace("/g/create").catch(err => { });
        return;
      }

      if (this.shared.gameLoadAttempted) {
        if (!this.shared.me.id) {
          // console.log("go claim");
          this.$router.replace("/g/claim").catch(err => { });
          return;
        }
      }

      switch (this.$route.name) {
        case 'stage1':
          if (this.shared.numBlankNames && this.shared.me.isAdmin) {
            // console.log("setup");
            this.$router.replace("/g/admin").catch(err => { });
            return;
          }
          break;

        case 'adminPanel':
          // stay here
          break;

        default:
          this.$router.replace("/g/home").catch(err => { });
          break;
      }



    }
  }
};
</script>

<style lang="less">
.gameShell {
  height: 100%;
  text-align: center;
  display: grid;
  grid-template-rows: 40px 99fr 1fr;
  grid-template-areas: "a" "b" "c";
  //flex-direction: column;

  #nav {
    grid-area: a;
  }
  .MemberStatus {
    // flex-grow: 1;
    grid-area: c;
  }
  #gameBody {
    // flex-grow: 100;
    grid-area: b;
  }

  .gameBody {
    flex-grow: 1;
    // overflow-y: scroll; // avoid jitter on small screens
    overflow-y: auto;
  }
}
</style>