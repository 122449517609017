<template>
  <div
    class="seatArea"
    :class="{next: isNext, viewAllCards: viewAllCards}"
  >

    <div class="nameAndDealer">
      <div class="name">
        {{ player.name }} {{ player.team }}
      </div>
      <div
        v-if="stage.playerIsDealer(player)"
        class="dealer"
      >Dealer</div>
      <div
        v-if="stage.playerIsBidWinner(player)"
        class="dealer"
      >Won Bid - {{ player.bid }}</div>
    </div>

    <bid-display
      v-if="showBidDisplay"
      :bid="player.bid || '-'"
      :tricks="stage.numTricks(player)"
      :show-for-team="showForTeam"
      :meld="teamMeld || player.meld"
      :points="teamPoints || player.points"
    ></bid-display>

    <!-- 
    <div>
      <button
        v-if="isDealer && stage.cardsAvailable && stage.useDeal1Card"
        v-on:click="stage.dealNextCardTo(player)"
      >Deal 1 card</button>
    </div> -->

    <div
      v-if="!viewAllCards"
      class="miniCards"
    >
      <div
        v-for="b in stage.getOtherCardCount(player)"
        :key="b"
      ></div>
      <!-- <img
        v-for="b in stage.getOtherCardCount(player)"
        :key="b"
        src="./../../assets/standard2/back-green-s.png"
      > -->
    </div>

    <div
      v-if="viewAllCards"
      class="allSmallCards"
    >
      <standard2
        v-for="c in cardsInHand"
        :key="c.key"
        :card="c"
        size="small"
      ></standard2>
    </div>
    <div
      v-if="viewAllCards"
      class="smallCardsGroups"
    >
      <div class="smallCardsGroup1">
        <standard2
          v-for="c in cardsInHand1"
          :key="c.key"
          :card="c"
          size="small"
        ></standard2>
      </div>
      <div class="smallCardsGroup2">
        <standard2
          v-for="c in cardsInHand2"
          :key="c.key"
          :card="c"
          size="small"
        ></standard2>
      </div>
    </div>
  </div>
</template>

<script>

import standard2 from "../cards/standard2";
import bidDisplay from "../controls/BidDisplay";

export default {
  name: "SeatArea",
  components: {
    bidDisplay,
    standard2
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
    stage: {
      type: Object,
      required: true,
    },
    hideTeamScoring: Boolean,
    showForTeam: Boolean,
    teamMeld: Number,
    teamPoints: Number,
  },
  data: function () {
    return {
    };
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    },
    me() {
      return this.shared.me;
    },
    viewAllCards() {
      return this.shared.viewAllCards;
    },
    cardsAvailable() {
      return this.shared.cardsStillInDeck ? this.shared.cardsStillInDeck.length : 0;
    },
    middleCardNum() {
      return Math.ceil(this.numCardsInHand / 2);
    },
    cardsInHand1() {
      return this.cardsInHand.slice(0, this.middleCardNum);
    },
    cardsInHand2() {
      return this.cardsInHand.slice(this.middleCardNum);
    },
    numCardsInHand() {
      return this.cardsInHand.length;
    },
    cardsInHand() {
      var cards = this.stage.getOtherCardsInHand(this.player);
      if (cards.length) {
        var sorter = this.shared.deckType.cardSorter(this.shared);
        cards.sort(sorter);
      }
      return cards;
    },
    isAdmin() {
      return this.shared.me.isAdmin;
    },
    isDealer() {
      return this.me.id === this.shared.game.dealer;
    },
    playIsActive() {
      // return !this.stage.personWhoTookTrick.id;
      return this.shared.game.state === 'play';
    },
    isNext() {
      return (this.playIsActive || this.stage.bidding)
        && this.player.id === this.shared.game.nextPlayer;
    },
    showBidDisplay() {
      if (this.hideTeamScoring) return false;

      return this.stage.playing || this.stage.showBidDisplay || (!this.stage.simultaneousBidding && this.stage.bidsSet) || this.player.bidSet;
    }
  }
};
</script>
<style lang="less">
</style>