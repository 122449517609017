import Stage2 from '../stages/Stage2.vue'
export default {
    code: 'wiz',
    name: 'Wiz',
    publish: true,
    summary: 'Estimate how many tricks you will win in each round!',
    stage: Stage2,
    deckType: 'standard',
    aceIsHigh: true,
    useTrump: true,
    minPlayers: 3,
    maxPlayers: 6,
    tableSize: 0, // match # of players
    useTeams: false,
    showBidCircles: true,
    dealAfterShuffle: true,
    useBidding: true,
    bidBeforeRound: true,
    questions: [{
            code: 'numCards',
            display: 'Number of cards in next round',
            type: 'NumberSlider',
            min: 1,
            max: 20
        },
        {
            code: 'counterClockwise',
            display: `Play proceeds:`,
            true: 'Counter-clockwise',
            false: 'Clockwise',
            type: 'BooleanSwitch'
        }, {
            code: 'viewersSeeAll',
            display: `Show cards to non-players`,
            type: 'Boolean'
        }, {
            code: 'simultaneousBidding',
            display: `All players bid at the same time`,
            type: 'Boolean'
        }, {
            code: 'dealerBidOff',
            display: `If bidding in order, total bids cannot match number of cards`,
            type: 'Boolean'
                // }, {
                //     code: 'incrementCards',
                //     display: `Increase the number of cards after each round`,
                //     type: Boolean
                // }, {
                //     code: 'addWizJest',
                //     display: `Include "Wizard" and "Jester" cards`,
                //     type: Boolean
        }
    ],
    adjustAnswers(newAnswers, oldAnswers) {
        var adjustmentsMade = false;
        if (newAnswers.incrementCards && !oldAnswers.incrementCards && newAnswers.numCards === 13) {
            newAnswers.numCards = 1;
            adjustmentsMade = true;
        }
        if (!newAnswers.incrementCards && oldAnswers.incrementCards && newAnswers.numCards === 1) {
            newAnswers.numCards = 13;
            adjustmentsMade = true;
        }
        return adjustmentsMade;
    },
    calcScore(bid, tricksTaken, numCardsInRound) {
        var scoreForRound;
        if (bid === tricksTaken) {
            scoreForRound = 2 + tricksTaken;
        } else {
            scoreForRound = 0 - Math.abs(bid - tricksTaken);
        }
        return scoreForRound;
    },
    maxRounds(numPlayers) {
        return 60 / numPlayers;
    },
    defaultAnswers() {
        return {
            numCards: 1,
            incrementCards: true,
            incrementCardsMax: 20,
            viewersSeeAll: true,
            dealerBidOff: false,
            simultaneousBidding: false,
            counterClockwise: true,
            addWizJest: true
        }
    }
}