<template>
  <div
    class="CardHolder Draw1"
    :class="classList"
    v-on:click="clicked"
  >
    <div
      class="Card"
      :class="[color, isNumberCard ? '' : 'letter']"
    >
      <div class="tl">
        <div v-text="card.short"></div>
      </div>
      <div class="br">
        <div v-text="card.short"></div>
      </div>
      <div class="center">
        <div v-text="card.name"></div>
      </div>
    </div>
    <!-- <div
      class="Back"
      v-html="backHtml"
    ></div> -->
  </div>
</template>

<script>

//    {{ cardUnicode }} {{ color.display }} {{ cardName }}

export default {
  name: "Card",
  props: {
    card: {
      type: Object,// {key:'s13'}
      required: true,
    }
  },
  data: function () {
    return {
      backHtml: ''
    };
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    },
    deckType() {
      return this.shared.configs.deckType.draw1 || {};
    },
    colorKey() {
      // could be 'j' for joker
      return this.baseKey[0] || '';
    },
    cardName() {
      return this.card.name;
    },
    isNumberCard() {
      return this.card.num === 0 || this.card.num;
    },
    color() {
      return this.deckType.colors[this.colorKey].class;
    },
    baseKey() {
      if (!this.card.key) {
        return '';
      }
      // if (this.card.faceDown) {
      //   return 'back';
      // }
      return this.card.key.split('_')[0];
    },
    classList() {
      var list = [];

      if (this.card.focused || this.card.newCard) {
        list.push('focused')
      }

      if (this.card.notPlayable) {
        list.push('notPlayable');
      }

      return list.join(' ');
    }
  },
  mounted: function () { },
  methods: {
    clicked() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="less">
.CardHolder.Draw1 {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 98px;
  height: 147px;
  box-sizing: content-box;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;

  transition: 0.1s;

  .Card,
  .Back {
    width: 96px;
    height: 145px;
    border: 1px solid black;
    border-radius: 5px;
    position: relative;

    &.red {
      background-color: #de2626;
    }
    &.black {
      background-color: black;
      color: white;
    }
    &.green {
      background-color: lightgreen;
    }
    &.yellow {
      background-color: lightyellow;
    }
    &.blue {
      background-color: lightblue;
    }
  }
  .tl {
    top: -3px;
    left: 3px;
  }
  .br {
    bottom: 0;
    right: 4px;
  }
  .tl,
  .br,
  .center {
    position: absolute;
    font-size: 1.7em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  .center {
    left: 0;
    right: 0;
    top: 40%;
    font-size: 2em;
  }
  .letter {
    .center {
      font-size: 1.5em;
      div {
        transform: rotateZ(-45deg);
      }
    }
  }
}
</style>
