<template>
  <div class="ClaimName">
    <div class="panel">
      <p>Welcome to our game! Please claim the spot beside your name.</p>
      <div
        v-if="!shared.people.length"
        class="loading"
      >Loading names...</div>
      <table>
        <tbody>
          <tr
            v-for="m in shared.people"
            :key="m.id"
            class="memberHolder"
            :class="{claimed: m.connected, nextPlayer: isNextPlayer(m)}"
          >
            <th>{{ m.name }}</th>
            <td>
              <button
                v-if="m.participating && !m.connected"
                :disabled="claimMade"
                v-on:click="claim(m)"
              >Claim my spot!</button>
              <span v-if="m.connected">
                <button :disabled="true">Claimed</button>
              </span>
              <span v-if="!m.participating">Not Connected</span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr
            v-for="m in shared.guests"
            :key="m.id"
            class="memberHolder"
            :class="{claimed: m.connected}"
          >
            <th>{{ m.name }}</th>
            <td>
              <button
                v-if="!m.connected"
                :disabled="claimMade"
                v-on:click="claim(m)"
              >Claim my spot to watch</button>
              <span v-if="m.connected">Claimed</span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <!-- <div class="panel">
      <p>
        Or, if this browser will be used to display results, click
        <button v-on:click="claimViewer">Viewer</button>
      </p>
      <p>To use this computer as a player <strong>and</strong> as a viewer, use an "In Private"/"Incognito" window for one of the sessions!</p>
    </div> -->
    <div
      v-if="!shared.me.id"
      class="panel"
    >
      <p>
        To leave this game entirely, click
        <button
          class="warning"
          v-on:click="logout"
        >
          Leave this Game
        </button>
      </p>
      <p>An administrator can delete this game on the Setup page.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClaimName",
  data: function () {
    return {
      claimMade: false
    };
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    }
  },
  updated: function () {
    if (this.shared.me.id) {
      // can't look here if already claimed?
      this.$router.replace("/g");
    }
  },
  methods: {
    isNextPlayer(m) {
      var trickWinnerId = this.shared.personWhoTookTrick.id;
      if (trickWinnerId) {
        return m.id === trickWinnerId;
      }
      return m.id === this.shared.nextPlayer.id;
    },
    claim: function (person) {
      if (person.connected) {
        // already claimed!
        return;
      }
      if (!person.id) {
        // something is wrong...
        return;
      }

      // debugger

      switch (person.id[0]) {
        case 'm':
          this.shared.claimMember(person.id);
          break;
        case 'g':
          this.shared.claimGuest(person.id);
          break;
        default:
          return;
      }
      this.$router.replace("/g");
    },
    logout: function () {
      this.shared.logout();
      this.$router.replace("/")
    },
    // claimViewer: function () {
    //   this.shared.startMeAsViewer();

    //   this.$router.replace("/g");
    // }
  }
};
</script>

<style lang="less">
.ClaimName {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 10px;

  table {
    margin: 1em auto;
    border-collapse: collapse;
    th {
      font-weight: normal;
      padding-right: 40px;
    }
  }

  td,
  th {
    padding: 0 1em;
  }

  tfoot {
    background-color: #e9e9e9;
    tr:first-child {
      border-top: 1px solid grey;
    }
  }

  tr.memberHolder {
    height: 3em;
    cursor: pointer;

    &.nextPlayer {
      th {
        font-weight: bold;
      }
    }
  }
  .loading {
    margin: 20px;
  }
}
</style>
