<template>
  <div class="public panel">
    <h1>Our Card Game</h1>
    <p>A virtual deck of cards and card table for you and your friends to play some games.</p>
    <img
      class="main"
      src="../../public/img/logo700x700.png"
    >
    <p>If a friend has given you a link for a game, paste it into the browser's address bar to get started.</p>

    <p>Games that are currently available that you can play with your friends: Estimation, Wiz (like Wizard), Pinochle.</p>

    <p>To start a new game, or return to your current game, click here:</p>
    <p>
      <button v-on:click="$router.push('/g')">
        Start
      </button>
    </p>

    <p>
      Here are some <a href="faq">Questions and Answers</a> about how this website works.
    </p>

    <!-- <h1>Spread the News!</h1>
    <p>Help spread the news about Our Card Game with your friends...</p>
    <div class="sharethis-inline-share-buttons"></div> -->
    <p>Version {{ version }}</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Public",
  computed: {
    version: function () {
      return _version;
    }
  }
};
</script>

<style lang="less">
.public {
  padding: 1em;
  font-family: "Open Sans", sans-serif;
  h1,
  h2 {
    font-family: "Acme", sans-serif;
    color: #1e331d;
  }
  img {
    &.main {
      display: block;
      margin: 0 auto;
      height: 40vh;
      min-height: 300px;
      max-height: 700px;
    }
  }
  button {
    font-size: 1.25em;
  }
}
</style>