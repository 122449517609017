<template>
  <div class="CreateGame">
    <div v-if="!shared.game.id">
      <!-- shared.gameLoadAttempted &&  -->
      <div class="panel">
        <h1>Our Card Game</h1>
        <p>Welcome to your new game!</p>
        <p>
          <strong>Please note</strong>: if you are trying to join an existing game, you must use the link given to you by the person who created it.
        </p>
      </div>
      <div class="panel">
        <h1>Create a Game</h1>
        <p>To get started on a new game, all we need is your first name.</p>
        <p>
          Please enter the name that you want other players to see when talking with you,
          usually just your first name or nickname. (You can change it later, if needed.)
        </p>
        <!-- <p>
          Or, you can use your Google account.
        </p> -->
        <div class="nameInput">
          <span>Your first name:</span>
          <input
            v-model="name"
            v-on:keyup.enter="create"
          >
          <!-- <span> or </span>
          <span v-on:click="loginGoogle">
            <img
              class="googleBtn"
              src="../assets/googleBtn.png"
            >
          </span> -->
        </div>
        <div>
          <button
            :disabled="!name"
            v-on:click="create"
            v-text="'Create the Game ' + (name ? 'Now' : '(enter your name)')"
          >

          </button>
        </div>
        <p>You will be the "administrator" of this game and able to manage it.</p>
        <p>
          When the game is created, you will be given a special private website address that you will need to
          share with the rest of the players.
        </p>
        <p>
          Read more in the
          <a href="/faq">Questions and Answers</a> about how this website works.
        </p>
        <p class="leave">
          <button
            class="notUsual"
            v-on:click="leave"
          >
            Cancel
          </button>
        </p>
      </div>
      <div
        v-if="previousGames.length"
        class="panel"
      >
        <h1>Previous Games on This Computer</h1>
        <div class="gamesList">
          <table class="previousGames">
            <tr
              v-for="g in previousGames"
              :key="g.key"
              class="previousGame"
            >
              <td>
                <button v-on:click="shared.loadGame(g.key)">Open</button>
              </td>
              <td :title="g.abbrev">
                <div class="name">{{ g.name }}</div>
                <div class="age">Viewed {{ g.age }}</div>
                <div
                  v-if="g.createdAge"
                  class="age"
                >Created {{ g.createdAge }}</div>
              </td>
              <td class="who">{{ g.who }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- <div
        v-if="!previousGames.length"
        class="loading"
      >
        Loading...
      </div> -->
    </div>
  </div>
</template>

<script>

import * as moment from "moment/moment";

export default {
  name: "CreateGame",
  components: {},
  data: function () {
    return {
      name: ""
    };
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    },
    previousGames() {
      var games = this.shared.myOldGames;
      if (games) {
        var list = Object.keys(games).map(k => {
          var info = games[k];
          if (typeof (info) === 'string') {
            // old/buggy data
            info = {
              when: info
            }
          }
          info.key = k;
          if (info.created) {
            info.createdAge = moment(info.created).fromNow();
          }
          info.age = moment(info.when).fromNow();
          info.abbrev = this.shared.gameKeyAbbrev(k);
          return info;
        })
        list.sort((a, b) => {
          return a.when < b.when ? 1 : -1; // oldest first
        });
        return list;
      }
      return [];
    }
  },
  beforeUpdate: function () {
    if (this.shared.game.created) {
      this.$router.replace("/g");
    }
  },
  mounted: function () {
    if (this.shared.game.created) {
      this.$router.replace("/g");
      return;
    }

    this.shared.$on("game-created", this.gameCreated);
    this.shared.$on("game-changed", this.goHome);
    // this.shared.$on("loggedIn", this.fillNameFromAuth);
  },
  beforeDestroy: function () {
    this.shared.$off("game-created", this.gameCreated);
    this.shared.$off("game-changed", this.goHome);
    // this.shared.$off("loggedIn", this.fillNameFromAuth);
  },
  methods: {
    goHome: function () {
      if (this.$route.path !== '/g') {
        this.$router.replace("/g");
      }
    },
    // fillNameFromAuth() {
    //   var user = this.shared.firebaseRawAuthUser;
    //   if (user) {
    //     this.name = user.displayName;
    //   }
    // },
    gameCreated: function () {
      var vue = this;
      if (vue.shared.members.filter(m => m.name).length < 2) {
        vue.$router.replace("/g/admin");
        return;
      }
      vue.$router.replace("/g");
    },
    create: function () {
      if (this.name) {
        this.shared.createGame(this.name);
      }
    },
    leave: function () {
      this.$router.replace("/");
    },
    // loginGoogle: function () {
    //   this.shared.loginGoogle();
    // }
  }
};
</script>

<style lang="less">
.CreateGame {
  text-align: left;
  // padding: 0 0 0 15px;
  // max-width: 700px;
  padding: 0 10px;
  // margin: 0 auto;

  .nameInput {
    margin: 0 0 1em;
    input {
      width: 60px;
      margin: 0 2em 0 1em;
    }
    display: flex;
    align-items: center;
    .googleBtn {
      height: 46px;
      margin-left: 1em;
    }
  }
  .loading {
    margin: 50px auto;
    text-align: center;
    font-size: 1.1em;
  }
  .leave {
    margin-top: 50px;
  }
  .gamesList {
    max-height: 50vh;
    overflow: auto;
  }
  .previousGames {
    td {
      padding: 3px 5px 0 0;
      vertical-align: top;
    }
    .name {
      font-weight: bold;
    }
    .age {
      color: #666;
      font-size: 85%;
      white-space: nowrap;
    }
  }
}
</style>
