import Stage1 from '../stages/Stage1.vue'
export default {
    code: 'memory',
    name: 'Memory',
    summary: '(just testing for now)',
    deckType: 'standard',
    useTeams: true,
    stage: Stage1,
    questions: [{
        code: 'allowJumpIn',
        display: '"Speed Version" - allow Jump-in',
        type: Boolean
    }],
    defaultAnswers() {
        return {
            allowJumpIn: false,
        }
    }
}