import firebaseDb from "../../firebaseInit";


export default {
    data: function() {
        return {

        };
    },
    computed: {
        shared() {
            return this.$root.shared;
        },
        mySittingOrder() {
            return this.me.sortOrder || 0;
        },
    },
    mounted: function() {
        this.shared.$on('changed-game-code', this.makeDeckIfMatchingGame);
    },
    beforeDestroy: function() {
        this.shared.$off('changed-game-code', this.makeDeckIfMatchingGame);
    },
    methods: {
        showScores() {
            this.shared.showScoreSheet = !this.shared.showScoreSheet;
        },
        showRules() {
            this.shared.showGameRules = !this.shared.showGameRules;
        },
        makeDeckIfMatchingGame() {
            var desiredStageName = this.shared.gameConfig.stage.name;
            if (desiredStageName === this.$options.name) {
                this.makeDeck();
            }
        },
        shuffleDeck() {
            var cards = this.shared.deck.cards;

            var updates = {};
            var deckPath = `/deck/${this.shared.gameKey}`;

            cards.forEach(c => {
                updates[`${deckPath}/cards/${c.key}/sort`] = Math.random();
            });

            updates[`${deckPath}/state`] = 'shuffled';

            firebaseDb.ref().update(updates);

            // this.afterShuffleDeck();
        },
        // afterShuffleDeck() {
        //     // overwrite as needed
        // },
        getOtherCardCount(member) {
            var other = this.shared.otherCardCount[member.id];
            return other;
        },
        getOtherCardsInHand(member) {
            var sorter = this.shared.deckType.cardSorter();

            var cards = this.shared.allCards.filter(c => c.who === member.id && !c.where);

            var copy = cards.slice();
            //copy.forEach(c => c.faceDown = false);

            copy.sort(sorter);

            return copy;
        },
        getPlayerInSpot(playerSpot) {
            // spot 0 is me, at the bottom
            var members = this.shared.members;
            var lastOrder = members.length - 1;

            if (playerSpot > lastOrder) {
                return {}
            }

            var myPosition = this.me.active ? this.mySittingOrder : 0;

            var order = myPosition + playerSpot;

            // rollover if needed
            if (order > lastOrder) {
                order -= members.length;
            }
            // if (order < 0) {
            //     order = members.length + order;
            // }


            var member = members.find(m => m.sortOrder === order && m.id !== this.me.id) || {};
            // console.log('me', myPosition, 'want seat', playerSpot, 'order', order, 'who', member.name)
            return member;
        },
        dealNextCardTo(member) {
            var cards = this.shared.cardsStillInDeck;
            if (cards.length === 0) {
                // nothing to deal
                return;
            }
            var nextCard = cards[0];
            var path = `/deck/${this.shared.gameKey}/cards/${nextCard.key}`;

            firebaseDb.ref(path)
                .update({
                    who: member.id
                });

            member.lastCardReceived = nextCard.key;
            this.$emit('cardDealt');
        },
        dealOneToAll() {
            var vue = this;
            this.shared.members.forEach(vue.dealNextCardTo);
            this.$emit('cardDealtToEach');
        },
        dealAll() {
            var cards = this.shared.cardsStillInDeck;
            if (cards.length === 0) {
                // nothing to deal
                return;
            }

            var memberIndex = 0;
            var updates = {};
            var deckPath = `/deck/${this.shared.gameKey}`;

            updates[`${deckPath}/state`] = 'dealt';

            // set to 999 to do all
            var maxCardsPerPerson = this.shared.gameConfig && this.shared.configAnswers.numCards || 999;
            var maxToDeal = maxCardsPerPerson * this.shared.members.length;

            // debugger;

            cards.forEach((c, i) => {
                const member = this.shared.members[memberIndex];

                updates[`${deckPath}/cards/${c.key}/who`] = i < maxToDeal ? member.id : '';

                memberIndex++;
                if (memberIndex > this.shared.members.length - 1) {
                    memberIndex = 0;
                }
            });

            firebaseDb.ref().update(updates);

            this.$emit('allCardsDealt');
        },
        dealSome(num) {
            var vue = this;
            for (let i = 0; i < num; i++) {
                this.shared.members.forEach(vue.dealNextCardTo);
            }
            this.$emit('someCardsDealt');
        },

        takesTrick(member) {
            // add trickN to tricks, with this player's id
            // move all cards to this trick
            // change "inPlay" to "trickN"

            var numInTrick = this.shared.allCardsInPlay.length;
            if (!numInTrick) {
                return;
            }

            var nextTrickNum = this.shared.tricks.length + 1;

            var path = `/tricks/${this.shared.gameKey}`;
            firebaseDb.ref(path).push({
                id: nextTrickNum,
                takenBy: member.id
            });

            var updates = {};
            this.shared.allCardsInPlay.forEach(c => {
                updates[`/deck/${this.shared.gameKey}/cards/${c.key}/where`] = `trick${nextTrickNum}`
                updates[`/deck/${this.shared.gameKey}/cards/${c.key}/takenBy`] = member.id
            });

            // set this person as the next player
            updates[`/games/${this.shared.gameKey}/nextPlayer`] = member.id;

            firebaseDb.ref().update(updates);
        },
        showLastTrick() {
            // reverse the trick
            var lastTrickNum = this.shared.tricks.length - 1;
            if (lastTrickNum < 0) {
                return;
            }

            var trickInfo = this.shared.tricks[lastTrickNum];
            var trickName = 'trick' + trickInfo.id;

            var updates = {};

            this.shared.allCards.forEach(c => {
                if (c.where === trickName) {
                    updates[`/deck/${this.shared.gameKey}/cards/${c.key}/where`] = 'inPlay';
                }
            });

            var firstPlayer = this.shared.game.previousFirstPlayer;
            if (firstPlayer) {
                updates[`/game/${this.shared.gameKey}/firstPlayer`] = firstPlayer;
            }

            firebaseDb.ref().update(updates);

            firebaseDb.ref(`/tricks/${this.shared.gameKey}/${trickInfo.key}`).remove();
        }
    }
}