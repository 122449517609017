import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/database'
import firebaseDb from './firebaseInit'
import configs from './components/gameConfigs/configs'
import * as moment from "moment/moment";

export default new Vue({
    data: {
        game: {},
        deck: {},
        cardDbRefs: {},
        configAnswers: {},
        configAnswersLoaded: false,
        allConfigAnswers: [],
        gameLoadAttempted: false,
        myId: '',
        myOldGames: null,
        lastPlayOrder: 0,
        myCards: [],
        allCards: [],
        otherCardCount: {},
        allCardsInPlay: [],
        otherCardsInPlay: {},
        cardsStillInDeck: [],
        numCardsPlayed: 0,
        numPlayable: 0,
        people: [],
        guests: [],
        topScore: 0,
        // scoreSheet: {},
        focusedCard: '',
        rounds: [],
        tricks: [],
        cardsInUse: false,
        firebaseRawAuthUser: null,
        disconnecting: false,
        dbGameRef: null,
        initialQuery: '',
        // symbol: '',
        justClaimed: '',
        // buildScoreTimeout: null,
        peopleSortTimeout: null,
        gameKey: '',
        showScoreSheet: false,
        showGameRules: false,

    },
    computed: {
        gameConfig() {
            return this.configs.gameConfigs.find(t => t.code === this.game.code);
        },
        me() {
            switch (this.myId[0]) {
                case 'm':
                    return this.people.find(m => m.id === this.myId) || {};
                case 'g':
                    return this.guests.find(m => m.id === this.myId) || {};
            }
            return {};
        },
        isDealer() {
            return this.myId === this.game.dealer;
        },
        deckType() {
            return this.configs && this.gameConfig ? this.configs.deckType[this.gameConfig.deckType] : {};
        },
        members() {
            return this.people.filter(p => p.active);
        },
        nextPlayer() {
            return this.game.nextPlayer ? this.members.find(m => m.id === this.game.nextPlayer) || {} : {}
        },
        // myIdFromRawUserDisplayName: function() {
        //     // this does not update immediately if the auth user's profile is updated
        //     return this.firebaseRawAuthUser && this.firebaseRawAuthUser.displayName;
        // },
        configs: () => configs,
        maxRounds() {
            if (this.gameConfig && this.gameConfig.maxRounds) {
                return this.gameConfig.maxRounds(this.members.length);
            }
            return 0;
        },
        seating() {
            var seating = [];
            if (!this.members.length) {
                return seating;
            }
            var firstSide = this.configAnswers.counterClockwise ? 'right' : 'left';
            var otherSide = this.configAnswers.counterClockwise ? 'left' : 'right';
            var fs = firstSide[0].toUpperCase();
            var os = otherSide[0].toUpperCase();
            switch (this.members.length) {
                case 1: // solitaire?
                    break;
                case 2:
                    seating[1] = "seatT1 top"
                    break;
                case 3:
                    seating[1] = `seat${fs}1 ${firstSide}`
                    seating[2] = `seat${os}1 ${otherSide}`
                    break;
                case 4:
                    seating[1] = `seat${fs}1 ${firstSide}`
                    seating[2] = `seatT1 top`
                    seating[3] = `seat${os}1 ${otherSide}`
                    break;
                case 5:
                    seating[1] = `seat${fs}2 ${firstSide}`
                    seating[2] = `seat${fs}1 ${firstSide}`
                    seating[3] = `seat${os}1 ${otherSide}`
                    seating[4] = `seat${os}2 ${otherSide}`
                    break;
                case 6:
                    seating[1] = `seat${fs}2 ${firstSide}`
                    seating[2] = `seat${fs}1 ${firstSide}`
                    seating[3] = `seatT1 top`
                    seating[4] = `seat${os}1 ${otherSide}`
                    seating[5] = `seat${os}2 ${otherSide}`
                    break;
                default:
                    console.log(`cannot have > 6 players - ${this.members.length}`)
                    break;
            }

            return seating;
        },
        canPlayAnotherRound() {
            var max = this.maxRounds;
            if (!max) return true;

            return this.game.roundNum < this.maxRounds;
        },
        personWhoTookTrick() {
            var winningCard = this.allCardsInPlay.find(c => c.tookTrick);
            if (!winningCard) {
                return {};
            }
            var person = this.members.find(m => m.id === winningCard.who);
            return person || {};
        },
        numBlankNames: function() {
            return this.members.filter(m => !m.name).length;
        },
        numNonBlankNames: function() {
            return this.members.filter(m => m.name).length;
        },
        numMembers: function() {
            return this.members.length;
        },
        isMember: function() {
            return this.me && this.me.active;
        },
        isViewer: function() {
            return this.me && !this.me.active;
        },
        viewAllCards: function() {
            return this.isViewer && this.gameConfig && this.configAnswers.viewersSeeAll;
        },
        firstCardPlayed() {
            var firstCard = this.allCardsInPlay[0];
            if (!firstCard) return {};

            if (firstCard.suit === 'e') {
                // check for first non-Jester card
                var numCards = this.allCardsInPlay.length;

                firstCard = {};

                for (var i = 1; i < numCards; i++) {
                    var testCard = this.allCardsInPlay[i];
                    if (testCard.suit !== 'e') {
                        firstCard = testCard;
                        break;
                    }
                }
            }
            return firstCard;
        },
        link: function() {
            if (this.firebaseRawAuthUser && this.gameKey) {
                return `${location.origin}/j?${this.gameKey}`;
            }
            return null;
        },
    },
    watch: {},
    created: function() {
        var vue = this;
        this.resetLocalGameInfo();

        vue.handleAuthChanges();
        vue.initialQuery = window.location.search;

        // vue.configAnswers = this.gameConfig.defaultAnswers();
    },
    methods: {
        resetLocalGameInfo() {
            // reset data; on load and later
            this.game = {
                code: ''
            };
            this.deck = {
                cards: {},
                state: ''
            };
            this.cardDbRefs = {};
            this.configAnswers = {};
            this.allConfigAnswers = [];
            this.configAnswersLoaded = false;
            this.gameLoadAttempted = false;
            this.myId = '';
            // this.myOldGames = null;
            this.lastPlayOrder = 0;
            this.myCards = [];
            this.allCards = [];
            this.otherCardCount = {};
            this.allCardsInPlay = [];
            this.otherCardsInPlay = {};
            this.cardsStillInDeck = [];
            this.numCardsPlayed = 0;
            this.people = [];
            this.guests = [];
            // this.scoreSheet = {};
            this.focusedCard = '';
            this.rounds = [];
            this.tricks = [];
            this.cardsInUse = false;
            // this.firebaseRawAuthUser = null;
            // this.disconnecting = false;
            this.dbGameRef = null;
            this.initialQuery = '';
            // this.symbol = '';
            // this.justClaimed = '';
            // this.buildScoreTimeout = null;
            // this.peopleSortTimeout = null;
            this.gameKey = '';
        },
        seatingFor(playerNum) {
            return this.seating[playerNum] || '';
        },
        tableAreaFor(playerNum) {
            return (this.seating[playerNum] || '').replace('seat', 'table');
        },
        gameKeyAbbrev(key) {
            return key ? `${key.substr(1, 3)}.${key.slice(-2)}` : '(no game)';
        },
        personOpposite(who) {
            // for 4-player games
            var members = this.members;
            var i = members.findIndex(m => m.id === who.id);
            i += 2;
            if (i > members.length - 1) {
                i -= members.length;
            }
            return members[i];
        },
        getPlayerName: function(who){
            return who?.name || who?.id || '?';
        },
      
        getNextPlayer(nextToDealer) {
            var members = this.members;
            var next = this.nextPlayer;
            if (nextToDealer || !next.id) {
                // reset to the person beside the dealer
                var dealerId = this.game.dealer;
                next = members.find(m => m.id === dealerId);
                if (!next) {
                    // no dealer? start at 0
                    next = members[0];
                }
                // if (this.gameConfig.code === 'pinochle') {
                //     // partner of dealer
                //     return this.personOpposite(next);
                // }
            }

            var currentIndex = members.findIndex(m => m.id === next.id);
            if (currentIndex === -1) {
                currentIndex = 0; // if not in list, start at 0
            }

            currentIndex++;

            if (currentIndex > members.length - 1) {
                currentIndex = 0;
            }
            if (currentIndex < 0) {
                currentIndex = members.length - 1;
            }

            next = members[currentIndex];

            return next;
        },
        processAuthUser(user) {
            var vue = this;
            if (user) {

                // User is signed in.
                vue.firebaseRawAuthUser = user;
                // debugger;

                firebaseDb.ref(`/users/${vue.firebaseRawAuthUser.uid}`)
                    .on("value", snapshot => {
                        var userInfo = snapshot.val();

                        // debugger;
                        if (vue.initialQuery) {
                            var gameKey = vue.initialQuery.substring(1);
                            vue.initialQuery = '';
                            vue.loadGame(gameKey);
                        } else if (userInfo.gameKey) {
                            vue.justClaimed = userInfo.memberId;

                            if (userInfo.gameKey !== vue.gameKey) {
                                vue.resetLocalGameInfo();
                                vue.loadGame(userInfo.gameKey);
                            }
                        } else {
                            vue.resetLocalGameInfo();
                            vue.gameLoadAttempted = true;
                        }
                    });

                firebaseDb.ref(`/userGames/${vue.firebaseRawAuthUser.uid}`)
                    .on("value", snapshot => {
                        vue.myOldGames = snapshot.val();
                    });

                firebaseDb.ref(`/users/${vue.firebaseRawAuthUser.uid}`)
                    .update({
                        status: 'online',
                    });

                vue.$emit('loggedIn');
                // debugger
                // if (user.photoURL) {
                //     vue.loadGame(user.photoURL);
                // } else {
                //     // not linked to a game already

                //     // see if there is a query string that works
                //     if (vue.initialQuery) {
                //         vue.resetLocalGameInfo();
                //         vue.loadGame(vue.initialQuery.substring(1));
                //     } else {
                //         vue.gameLoadAttempted = true;
                //     }
                // }
            } else {
                // User is signed out.
                vue.firebaseRawAuthUser = null;
                // vue.dbGameRef = null;
            }
            // ...
        },
        handleAuthChanges: function() {
            var vue = this;

            firebase.auth().onAuthStateChanged(function(user) {
                vue.processAuthUser(user);
            });

            firebase.auth().signInAnonymously()
                .then(result => {
                    // logged in anonymously
                    // debugger;
                })
                .catch(function(error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    console.log('login error', errorCode, errorMessage)
                        // debugger;
                });

            // firebase.auth().getRedirectResult().then(function(result) {
            //     if (result.credential) {
            //         // This gives you a Google Access Token. You can use it to access the Google API.
            //         var token = result.credential.accessToken;
            //         // ...
            //     }
            //     // The signed-in user info.
            //     vue.processAuthUser(result.user);
            //     debugger;

            //     // if (user) {

            //     //     vue.
            //     //     // user.updateProfile({
            //     //     //     displayName: "Jane Q. User",
            //     //     //     custom1: 'Test',
            //     //     //     custom2: 23
            //     //     // }).then(function() {
            //     //     //     // Profile updated successfully!
            //     //     //     var displayName = user.displayName;
            //     //     //     // "https://example.com/jane-q-user/profile.jpg"
            //     //     //     debugger;
            //     //     // }, function(error) {
            //     //     //     // An error happened.
            //     //     //     debugger;
            //     //     // });
            //     // }
            // }).catch(function(error) {
            //     // Handle Errors here.
            //     var errorCode = error.code;
            //     var errorMessage = error.message;
            //     // The email of the user's account used.
            //     var email = error.email;
            //     // The firebase.auth.AuthCredential type that was used.
            //     var credential = error.credential;
            //     // ...
            //     debugger;
            // });

            firebaseDb.ref('.info/connected')
                .on('value', function(snapshot) {
                    if (!snapshot.val()) {
                        // we are not connected
                        return;
                    }

                    var updates = {};
                    updates.status = 'offline';

                    if (vue.firebaseRawAuthUser) {
                        firebaseDb.ref(`/users/${vue.firebaseRawAuthUser.uid}`)
                            .onDisconnect()
                            .update(updates);
                    }
                });
        },
        changedToDiffGameType() {
            // this.game.code is different
            // don't allow change after game started
        },
        loadGame: function(gameKey) {
            var vue = this;
            // debugger;

            if (gameKey) {
                firebaseDb.ref(`/users/${vue.firebaseRawAuthUser.uid}`)
                    .update({
                        gameKey: gameKey
                    });

                var gameRef = firebaseDb.ref(`/games/${gameKey}`);
                gameRef.once('value')
                    .then(snapshot => {
                        if (snapshot.exists()) {
                            // only connect if we know it exists
                            var game = snapshot.val();
                            // debugger;
                            if (game.deletedMe) {
                                // being deleted
                                return;
                            }

                            vue.connectToGame(gameRef);
                        } else {
                            vue.gameLoadAttempted = true;

                            // is this in the userGames list?
                            firebaseDb.ref(`/userGames/${vue.firebaseRawAuthUser.uid}/${gameKey}`)
                                .remove();
                        }
                    });
            }
        },
        connectToGame: function(gameRef) {
            // we know that ref refers to an actual db entry
            var vue = this;
            // console.log('connected to game', gameRef.key);
            vue.dbGameRef = gameRef;
            vue.gameKey = gameRef.key;
            // vue.firebaseRawAuthUser.updateProfile({
            //     // for later use?
            //     photoURL: gameRef.key
            // });
            firebaseDb.ref(`/users/${vue.firebaseRawAuthUser.uid}`)
                .update({
                    gameKey: vue.gameKey
                });

            vue.watchForListChanges(vue.people, firebaseDb.ref('/people/' + vue.gameKey), (person, rawData) => {

                // local updates
                if (!person.chimeNum) {
                    person.chimeNum = 1;
                }
                if (vue.gameConfig && vue.gameConfig.useMeld && !person.meldDisplay) {
                    person.meldDisplay = [];
                }

                // keep them sorted locally
                clearTimeout(vue.peopleSortTimeout);
                vue.peopleSortTimeout = setTimeout(() => {
                    if (vue.myId && !vue.people.length) {
                        // clean up! 
                        // there are no people... make me a temporary admin
                        vue.me.isAdmin = true;
                    }

                    if (vue.isDealer && vue.configAnswers.simultaneousBidding && vue.members.length) {
                        // check if all bids are in
                        var bidsReady = true;
                        for (let i = 0; i < vue.members.length && bidsReady; i++) {
                            var m = vue.members[i];
                            if (!m.bidReady || m.bidSet) {
                                bidsReady = false;
                            }
                        }
                        // console.log('bids ready', bidsReady)
                        if (bidsReady) {
                            var updates = {};

                            updates[`/games/${vue.gameKey}/state`] = 'bidsSet';

                            vue.members.forEach(m => {
                                updates[`/people/${vue.gameKey}/${m.id}/bidSet`] = true;
                            });

                            firebaseDb.ref().update(updates);
                        }
                    }

                    vue.people.sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);
                    vue.$emit('people-updated');

                    if (vue.gameConfig && vue.gameConfig.name) {
                        firebaseDb.ref(`/userGames/${vue.firebaseRawAuthUser.uid}/${vue.gameKey}`)
                            .update({
                                created: vue.game.created,
                                when: moment().toISOString(),
                                name: vue.gameConfig.name,
                                who: vue.people.filter(p => p.name).map(p => p.name).join(', ')
                            });
                    }
                }, 100);

                // console.log(person);

                // if (person.id === (vue.myIdFromRawUserDisplayNameLocal || vue.myIdFromRawUserDisplayName) ||
                if (person.id && (person.id === vue.myId ||
                        person.id === vue.justClaimed)) {
                    // debugger;
                    vue.justClaimed = null;

                    if (vue.me.id) {
                        if (!person.connected) {
                            // admin may have marked me as gone?
                            vue.claimMember(person.id);
                        }
                        // if (vue.me.rebid) {
                        //     vue.me.bid = vue.me.rebid;
                        // }
                        if (vue.me.sendCards) {
                            vue.$emit('sendCards');
                        }
                    } else if (vue.disconnecting) {
                        vue.disconnecting = false;
                    } else {
                        vue.claimMember(person.id);
                    }
                }

                if (!person.id && person.connected === false) {
                    // an orphaned member that is recreated by the functions... need to clean this up
                    rawData.ref.remove();
                }
            }, (deletedMember, rawData) => {
                // if (deletedMember.id === (vue.myIdFromRawUserDisplayNameLocal || vue.myIdFromRawUserDisplayName) ||
                if (deletedMember.id === vue.myId ||
                    deletedMember.id === vue.justClaimed) {
                    // my member info has been removed
                    vue.forgetMe();
                    return;
                }
                if (!deletedMember.id && deletedMember.connected === false) {
                    // an orphaned member that is recreated by the functions... need to clean this up
                    rawData.ref.remove();
                }
            });

            vue.watchForListChanges(vue.guests, firebaseDb.ref('/guests/' + vue.gameKey), (person, ref) => {
                if (person.id && (person.id === vue.myId ||
                        person.id === vue.justClaimed)) {

                    // debugger;
                    vue.justClaimed = null;
                    if (vue.me.id) {
                        if (!person.connected) {
                            // admin may have marked me as gone?
                            vue.claimGuest(person.id);
                        }
                    } else if (vue.disconnecting) {
                        vue.disconnecting = false;
                    } else {
                        vue.claimGuest(person.id);
                    }
                }
            }, (deletedGuest, rawData) => {
                if (deletedGuest.id === vue.myId ||
                    deletedGuest.id === vue.justClaimed) {
                    // my member info has been removed
                    // debugger
                    vue.forgetMe();
                    return;
                }
                if (!deletedGuest.id && deletedGuest.connected === false) {
                    // an orphaned member that is recreated by the functions... need to clean this up
                    rawData.ref.remove();
                }
            });

            vue.watchForListChanges(vue.tricks, firebaseDb.ref('/tricks/' + vue.gameKey), (trick, ref) => {
                // console.log(trick, ref);
                trick.key = ref.key;
            });

            vue.watchForListChanges(vue.rounds, firebaseDb.ref('/rounds/' + vue.gameKey), (round, ref) => {
                // clearTimeout(this.buildScoreTimeout);
                // if (vue.myId === vue.game.dealer) {
                // this.buildScoreTimeout = setTimeout(() => this.buildScoreTable(), 250);
                // }
            });

            if (vue.game && vue.game.createdBy) {
                // record when this game was last used, but not if it was deleted!
                gameRef.update({
                    lastLogin: moment().toISOString()
                });
            }

            gameRef.on('value', function(snapshot) {
                var incomingGame = snapshot.val() || {};

                // console.log('game changed', incomingGame);
                if (!incomingGame || !incomingGame.createdBy || incomingGame.deleteMe) {
                    // deleted!
                    vue.gameKey = '';
                    vue.game = {};

                    vue.logout();
                    vue.$emit('goto', '/');
                    return;
                }

                //var updateConfig = incomingGame.code && (incomingGame.code !== vue.game.code);
                //var changedCodeCode = updateConfig && incomingGame.code && vue.game.code;

                if (!incomingGame.code) {
                    incomingGame.code = 'estimation';
                }

                var trumpChanged = incomingGame.trumpSuit !== vue.game.trumpSuit ||
                    incomingGame.trumpSuit === 'w';
                var typeChanged = incomingGame.code !== vue.game.code;
                var bidChanged = incomingGame.currentBid !== vue.game.currentBid;

                vue.game = incomingGame;

                // if (updateConfig) {
                //     // load config answers
                //     firebaseDb.ref(`/configAnswers/${vue.gameKey}/${vue.game.code}`).once("value", snapshot => {
                //         var config = snapshot.val();
                //         vue.configAnswers = config || vue.gameConfig.defaultAnswers();

                //         if (changedCodeCode) {
                //             vue.$emit('changed-game-code');
                //         }
                //     });
                // }

                vue.gameLoadAttempted = true;
                vue.$emit('game-changed');

                if (trumpChanged) {
                    vue.$emit('trumpChanged');
                }
                if (bidChanged) {
                    setTimeout(() => {
                        vue.$emit('currentBidChanged');
                    }, 500);
                }
                if (typeChanged) {
                    vue.configAnswers = vue.gameConfig.defaultAnswers();
                    vue.$emit('config-changed');
                }
            });

            firebaseDb.ref(`/configAnswers/${vue.gameKey}`)
                .on("value", snapshot => {
                    var configAnswers = snapshot.val();

                    var answers;
                    if (vue.gameConfig) {
                        answers = vue.gameConfig.defaultAnswers();
                    }

                    if (configAnswers) {
                        var incomingAnswers = configAnswers[vue.game.code];
                        if (incomingAnswers) {
                            // merge with existing
                            Object.assign(answers, incomingAnswers);
                        }
                    }
                    vue.configAnswersLoaded = true;
                    vue.configAnswers = answers;
                    vue.$emit('config-changed');
                });

            firebaseDb.ref(`/deck/${vue.gameKey}`)
                .on("value", snapshot => {
                    var deck = snapshot.val();
                    if (deck) {
                        vue.loadDeck(deck);
                    }
                });

            // firebaseDb.ref(`/scoreSheet/${vue.gameKey}`)
            //     .on("value", snapshot => {
            //         var scoreSheet = snapshot.val();
            //         if (scoreSheet) {
            //             vue.scoreSheet = scoreSheet;
            //         }
            //     });

            // debugger
            /// NEEDED?
            // firebaseDb.ref(`/users/${vue.firebaseRawAuthUser.uid}`)
            //     .update({
            //         status: 'online',
            //         gameKey: vue.gameKey,
            //         memberId: vue.myIdFromRawUserDisplayNameLocal || vue.myIdFromRawUserDisplayName || ''
            //     });

            firebaseDb.ref(`/userGames/${vue.firebaseRawAuthUser.uid}/${vue.gameKey}`)
                .update({
                    when: moment().toISOString(),
                });
        },
        forgetMe: function() {
            if (this.myId) {
                var oldId = this.myId;

                // disconnect from the member/viewer
                this.disconnecting = true;

                this.myId = '';
                this.myCards = [];

                var updates = {};
                updates[`/users/${this.firebaseRawAuthUser.uid}/memberId`] = '';

                switch (oldId[0]) {
                    case 'm':
                        // if we are being forced out because the person record is gone, don't try to update it!
                        if (this.people.find(m => m.id === oldId)) {
                            updates[`/people/${this.gameKey}/${oldId}/connected`] = false;
                        }
                        break;
                    case 'g':
                        if (this.guests.find(g => g.id === oldId)) {
                            updates[`/guests/${this.gameKey}/${oldId}/connected`] = false;
                        }
                        break;
                }

                firebaseDb.ref().update(updates);

                this.$emit('goto', '/g');
            }
        },
        loadDeck(incomingDeck) {
            var vue = this;
            var cardList = Object.keys(incomingDeck.cards).map(key => {
                var card = incomingDeck.cards[key];
                card.key = key;
                return card
            });
            // console.log('load deck')
            var deckType = vue.deckType;
            if (!deckType.adjustOnLoad) {
                // console.log('no deck type', incomingDeck);
                return;
            }
            var inDeck = [];
            var myCards = [];
            vue.otherCardCount = {};
            vue.otherCardsInPlay = {};
            vue.allCardsInPlay = [];
            var cardsInPlay = [];
            var lastPlayOrder = 0;
            var numCardsPlayed = 0; // cards on table (inPlay) or in a trick (where!=='')
            var cardsInUse = false;
            var sorter = vue.deckType.cardSorter(vue);

            var freshlyShuffled = incomingDeck.state === 'shuffled';
            if (freshlyShuffled) {
                vue.focusedCard = '';
            }
            var oldDeck = this.deck;

            cardList.forEach(card => {
                deckType.adjustOnLoad(card);

                if (card.who) {
                    if (card.who === vue.myId) {
                        // card.faceDown = false; // per game?

                        // console.log(card.key, card.focused, this.game.state);
                        if (this.game.state === 'meld') {
                            // leave as is
                            // console.log('where', card.key, card.where)
                            if (!card.where) {
                                var oldCard = oldDeck.cards[card.key];
                                if (oldCard) {
                                    // console.log(card.key, 'old', oldCard.focused)
                                    card.focused = oldCard.focused;
                                }
                            }
                        } else if (this.game.state === 'exchangeCards') {
                            // leave as is
                        } else {
                            card.focused = vue.focusedCard === card.key;
                            // console.log('keep focus?', card.key, vue.focusedCard, card.focused)
                        }

                        if (vue.me.lastCardReceived === card.key) {
                            card.newCard = true;
                        }

                        if (card.newCard) {
                            // may be set elsewhere
                            setTimeout(function() {
                                card.newCard = false;
                            }, 1000);
                        }

                        myCards.push(card);
                    } else {
                        if (!card.where) {
                            // count to show mini-cards
                            var other = vue.otherCardCount[card.who];
                            if (!other) {
                                vue.otherCardCount[card.who] = 0;
                            }
                            vue.otherCardCount[card.who] += 1;
                        }
                        if (card.where === 'inPlay') {
                            var inPlay = vue.otherCardsInPlay[card.who];
                            if (!inPlay) {
                                vue.otherCardsInPlay[card.who] = [];
                            }
                            vue.otherCardsInPlay[card.who].push(card);
                        }
                    }
                    if (card.where === 'inPlay') {
                        cardsInPlay.push(card);
                    }
                } else {
                    inDeck.push(card);
                }
                if (card.playOrder && card.playOrder > lastPlayOrder) {
                    lastPlayOrder = card.playOrder;
                }
                if (!freshlyShuffled) {
                    if (card.where || card.who) {
                        cardsInUse = true;
                    }
                }
                if (card.where) {
                    numCardsPlayed++;
                }
            })

            if (inDeck.some(c => c.sort)) {
                inDeck.sort((a, b) => a.sort < b.sort ? -1 : 1);
            }
            if (myCards.length) {
                myCards.sort(sorter);
            }

            if (cardsInPlay.length) {
                cardsInPlay.sort((a, b) => a.playOrder < b.playOrder ? -1 : 1);
            }

            Object.keys(vue.otherCardsInPlay).forEach(key => {
                vue.otherCardsInPlay[key].sort(sorter)
            });

            vue.cardsStillInDeck = inDeck;
            vue.myCards = myCards;
            vue.lastPlayOrder = lastPlayOrder;
            vue.allCardsInPlay = cardsInPlay;
            vue.cardsInUse = cardsInUse;
            vue.allCards = cardList;
            vue.numCardsPlayed = numCardsPlayed;

            var readyToDeal = false;

            // compare new with existing
            if (incomingDeck.state === 'shuffled') {
                readyToDeal = true;
            }

            vue.deck = incomingDeck;

            if (deckType.afterCardsChange) {
                deckType.afterCardsChange(vue);
            }

            if (readyToDeal && vue.gameConfig.dealAfterShuffle && vue.isDealer) {
                vue.$emit('readyToDeal');
            }
        },
        logout() {
            // debugger;
            firebaseDb.ref(`/users/${this.firebaseRawAuthUser.uid}`)
                .update({
                    memberId: '',
                    // status: 'offline',
                    gameKey: ''
                });

            this.resetLocalGameInfo();

            // this.firebaseRawAuthUser.signOut()
            //     .then(() => {
            //         if (cb) cb();
            //     })

            // this.$root.$router.replace("/");
            // if (!stayOnPage) {
            //     // location.href = "../";
            //     this.$router.replace("/");
            // }
        },
        // loginGoogle: function() {
        //     var provider = new firebase.auth.GoogleAuthProvider();
        //     firebase.auth().signInWithRedirect(provider)
        //         .then((a, b, c) => {
        //             // debugger
        //         })
        //         .catch(err => {
        //             var code = err.code;
        //             var messge = err.message;
        //             // debugger
        //         });
        // },
        watchForListChanges: function(localList, listRef, onAddChange, onRemove) {
            var i;
            listRef.on('child_added', data => {
                // we may have loaded it locally already, so may need to replace it
                var item = data.val();
                i = localList.findIndex(x => x.id === item.id);
                if (i !== -1) {
                    localList.splice(i, 1, item);
                } else {
                    localList.push(item);
                }
                if (onAddChange) {
                    onAddChange(item, data);
                }
            }, null, listRef);

            listRef.on('child_changed', data => {
                var item = data.val();
                i = localList.findIndex(x => x.id === item.id);
                if (i !== -1) {
                    try {
                        localList.splice(i, 1, item);
                    } catch (err) {
                        // debugger;
                    }
                } else {
                    // missing??
                    console.log('missing', item.id);
                    localList.push(item);
                }
                if (onAddChange) {
                    onAddChange(item, data);
                }
            });

            listRef.on('child_removed', data => {
                var item = data.val();
                i = localList.findIndex(x => x.id === item.id);
                if (i !== -1) {
                    localList.splice(i, 1);
                    if (onRemove) {
                        onRemove(item, data);
                    }
                } else {
                    // missing??
                    // console.log('cannot delete', item);
                }
            });
        },
        claimGuest(guestId) {
            var vue = this;
            vue.myId = guestId;
            vue.justClaimed = guestId;

            // debugger;

            firebaseDb.ref(`/users/${vue.firebaseRawAuthUser.uid}`)
                .update({
                    status: 'online',
                    gameKey: vue.gameKey,
                    memberId: guestId
                });

            firebaseDb.ref(`/guests/${vue.gameKey}/${guestId}`)
                .update({
                    connected: vue.firebaseRawAuthUser.uid,
                    connectedTime: moment().toISOString(),
                    browser: this.getBrowserInfo()
                });

            firebaseDb.ref(`/deck/${vue.gameKey}`)
                .once("value", snapshot => {
                    var deck = snapshot.val();
                    if (deck) {
                        vue.loadDeck(deck);
                    }
                });
        },
        claimMember(personId) {
            var vue = this;
            // debugger
            // console.log('set connected', this.firebaseRawAuthUser.uid);
            vue.myId = personId;
            vue.justClaimed = personId;

            // change my default id in my base firebase auth profile
            // vue.myIdFromRawUserDisplayNameLocal = personId;
            // vue.firebaseRawAuthUser.updateProfile({
            //     displayName: personId
            // });
            // vue.firebaseRawAuthUser.reload();

            // change the 'users' record that I'm associated with
            // debugger
            firebaseDb.ref(`/users/${vue.firebaseRawAuthUser.uid}`)
                .update({
                    status: 'online',
                    gameKey: vue.gameKey,
                    memberId: personId
                });

            // tell the person that I'm connected
            // debugger;
            firebaseDb.ref(`/people/${vue.gameKey}/${personId}`)
                .update({
                    connected: vue.firebaseRawAuthUser.uid,
                    connectedTime: moment().toISOString(),
                    browser: this.getBrowserInfo()
                });

            firebaseDb.ref(`/deck/${vue.gameKey}`)
                .once("value", snapshot => {
                    var deck = snapshot.val();
                    if (deck) {
                        vue.loadDeck(deck);
                    }
                });
        },
        getBrowserInfo() {
            // document browser
            return {
                ver: this.getBrowserVersion(),
                // lang: window.navigator.language ? window.navigator.language : (window.navigator.systemLanguage + ';' + window.navigator.userLanguage),
                screen: screen.availWidth + 'x' + screen.availHeight + 'c' + screen.colorDepth,
                window: window.innerWidth + 'x' + window.innerHeight,
            };
        },
        getBrowserVersion() {
            var ua = window.navigator.userAgent;

            var op = ua.match('.*OPR\/([0-9\.]*)');
            if (op && op.length == 2) {
                return 'Opera ' + op[1];
            }

            var chrome = ua.match('.*Chrome\/([0-9\.]*)[$ ]');
            if (chrome && chrome.length == 2) {
                return 'Chrome ' + chrome[1];
            }
            var ff = ua.match('.*Firefox\/([0-9\.]*)');
            if (ff && ff.length == 2) {
                return 'FF ' + ff[1];
            }

            var ie11Plus = ua.match('.*Trident.*rv\:([0-9m\.]*).*');
            if (ie11Plus && ie11Plus.length) {
                return 'IE ' + ie11Plus[1];
            }

            var ieOlder = ua.match('.*MSIE \([0-9\.]*).*');
            if (ieOlder && ieOlder.length) {
                return 'IE ' + ieOlder[1] + 'm' + document.documentMode;
            }

            var safari = ua.match('.*Safari\/([0-9\.]*)');
            if (safari && safari.length == 2) {
                var host = /Mozilla\/.*\((\w*)\;/.exec(ua);
                return 'Safari ' + safari[1] + (host ? ' ' + host[1] : '');
            }

            return 'Unk';
        },
        // claimViewer: function(viewerId) {
        //     this.me = this.viewers.find(v => v.id === viewerId);

        //     if (this.me.id) {
        //         firebaseDb.ref(`/viewers/${this.gameKey}/${viewerId}`).update({
        //             connected: this.firebaseRawAuthUser.uid,
        //             connectedTime: firebase.database.ServerValue.TIMESTAMP
        //         });

        //         this.firebaseRawAuthUser.updateProfile({
        //             displayName: viewerId
        //         });
        //     } else {
        //         console.log('Did not find viewer', viewerId);
        //     }
        // },
        // startMeAsViewer: function() {
        //     var id = this.getRandomId('v', this.viewers);
        //     var lastName = this.viewers.length ? this.viewers[this.viewers.length - 1].name : null;
        //     // can handle 26 viewers... should not have more than 1 or 2
        //     var nextNum = lastName ? +lastName.substr(1) : 0;
        //     var name = 'V' + (1 + nextNum);
        //     var viewer = {
        //         id: id,
        //         name: name
        //     };

        //     firebaseDb.ref(`/viewers/${this.gameKey}/${id}`).set(viewer);

        //     this.claimViewer(id);
        // },
        // updateList: function(list, doc) {
        //     var item = doc.data();
        //     var i = list.findIndex(x => x.id === item.id);
        //     if (i !== -1) {
        //         list.splice(i, 1, item);
        //     } else {
        //         list.push(item);
        //     }
        // },
        createGame: function(nameOfAdmin) {
            var vue = this;
            if (!vue.firebaseRawAuthUser) {
                return;
            }

            vue.people = [];

            var newAdminPerson = vue.makePerson(nameOfAdmin, null, 0);
            vue.myId = newAdminPerson.id;

            newAdminPerson.connected = moment().toISOString();
            newAdminPerson.isAdmin = true;
            newAdminPerson.active = true;

            var gameRef = firebaseDb.ref('/games')
                .push(); // generate new game doc

            gameRef.set({
                    created: moment().toISOString(),
                    createdBy: nameOfAdmin,
                    dealer: newAdminPerson.id
                }).then(function() {
                    vue.connectToGame(gameRef);

                    vue.createMembers(newAdminPerson);

                    gtag('event', 'createGame');

                    vue.$emit('game-created');
                })
                .catch(function(err) {
                    console.log(err);
                    vue.$emit('game-creation-error', err);
                });
        },
        createMembers: function(firstAdmin) {
            var vue = this;
            // debugger;

            var newPeople = [];

            // me.connected = this.firebaseRawAuthUser.uid;
            // me.connectedTime = firebase.database.ServerValue.TIMESTAMP;
            newPeople.push(firstAdmin);
            // debugger
            // default to 4 members
            for (var i = 0; i < 3; i++) {
                var person = vue.makePerson('', newPeople, i + 1);
                person.active = true;
                newPeople.push(person);
            }

            var peopleSet = {};
            newPeople.forEach(p => {
                peopleSet[p.id] = p;
            });

            firebaseDb.ref(`/people/${vue.gameKey}`)
                .set(peopleSet)

            firebaseDb.ref(`/users/${vue.firebaseRawAuthUser.uid}`)
                .update({
                    memberId: vue.myId
                });

            // vue.claimMember(vue.myId);
        },
        getRandomId: function(prefix, list, numDigits) {
            var uniqueIdFound = false;
            var id;
            numDigits = numDigits || 3;
            while (!uniqueIdFound) {
                id = prefix + Math.random().toString().substr(3, numDigits);
                uniqueIdFound = !list || !list.find(m => m.id === id);
            }
            return id;
        },
        makePerson: function(name, list, sortOrder) {
            var id = this.getRandomId('m', list);
            return {
                name: name || '',
                id: id,
                isAdmin: false,
                connected: false,
                chime: false,
                chimeVolume: 50,
                chimeNum: sortOrder + 1,
                participating: true,
                bid: '',
                bidSet: false,
                sortOrder: sortOrder
            };
        },
        plural(num){
            return num === 1 ? '' : 's';
        },
        copyToClipboard: function(text, title, msg) {
            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            this.$notify({
                title: title || 'Copied',
                message: msg || 'Copied to the clipboard',
                type: 'success'
            });
        }
    }
});