import Stage2Pinochle from '../stages/Stage2_pinochle.vue'
export default {
    code: 'pinochle',
    name: 'Pinochle',
    publish: true,
    summary: 'Score meld and win tricks!',
    stage: Stage2Pinochle,
    deckType: 'pinochle',
    aceIsHigh: true,
    minPlayers: 4,
    maxPlayers: 4,
    tableSize: 4, // match # of players
    useTeams: false, // auto teams - alternating seats
    /*
game flow:
 -deal, (3 players - with kitty)
 -bid, from 21
 -winner chooses trump
 -4 player - winner & partner exchange 4? cards
 -3 player - put 4 cards aside
 -show and score meld
 -play
    */
    dealAfterShuffle: true,
    useBidding: true,
    bidBeforeRound: true,
    bidFrom: 30, // 20 if only 3 playing
    bidCanPass: true,
    hideBidInPlay: true,
    useMeld: true,
    scoreByPoints: true,
    questions: [{
        code: 'counterClockwise',
        display: `Play proceeds:`,
        true: 'Counter-clockwise',
        false: 'Clockwise',
        type: 'BooleanSwitch'
    }, {
        code: 'viewersSeeAll',
        display: `Show cards to non-players`,
        type: 'Boolean'
    }],
    adjustAnswers(newAnswers, oldAnswers) {},
    calcScore(bid, tricksTaken, numCardsInRound) {
        var scoreForRound;
        if (bid === tricksTaken) {
            scoreForRound = 2 + tricksTaken;
        } else {
            scoreForRound = 0 - Math.abs(bid - tricksTaken);
        }
        return scoreForRound;
    },
    defaultAnswers() {
        return {
            viewersSeeAll: true,
            counterClockwise: true,
        }
    }
}