<template>
  <div class="Admin">
    <setup-game></setup-game>
    <setup-names></setup-names>

    <div class="Admin panel">
      <h1>Share this Game</h1>
      <p>Share this game with others from the <a href="./share">Share / Talk</a> page.</p>
    </div>

    <div class="Admin panel">
      <h1>Start Playing</h1>
      <p>Use the <a href="./play">Play</a> page to play the game!</p>
    </div>

    <div class="Admin panel">
      <h2>All Done with the Game?</h2>
      <p>When the game is done, you can leave the game and close your browser. The game will stay on the server for a while.</p>
      <p>However, if you want to permanently delete it now, click:</p>
      <button
        v-if="!pendingDelete"
        class="caution"
        v-on:click="pendingDelete = true"
      >
        Delete this Game...
      </button>
      <button
        v-if="pendingDelete"
        class="caution"
        v-on:click="deleteGame"
      >Are you sure? Click again to Delete now! No Undo.</button>
    </div>
  </div>
</template>

<script>

import firebaseDb from "../firebaseInit";
import SetupNames from "./SetupNames.vue";
import SetupGame from "./SetupGame.vue";

export default {
  name: "Admin",
  components: {
    SetupNames,
    SetupGame
  },
  data: function () {
    return {
      pendingDelete: false,
      pendingTimer: null
    };
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    },
  },
  watch: {
    pendingDelete: function (a) {
      if (a) {
        var vue = this;
        clearTimeout(this.pendingTimer);
        this.pendingTimer = setTimeout(() => {
          vue.pendingDelete = false;
        }, 5000);
      }
    }
  },
  methods: {
    deleteGame: function () {
      if (!this.shared.me.isAdmin) {
        return;
      }
      if (!this.shared.gameKey) {
        // something's wrong
        return;
      }

      firebaseDb.ref(`/games/${this.shared.gameKey}`)
        .update({
          // will be deleted by a firebase Function
          deleteMe: true
        });

      this.shared.game = {};
      this.shared.gameKey = '';
    }
  }
};
</script>

<style lang="less">
// .Admin {
// }
</style>
