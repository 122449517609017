<template>
  <div class="Admin">
    <div class="panel">
      <h1>Share the Link</h1>
      <p>Copy and share this link with the players so that they can join the game:</p>
      <p>
        <el-link
          :href="shared.link"
          type="primary"
          onclick="return false"
          target="_blank"
        >{{ shared.link }}</el-link>
        <br>
        <el-button
          icon="el-icon-share"
          size="small"
          v-on:click="shared.copyToClipboard(shared.link, 'Link Copied', 'The link is now in your clipboard.')"
        >Copy Link for Others</el-button>
      </p>
      <p>This link is the only way to get into this game!</p>

    </div>
    <div class="panel left">
      <h1>Talk as you play together</h1>
      <p>Use whichever technology you like best to talk with each other, such as by
        <strong>Zoom</strong>, <strong>Meet</strong>, <strong>Jitsi</strong>, Discord, Hangouts, Facetime, Teams, Skype, telephone conference call, and many more...
        Video calls are the most fun!
        If you are playing on your computer and have only one screen, consider using your smartphone for the video call!</p>

      <h2>Zoom</h2>
      <p>Here is a
        <el-link
          target="_blank"
          type="primary"
          href="https://us04web.zoom.us/start/videomeeting"
        >link to start a Zoom video call</el-link>. A Zoom account (paid or free) is needed. You can then invite others to join you. There may be a time limit on your call.</p>
      <h2>Google Meet</h2>
      <p>Here is a
        <el-link
          target="_blank"
          type="primary"
          href="https://meet.google.com/new?hs=180"
        >link to start a Google Meet video call</el-link>. A Google account (free) is needed. You can then invite others to join you.</p>
      <h2>Jitsi</h2>
      <p>You can use Jitsi for free with no account. Jitsi runs in browsers and most smartphones. (No browser extensions are needed.)
        Here is a Jitsi room <strong>just for this game</strong> that each player can join by clicking this link:
      </p>
      <blockquote>
        <el-link
          :href="jitsiLinkFull"
          type="primary"
          target="_blank"
        >{{ jitsiLinkBase }}
          <br>
          <el-button
            icon="el-icon-top-right"
            size="small"
          >Open our Jitsi room for {{ shared.me.name }}</el-button>
        </el-link>
        <br>
        <el-button
          icon="el-icon-share"
          size="small"
          v-on:click="shared.copyToClipboard(jitsiLinkBase, 'Link Copied', 'The link is now in your clipboard.')"
        >Copy Link for Others</el-button>
      </blockquote>
    </div>

  </div>
</template>

<script>

import firebaseDb from "../firebaseInit";

export default {
  name: "Admin",
  components: {
  },
  data: function () {
    return {
      pendingDelete: false,
      pendingTimer: null
    };
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    },
    codeForJitsi() {
      var code = this.shared.gameKey;

      // strip out non-alpha
      code = code.replace(/[^a-z]/gi, '');
      code = code.substr(0, 1).toUpperCase() + code.substr(1, 6).toLowerCase();

      return 'OurGame' + code;
    },
    jitsiLinkBase() {
      return 'https://meet.jit.si/' + this.codeForJitsi;
    },
    jitsiLinkFull() {
      return this.jitsiLinkBase + `#userInfo.displayName="${this.shared.me.name}"`;
    }
  },
  watch: {

  },
  methods: {

  }
};
</script>

<style lang="less">
.Admin {
  .forCopy {
    width: 40em;
    input {
      text-align: center;
      font-size: 1.3em;
    }
  }
  .panel {
    &.left {
      text-align: left;
    }
  }
  .el-button {
    margin: 3px 0 0 0;
  }
}
</style>
