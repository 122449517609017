import deckType_standard from './deckType_standard'
import deckType_pinochle from './deckType_pinochle'
import deckType_draw1 from './deckType_draw1'
import gameConfig_estimation from './gameConfig_estimation'
import gameConfig_wiz from './gameConfig_wiz'
import gameConfig_pinochle from './gameConfig_pinochle'
import gameConfig_draw1 from './gameConfig_draw1'
import gameConfig_memory from './gameConfig_memory'

export default {
    gameConfigs: [
        gameConfig_estimation,
        gameConfig_wiz,
        gameConfig_pinochle,
        gameConfig_draw1,
        gameConfig_memory,
    ],
    deckType: {
        standard: deckType_standard,
        pinochle: deckType_pinochle,
        draw1: deckType_draw1
    }
};