<template>
  <div class="SetupGame">
    <div class="panel">
      <h1>Choose the game</h1>

      <el-radio-group
        v-model="localGameCode"
        :disabled="!allowChangeType"
      >
        <el-radio-button
          v-for="gt in gameConfigs"
          :key="gt.code"
          :label="gt.code"
        >{{ gt.name }}</el-radio-button>
      </el-radio-group>

      <p class="summary">{{ summary }}</p>
      <p v-if="shared.gameConfig">
        <el-button
          type="success"
          icon="el-icon-info"
          title="Rules of the Game"
          v-on:click="showRules"
        >View the rules for {{ shared.gameConfig.name }}</el-button>
        <game-rules
          :visible="shared.showGameRules"
          v-on:closeMe="shared.showGameRules=false"
        ></game-rules>

      </p>

      <div class="questions">
        <div>

          <div
            v-for="q in gameConfigQuestionsBoolean"
            :key="q.code"
          >
            <el-checkbox
              v-model="shared.configAnswers[q.code]"
              :disabled="!questionsReady"
              v-on:change="configChanged"
            >{{ q.display }}</el-checkbox>
          </div>

          <div
            v-for="q in gameConfigQuestionsBooleanSwitch"
            :key="q.code"
            class="BooleanSwitch"
          >
            <span>{{ q.display }}</span>
            <el-switch
              v-model="shared.configAnswers[q.code]"
              :active-text="q.true"
              :inactive-text="q.false"
              v-on:change="configChanged"
            ></el-switch>
            <!-- <el-checkbox
              v-model="shared.configAnswers[q.code]"
              :disabled="!questionsReady"
              v-on:change="configChanged"
            >{{ q.display }}</el-checkbox> -->
          </div>
        </div>

        <div>
          <div
            v-for="q in gameConfigQuestionsNumberSlider"
            :key="q.code"
          >
            <div>{{ q.display }}</div>
            <el-input-number
              v-model="shared.configAnswers[q.code]"
              :disabled="!questionsReady"
              :min="q.min"
              :max="q.max"
              v-on:change="configChanged"
            >
            </el-input-number>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import firebaseDb from "../firebaseInit";
import gameRules from "./controls/GameRules";

export default {
  name: "SetupGame",
  components: {
    gameRules,
  },
  data: function () {
    return {
      oldAnswers: null,
      questionsReady: false
    };
  },
  computed: {
    game: function () {
      return this.shared.game;
    },
    shared: function () {
      return this.$root.shared;
    },
    gameConfigs() {
      return this.shared.configs.gameConfigs.filter(g => g.publish);
    },
    localGameCode: {
      get() { return this.shared.game.code; },
      set(v) {
        if (this.allowChangeType) {
          this.shared.configAnswersLoaded = false;
          var path = `/games/${this.shared.gameKey}`;
          firebaseDb.ref(path)
            .update({
              code: v
            });
          // this.shared.changedToDiffGameType();
        }      }
    },
    allowChangeType() {
      var s = this.shared
      return s.rounds.length === 0
        && s.tricks.length === 0
        && s.allCards.length === 0;
    },
    gameConfigQuestionsBoolean() {
      if (!this.shared.gameConfig) return [];
      return this.shared.gameConfig.questions.filter(q => q.type === 'Boolean');
    },
    gameConfigQuestionsBooleanSwitch() {
      if (!this.shared.gameConfig) return [];
      return this.shared.gameConfig.questions.filter(q => q.type === 'BooleanSwitch');
    },
    gameConfigQuestionsNumberSlider() {
      if (!this.shared.gameConfig) return [];
      return this.shared.gameConfig.questions.filter(q => q.type === 'NumberSlider');
    },
    summary() {
      var chosenConfig = this.shared.configs.gameConfigs.find(c => c.code === this.shared.game.code);
      if (chosenConfig) {
        return chosenConfig.summary;
      }
      return null;
    }
  },
  watch: {},
  mounted: function () {
    this.shared.$on('config-changed', this.rememberOldAnswers);
    this.rememberOldAnswers();
  },
  beforeDestroy: function () {
    this.shared.$off('config-changed', this.rememberOldAnswers);
  },
  methods: {
    rememberOldAnswers() {
      this.oldAnswers = Object.assign({}, this.shared.configAnswers);
      if (Object.keys(this.oldAnswers)) {
        this.questionsReady = true;
      }
    },
    showRules() {
      this.shared.showGameRules = true;
    },
    configChanged() {
      this.shared.gameConfig.adjustAnswers(this.shared.configAnswers, this.oldAnswers);

      this.oldAnswers = Object.assign({}, this.shared.configAnswers);

      var path = `/configAnswers/${this.shared.gameKey}/${this.shared.game.code}`;
      firebaseDb.ref(path)
        .update(this.shared.configAnswers);
    }
  }
};
</script>

<style lang="less">
.SetupGame {
  .questions {
    margin: 1em 0;
    text-align: left;
    display: flex;
    justify-content: space-around;
    > div {
      max-width: 60%;
      .el-checkbox__label {
        white-space: normal;
        vertical-align: top;
      }
    }
    .BooleanSwitch {
      > span {
        margin-right: 0.5em;
      }
    }
  }
  .summary {
    font-style: italic;
  }
  .el-checkbox__label {
    font-size: 1rem;
  }
}
</style>
