<template>
  <div class="cardArea">
    <standard2
      v-for="c in shared.otherCardsInPlay[player.id]"
      :key="c.key"
      :card="c"
    ></standard2>
    <div
      v-if="showMeld && player.meldDisplay && player.meldDisplay.length"
      class="meldDisplay"
    >
      <div
        v-for="m in player.meldDisplay"
        :key="m.key"
      >
        <div>{{ m.d }}</div>
        <div>{{ m.p }}</div>
      </div>
    </div>
    <div
      v-if="showBidNum"
      class="bidNum"
    >
      <div>
        <div v-if="player.bid !== 'Pass'">Bid</div>
        <div>{{ player.bid }}</div>
      </div>
    </div>
    <div
      v-if="showBidReady || readyToExchange"
      class="bidNum"
    >
      <span>Ready</span>
    </div>
  </div>
</template>

<script>

import standard2 from "../cards/standard2";

export default {
  name: "CardArea",
  components: {
    standard2,
  },
  props: {
    player: {
      type: Object,// {key:'s13'}
      required: true,
    }
  },
  data: function () {
    return {
    };
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    },
    simultaneousBidding() {
      return this.shared.configAnswers.simultaneousBidding;
    },
    showBidNum() {
      return this.shared.game.state === 'bid' && this.player.bidSet && !this.simultaneousBidding
        || this.simultaneousBidding && this.shared.game.state === 'bidsSet';
    },
    showBidReady() {
      return this.shared.game.state === 'bid' && this.simultaneousBidding && this.player.bidReady;
    },
    showMeld() {
      return this.shared.game.state === 'meld';
    },
    readyToExchange() {
      return this.player.readyToExchange;
    }
  }
};
</script>