export default {
    cardsInSuites: {
        first: 1,
        last: 13
    },
    cardName(key) {
        var number = +(key.split('_')[0]).substr(1);
        if (number > 1 && number < 11) {
            return number;
        }
        switch (number) {
            case 1:
                return 'Ace';
            case 11:
                return 'Jack';
            case 12:
                return 'Queen';
            case 13:
                return 'King';
        }
        return '?';
    },
    cardDesc(key) {
        var suit = key[0];
        if (suit === 'j') return 'Joker';
        if (suit === 'w') return 'Wizard';
        if (suit === 'e') return 'Jester';
        if (key === 'back') return '(back)';
        // console.log(key)
        return `${this.cardName(key)} of ${this.suits[suit].display}`;
    },
    makeCards(config, extraKeyForSet) {
        var cards = {};
        var includeJokers = config && config.includeJokers;
        var suitArray = Object.entries(this.suits);
        suitArray.sort((a, b) => a[1].sort > b[1].sort ? 1 : -1);
        suitArray.forEach(kvp => {
            for (let i = this.cardsInSuites.first; i <= this.cardsInSuites.last; i++) {
                var key = kvp[0] + i;
                if (extraKeyForSet) {
                    key += '_' + extraKeyForSet;
                }
                cards[key] = {
                    focused: false,
                    where: null,
                    sort: Math.random()
                };
            }
        });
        if (includeJokers) {
            cards['j1' + (extraKeyForSet ? '_' + extraKeyForSet : '')] = {
                focused: false,
                where: null,
                sort: Math.random()
            };
            cards['j2' + (extraKeyForSet ? '_' + extraKeyForSet : '')] = {
                focused: false,
                where: null,
                sort: Math.random()
            };
        }
        if (config.addWizJest) {
            for (let i = 0; i < 4; i++) {
                cards['w' + i + (extraKeyForSet ? '_' + extraKeyForSet : '')] = {
                    focused: false,
                    where: null,
                    sort: Math.random()
                };
                cards['e' + i + (extraKeyForSet ? '_' + extraKeyForSet : '')] = {
                    focused: false,
                    where: null,
                    sort: Math.random()
                };
            }
        }

        return cards;
    },
    adjustOnLoad(card) {
        card.suit = card.key[0];
        card.playable = true;
        card.select = false;
        card.num = +(card.key.split('_')[0]).substr(1);
    },
    getUnicodeFor(key) {
        if (!key) return null;
        if (key === 'back') {
            return 127136;
        }
        var suit = key[0];
        var base = suit === 'j' ?
            this.jokers[key.split('_')[0]] :
            this.suits[suit];
        if (base.unicode) {
            return base.unicode;
        }
        var number = +(key.split('_')[0]).substr(1);
        if (number > 11) {
            // skip the Knight card
            number++;
        }
        return base.unicodeAce + (number - 1);
    },
    gameIsFinished(shared) {
        return !shared.canPlayAnotherRound;
    },
    determineWinningCard(shared) {
        var w = shared.allCardsInPlay.find(c => c.suit === 'w');
        if (w) {
            return w;
        }

        var sorter = this.cardSorter(shared);

        var trumpSuit = shared.game.trumpSuit;
        var noTrump = trumpSuit === 'w' || trumpSuit === 'e';

        if (!noTrump) {
            var cardsInTrump = shared.allCardsInPlay.filter(c => c.suit === trumpSuit);
            if (cardsInTrump.length) {
                if (cardsInTrump.length === 1) {
                    return cardsInTrump[0];
                }
                cardsInTrump.sort(sorter);
                return cardsInTrump[0];
            }
        }

        var firstCard = shared.firstCardPlayed;
        var suitInPlay = firstCard.suit;

        var cardsFollowingSuit = shared.allCardsInPlay.filter(c => c.suit === suitInPlay);
        if (cardsFollowingSuit.length) {
            if (cardsFollowingSuit.length === 1) {
                return cardsFollowingSuit[0];
            }
            cardsFollowingSuit.sort(sorter);
            return cardsFollowingSuit[0];
        }

        // if all are Jester, first wins
        var firstJester = shared.allCardsInPlay[0];
        if (firstJester.suit === 'e') {
            for (let i = 1; index < shared.allCardsInPlay.length; index++) {
                if (shared.allCardsInPlay[i].suit !== 'e') {
                    firstJester = null;
                }
            }
        }
        if (firstJester) {
            return firstJester;
        }

        // none in trump, none in suit
        return firstCard;
    },
    afterCardsChange(host) {
        // flag unplayable cards
        if (!host.allCardsInPlay.length) {
            return;
        }
        var firstCard = host.firstCardPlayed;
        var suitInPlay = firstCard.suit;

        var inHand = host.myCards.filter(c => !c.where);

        if (suitInPlay === 'w' || suitInPlay === 'e') {
            // no suit
            return;
        }

        var inSuit = inHand.filter(c => c.suit === suitInPlay);
        if (!inSuit.length) {
            return;
        }

        var alreadyPlayed = !!host.allCardsInPlay.find(c => c.who === host.me.id);

        var playableCards = [];
        inHand.forEach(c => {
            if (alreadyPlayed) {
                c.playable = true;
            } else {
                c.playable = c.suit === suitInPlay ||
                    c.suit === 'w' ||
                    c.suit === 'e';
            }
            if (c.playable) {
                playableCards.push(c);
            }
        });

        // if (playableCards.length === 1) {
        //     playableCards[0].focused = true;
        // }
    },
    cardSorter(shared) {
        if (!shared) {
            return (a, b) => {};
        }
        var config = shared.gameConfig;
        var answers = shared.configAnswers
        var trumpSuit = shared.game.trumpSuit;

        var suits = this.suits;
        var aceIsHigh = config.aceIsHigh;

        // adjust order
        var offset = 0;
        if (trumpSuit && trumpSuit !== 'e' && trumpSuit !== 'w') {
            var oldTrumpSort = suits[trumpSuit].sort;
            offset = 1 - oldTrumpSort;
        }
        // debugger;
        var suitOrder = {};
        Object.keys(suits).forEach(k => {
            var newSort = suits[k].sort + offset;
            if (newSort < 1) {
                newSort = newSort + 4;
            }
            suitOrder[k] = newSort; // negative
        });

        return (a, b) => {
            // console.log(a.suit, b.suit, a, b)
            if (answers.addWizJest) {
                if (a.suit === 'w') return -1;
                if (b.suit === 'w') return 1;
                if (a.suit === 'e') return b.suit === 'w' ? 1 : -1;
                if (b.suit === 'e') return a.suit === 'w' ? -1 : 1;
            }

            if (a.suit === b.suit) {
                var aNum = aceIsHigh && a.num === 1 ? 15 : a.num;
                var bNum = aceIsHigh && b.num === 1 ? 15 : b.num;

                // console.log(a.key, a.num, b.key, b.num);
                return aNum < bNum ? 1 : -1;
            }
            return suitOrder[a.suit] > suitOrder[b.suit] ? 1 : -1;
        }
    },
    suits: {
        //sort as per https://en.wikipedia.org/wiki/High_card_by_suit

        d: {
            unicodeAce: 127169,
            code: 'diams',
            display: 'Diamonds',
            class: 'red',
            sort: 1,
            symbol: '♦'
        },
        c: {
            unicodeAce: 127185,
            code: 'clubs',
            display: 'Clubs',
            class: 'black',
            sort: 2,
            symbol: '♣'
        },
        h: {
            unicodeAce: 127153,
            code: 'hearts',
            display: 'Hearts',
            class: 'red',
            sort: 3,
            symbol: '♥'
        },
        s: {
            unicodeAce: 127137,
            code: 'spades',
            display: 'Spades',
            class: 'black',
            sort: 4,
            symbol: '♠'
        },
    },
    jokers: {
        j1: {
            unicode: 127167,
            display: 'Joker',
            class: 'red'
        },
        j2: {
            unicode: 127167,
            display: 'Joker',
            class: 'black'
        }
    }
}