<template>
  <div
    class="CardHolder Standard2"
    :class="classList"
    :title="desc"
    v-on:click="clicked"
  >
    <img
      class="Card"
      :src="require('@/assets/standard2/' + baseKey + ext)"
    >
    <!-- <div
      class="Back"
      v-html="backHtml"
    ></div> -->
  </div>
</template>

<script>


export default {
  name: "Card",
  props: {
    card: {
      type: Object,// {key:'s13'}
      required: true,
    },
    size: String
  },
  data: function () {
    return {
    };
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    },
    deckType() {
      return this.shared.configs.deckType.standard;
    },
    ext() {
      return '.png';
      // return this.baseKey === 'c1' ? '.svg' : '.png';
    },
    suitKey() {
      // could be 'j' for joker
      return this.baseKey[0] || '';
    },
    cardName() {
      return this.deckType.cardName(this.baseKey);
    },
    desc() {
      return this.deckType.cardDesc(this.baseKey)
        + (this.card.focused ? '\n(Selected)' : '');
    },
    suit() {
      if (this.baseKey === 'back') { return {}; }
      if (this.suitKey === 'j') {
        return this.deckType.jokers[this.baseKey];
      }
      if (this.suitKey === 'w' || this.suitKey === 'e') {
        return {};
      }
      return this.deckType.suits[this.suitKey];
    },
    baseKey() {
      if (!this.card.key) {
        return '';
      }
      // if (this.card.faceDown) {
      //   return 'back';
      // }
      var key = this.card.key.split('_')[0];

      if (key[0] === 'w' || key[0] === 'e') {
        return key[0];
      }
      return key;
    },
    number() {
      return +this.baseKey.substr(1);
    },
    color() {
      return this.suit ? this.suit.class : null;
    },
    classList() {
      var list = [];

      if (this.shared.isMember) {
        if (this.card.focused || this.card.newCard) {
          list.push('focused')
        }

        if (this.card.select) {
          list.push('select')
        }
      }

      if (this.card.tookTrick) {
        list.push('tookTrick')
      }

      if (this.size !== 'small') {
        if (!this.card.playable) {
          list.push('notPlayable');
        } else if (this.card.where === 'inPlay') {
          // neither
        } else {
          list.push('playable');
        }
      }

      list.push('suit_' + this.card.suit);

      list.push(this.size);

      return list.join(' ');
    }
  },
  mounted: function () { },
  methods: {
    clicked() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="less">
.CardHolder.Standard2 {
  display: inline-block;
  overflow: visible;
  user-select: none;

  .Card {
    height: calc(95vh * 0.2);
    width: calc(95vh * 0.1429);
    //height: calc(312px / 2); // 312
    //width: calc(223px / 2); // 223
    border: 1px solid #666;
    border-radius: 5px;
    box-shadow: 0 0 0 1px #c0c4cc;
  }

  // &.small {
  // }

  &.focused {
    margin-top: -20px;
    transition: all 0.1s;
    flex-shrink: 0;

    .Card {
      box-shadow: 1px 1px 5px 0px #a0a4ac;
    }
  }

  &.select {
    flex-shrink: 0;

    .Card {
      border: 1px solid black;
    }
  }
}
</style>
