export default {
    makeCards() {
        var cards = {};
        var colors = Object.entries(this.colors);
        colors.sort((a, b) => a[1].sort > b[1].sort ? 1 : -1);
        colors.filter(kvp => !kvp[1].wild).forEach(kvp => {
            for (let i = 0; i <= 9; i++) {
                var key = kvp[0] + i;
                cards[key] = {
                    name: i,
                    short: i,
                    num: i
                };
                if (i !== 0) {
                    cards[key + '_2'] = {
                        name: i,
                        short: i,
                        num: i
                    };
                }
            }
            var c = kvp[0];
            cards[`${c}d2`] = {
                name: "Draw 2",
                short: "+2"
            };
            cards[`${c}d2_2`] = {
                name: "Draw 2",
                short: "+2"
            };
            cards[`${c}s`] = {
                name: "Skip",
                short: "S"
            };
            cards[`${c}s_2`] = {
                name: "Skip",
                short: "S"
            };
            cards[`${c}r`] = {
                name: "Reverse",
                short: "R"
            };
            cards[`${c}r_2`] = {
                name: "Reverse",
                short: "R"
            };
        })

        cards[`w_1`] = {
            name: "Wild",
            short: "W"
        };
        cards[`w_2`] = {
            name: "Wild",
            short: "W"
        };
        cards[`w_3`] = {
            name: "Wild",
            short: "W"
        };
        cards[`w_4`] = {
            name: "Wild",
            short: "W"
        };

        cards[`w4_1`] = {
            name: "Draw 4",
            short: "+4"
        };

        cards[`w4_2`] = {
            name: "Draw 4",
            short: "+4"
        };

        cards[`w4_3`] = {
            name: "Draw 4",
            short: "+4"
        };

        cards[`w4_4`] = {
            name: "Draw 4",
            short: "+4"
        };

        Object.entries(cards).forEach(c => {
            c.faceDown = true;
            c.focus = false;
        })

        return cards;
    },
    cardSorter() {
        var colors = this.colors;
        return (a, b) => {
            if (a.color === b.color) {
                return a.short < b.short ? 1 : -1;
            }
            return colors[a.color].sort > colors[b.color].sort ? 1 : -1;
        }
    },
    adjustOnLoad(card) {
        card.color = card.key[0];
        card.playable = true;
        card.focused = false;
    },
    colors: {

        r: {
            display: 'Red',
            class: 'red',
            sort: 1,
        },

        y: {
            display: 'Yellow',
            class: 'yellow',
            sort: 2,
        },

        g: {
            display: 'Green',
            class: 'green',
            sort: 3,
        },

        b: {
            display: 'Blue',
            class: 'blue',
            sort: 4,
        },

        w: {
            display: 'Wild',
            class: 'black',
            wild: true,
            sort: 5,
        },

    },
}