<template>
  <div class="MemberStatus">
    <div class="top">
      <div class="buttonsL">
        <!-- dealer -->
        <div v-if="!me.active">
          Viewing
        </div>
        <div
          v-if="isDealer && $route.name === 'home'"
          class="dealerBtns"
        >
          <span>Dealer</span>

          <template v-if="shared.cardsInUse">
            <el-popconfirm
              title="Are you sure you want to redeal all the cards and restart this round?"
              confirm-button-text="Yes, Redeal now"
              cancel-button-text="No, Cancel"
              v-on:confirm="makeDeck"
            >
              <button
                slot="reference"
                class="caution"
              >Redeal...</button>
            </el-popconfirm>
          </template>

          <button
            v-if="isDealer && shared.game.roundFinished && !shared.personWhoTookTrick.id && shared.canPlayAnotherRound"
            v-on:click="startNextRound"
          >Start Next Round</button>

          <template v-if="canRestartBidding">
            <el-popconfirm
              title="Are you sure you want to restart the bidding for this round?"
              confirm-button-text="Yes, Restart bidding"
              cancel-button-text="No, Cancel"
              v-on:confirm="restartBidding"
            >
              <button
                slot="reference"
                class="caution"
              >Rebid...</button>
            </el-popconfirm>
          </template>

          <template v-if="canRestartMeld">
            <el-popconfirm
              title="Are you sure you want to restart the meld phase?"
              confirm-button-text="Yes, Restart meld"
              cancel-button-text="No, Cancel"
              v-on:confirm="restartMelding"
            >
              <button
                slot="reference"
                class="caution"
              >Meld again...</button>
            </el-popconfirm>
          </template>

          <template v-if="shared.allCardsInPlay.length">
            <el-popconfirm
              title="Are you sure you want to clear the table and restart this trick?"
              confirm-button-text="Yes, Restart trick"
              cancel-button-text="No, Cancel"
              v-on:confirm="restartTrick"
            >
              <button
                slot="reference"
                class="caution"
              >Restart Trick...</button>
            </el-popconfirm>
          </template>

          <button
            v-if="(!shared.allCardsInPlay.length && shared.tricks.length)"
            v-on:click="showLastTrick"
          >View Last Trick</button>
        </div>
      </div>
      <div class="people">
        <div
          v-for="m in players"
          :key="m.id"
          :title="(m.isAdmin ? 'Administrator. ' : '') + (m.id === me.id ? 'Make my name blink on all screens' : '')"
          :class="{connected: m.connected, 
                   activeMember: m.active,
                   inactiveMember: !m.active,
                   highlight: m.highlight, 
                   isAdmin: m.isAdmin, 
                   isDealer: m.id === shared.game.dealer,
                   isMe: m.id === me.id,
                   isNext: m.id === shared.game.nextPlayer,
                   participating: m.participating}"
          v-on:click="clicked(m)"
        >{{ m.name }}</div>
        <div
          v-for="m in shared.guests"
          :key="m.id"
          :title="(m.id === me.id ? 'Make my name blink on all screens' : '')"
          :class="{connected: m.connected, 
                   highlight: m.highlight, 
                   guest: true,
                   isMe: m.id === me.id
          }"
          v-on:click="clicked(m)"
        >{{ m.name }}</div>
      </div>
      <div class="buttonsR">
        <!-- 
        <div class="inactives">
          <div
            v-for="m in inactiveMembers"
            :key="m.id"
            :title="(m.isAdmin ? 'Administrator. ' : '') + (m.id === me.id ? 'Make my name blink on all screens' : '')"
            :class="{connected: m.connected, 
                     highlight: m.highlight, 
                     isAdmin: m.isAdmin, 
                     isDealer: m.id === shared.game.dealer,
                     isMe: m.id === me.id,
                     participating: m.participating}"
            v-on:click="clicked(m)"
          >{{ m.name }}</div>
        </div> -->

        <el-button
          type="success"
          icon="el-icon-info"
          title="Rules of the Game"
          circle
          v-on:click="showRules"
        ></el-button>

        <el-button
          type="success"
          icon="el-icon-tickets"
          title="View the Score"
          circle
          v-on:click="showScores"
        ></el-button>

      </div>

    </div>
  </div>
</template>

<script>

import firebaseDb from "../firebaseInit";
import * as moment from "moment/moment";

export default {
  name: "MemberStatus",
  components: {
  },
  data: function () {
    return {
    };
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    },
    // onGamePage() {
    //   return this.$route.name === 'home';
    // },
    isDealer() {
      return this.me.id && this.me.id === this.shared.game.dealer;
    },
    me() {
      return this.shared.me;
    },
    canRestartMeld() {
      // if no tricks completed
      return this.shared.gameConfig.useMeld
        && this.shared.game.state === 'play'
        && !this.shared.tricks.length;
    },
    canRestartBidding() {
      var nextPlayer = this.shared.getNextPlayer(true);
      if (!nextPlayer) return false;

      return !this.shared.numCardsPlayed && nextPlayer.bidSet;
    },
    // inactiveMembers: function () {
    //   return this.shared.people.filter(m => !m.active);
    // },
    players: function () {
      return this.shared.people.filter(m => !m.viewOnly);
    },
  },
  watch: {
    'me.name': function () {
      this.updateDocTitle();
    },
    isDealer: function () {
      this.updateDocTitle();
    },
    'me.active': function () {
      this.updateDocTitle();
    },
    'shared.game.nextPlayer': function () {
      this.updateDocTitle();
    }
  },
  mounted: function () {
    this.updateDocTitle();
  },
  beforeDestroy: function () {
  },

  methods: {

    updateDocTitle() {
      if ((!isNaN(window.location.hostname.split('.').join('')) || window.location.hostname.split('.').length === 1) && this.me.id) {
        // in dev, show title
        var title = [
        ];
        title.push(this.me.name)

        if (this.me.id === this.shared.game.nextPlayer) {
          title.push('(Next)');
        }
        if (this.isDealer) {
          title.push('(Dealer)');
        }
        if (this.me.isAdmin) {
          title.push('(Admin)');
        }
        if (!this.me.active) {
          title.push('(Viewer)');
        }
        window.document.title = title.join(' ');
      } else {
        window.document.title = 'Our Card Game'
      }
    },
    showRules() {
      this.shared.$emit('showRules');
    },
    showScores() {
      this.shared.$emit('showScores');
    },

    startNextRound() {
      this.shared.$emit('startNextRound');
    },
    makeDeck() {
      this.shared.$emit('makeDeck');
    },
    restartTrick() {
      this.shared.$emit('restartTrick');
    },
    showLastTrick() {
      this.shared.$emit('showLastTrick');
    },
    restartBidding() {
      this.shared.$emit('restartBidding');
    },
    restartMelding() {
      this.shared.$emit('restartMelding');
    },
    testNow: function () {
      var vue = this;

      var id = this.me.id;
      var groupType = id[0] === 'm' ? 'people' : 'guests';

      var ref = firebaseDb.ref(`/${groupType}/${this.shared.gameKey}/${id}`);

      ref.update({
        connected: this.shared.firebaseRawAuthUser.uid,
        connectedTime: moment().toISOString(),
        highlight: true
      });

      setTimeout(function () {
        ref.update({
          highlight: false
        });
      }, 2000);
    },
    clicked: function (person) {
      if (this.me.id !== person.id) {
        return;
      }
      this.testNow();
    }
  }
};
</script>

<style lang="less">
.MemberStatus {
  flex-shrink: 0;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2em;
    border-top: 1px solid #5d6560;
    border-bottom: 1px solid #5d6560;
    background-color: #d2d2d2;
    padding: 3px 5px;

    .buttonsL {
      text-align: left;
      .dealerBtns {
        display: flex;
        align-items: center;
      }
    }

    .buttonsR {
      white-space: nowrap;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .people {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
    }

    .guest {
      display: block;
      margin: 0 3px;
      font-style: italic;
      padding: 0px 5px 0px 4px;
      font-size: 90%;
      border-radius: 2px;
      user-select: all;
      position: relative;
      align-self: center;

      background-color: #e1dfc2;

      &.connected {
        background-color: #f0eab1;
      }

      &.connected.highlight {
        animation: pulseGuest 0.3s infinite;
      }

      &.isMe {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        cursor: pointer;
        user-select: none;
      }
    }

    .activeMember,
    .inactiveMember {
      display: block;
      margin: 3px 10px;
      padding: 1px 3px;
      border-radius: 2px;
      user-select: all;
      position: relative;
      border: 2px solid transparent;

      &.connected.highlight {
        // include more selectors to take priority
        animation: pulse 0.3s infinite;
        //user-select: unset;
      }

      &.isMe {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        cursor: pointer;
        user-select: none;
      }
    }

    .inactiveMember {
      //background-color: #e9e9e9;
      background-color: rgba(100, 100, 100, 0.1);

      &.connected {
        //background-color: #e8fae8;
        background-color: #cbe6b9;
      }
    }

    .activeMember {
      background-color: rgba(100, 100, 100, 0.1);
      border-color: #aaa;

      &.participating {
        background-color: #efc3c3; // participating, but not connected!
      }

      &.connected {
        background-color: #9fef93;
      }

      &.isNext {
        border-color: #de2626;
      }
    }

    .activeMember + .guest {
      margin-left: 30px;
    }

    .isAdmin:after {
      content: "A"; // for Admin
      position: absolute;
      bottom: -5px;
      right: -8px;
      font-size: 60%;
    }

    .isDealer:after {
      content: "D"; // for Admin
      position: absolute;
      bottom: -5px;
      right: -8px;
      font-size: 60%;
    }
    .isAdmin.isDealer:after {
      content: "D A"; // for Admin
      position: absolute;
      bottom: -5px;
      right: -8px;
      font-size: 60%;
    }

    @keyframes pulse {
      0% {
        background-color: #fff;
      }
      50% {
        background-color: #4a993e;
      }
    }
    @keyframes pulseGuest {
      0% {
        background-color: #fff;
      }
      50% {
        background-color: #f0eab1;
      }
    }
  }
}
</style>
