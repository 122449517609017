<template>
  <el-dialog
    class="ScoreSheet"
    title="Score Sheet"
    :visible="visible"
    fullscreen
    :before-close="handleClose"
    v-on:opened="opened"
  >
    <div
      id="body"
      tabindex="1"
    >

      <table v-if="dataRows.length">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th v-if="showNumCards"></th>
            <th
              v-if="showTotalBids"
              class="bottom"
              rowspan="2"
            >
              Total<br>
              Bids
            </th>
            <th
              v-for="p in people"
              :key="p.num"
              :class="{rank1: p.rank === 1}"
              class="name"
              colspan="4"
            >{{ p.name }}</th>
          </tr>
          <tr>
            <th>#</th>
            <th>Suit</th>
            <th v-if="showNumCards">Cards</th>
            <template v-for="p in people">
              <th
                :key="'b'+p.num"
                class="bid"
              >Bid</th>
              <th
                :key="'g'+p.num"
                class="got"
              >Won</th>
              <th
                :key="'p'+p.num"
                class="got"
              >Pts</th>
              <th
                :key="'s'+p.num"
                class="score"
              >Score</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(r,j) in dataRows"
            :key="'r'+j"
          >
            <th>{{ r.id }}</th>
            <th class="suit">
              <img
                v-if="logoSymbolUrl(r.suit)"
                class="logoSymbolUrl"
                :class="logoSymbolClass(r.suit)"
                :src="logoSymbolUrl(r.suit)"
              >
              <span v-else>-</span>

            </th>
            <th v-if="showNumCards">{{ r.numCards || '' }}</th>
            <th v-if="showTotalBids">{{ r.bidsOverUnder }}</th>
            <template v-for="p in people">
              <td
                v-if="r[p.num].text"
                :key="'b'+p.num"
                :class="r[p.num].state"
                colspan="3"
                class="bid"
                v-text="r[p.num].text"
              ></td>
              <td
                v-if="!r[p.num].text"
                :key="'b'+p.num"
                :class="r[p.num].state"
                class="bid"
                v-text="r[p.num].bid"
              ></td>
              <td
                v-if="!r[p.num].text"
                :key="'g'+p.num"
                :class="r[p.num].state"
                class="got"
              >
                {{ r[p.num].taken }}
              </td>
              <td
                v-if="!r[p.num].text"
                :key="'pt'+p.num"
                class="got"
                :class="r[p.num].state"
                v-text="(r[p.num].pts > 0 ? '+' : '') + (r[p.num].pts || '')"
              ></td>
              <td
                :key="'sc'+p.num"
                class="score"
                :class="r[p.num].top ? 'topScore' : ''"
                v-text="r[p.num].score"
              ></td>
            </template>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th
              :colspan="2 + (showNumCards ? 1 : 0) + (showTotalBids ? 1 : 0)"
              class="rank"
            >Rank</th>
            <template v-for="p in people">
              <td
                :key="'bl'+p.num"
                :class="{rank1: p.rank === 1}"
                class="rankLeftSide"
                colspan="3"
              >
                <div v-if="p.tied">Tied</div>
              </td>
              <td
                :key="'r' + p.num"
                :class="{rank1: p.rank === 1}"
                class="rank"
              >#{{ p.rank }}
              </td>
            </template>
          </tr>
          <!-- <tr>
            <th
              :colspan="2 + (showNumCards ? 1 : 0) + (showTotalBids ? 1 : 0)"
              class="rank"
            >Rank %</th>
            <template v-for="p in people">
              <td
                :key="'b2'+p.num"
                :class="{rank1: p.rank === 1}"
                class="rankLeftSide"
                colspan="3"
              >
              </td>
              <td
                :key="'r2' + p.num"
                :class="{rank1: p.rank === 1}"
                class="rank"
              >{{ p.pct }}%
              </td>
            </template>
          </tr> -->
          <tr v-if="showExtraFooter">
            <th></th>
            <th></th>
            <th v-if="showNumCards"></th>
            <th v-if="showTotalBids"></th>
            <th
              v-for="p in people"
              :key="p.num"
              :class="{rank1: p.rank === 1}"
              class="name"
              colspan="4"
            >{{ p.name }}</th>
          </tr>
        </tfoot>
      </table>
      <div
        v-else
        class="NoScores"
      >
        First round not completed yet!
      </div>
      <div class="rules">
        <div v-if="shared.game.code==='estimation'">
          <h2>Scoring for Estimation</h2>
          <ul>
            <li>Each person who matched their bid will get the number of tricks they won + the number of cards for that round.</li>
            <li>Others will lose the difference between their bid and the number of tricks they won.</li>
          </ul>
        </div>
        <div v-if="shared.game.code==='wiz'">
          <h2>Scoring for Wiz</h2>
          <ul>
            <li>Each person who matched their bid will get 2 plus number of tricks they won.</li>
            <li>Others will lose the difference between their bid and the number of tricks they won.</li>
          </ul>
        </div>
      </div>
      <div
        slot="footer"
        class="dialogFooter"
      >
        <el-button v-on:click="handleClose">Close</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>


export default {
  name: "ScoreSheet",
  props: {
    visible: Boolean
  },
  data: function () {
    return {
      people: [],
      dataRows: []
    };
  },
  computed: {
    shared() {
      return this.$root.shared;
    },
    showExtraFooter() {
      return this.numDataRows > 8; // for long lists
    },
    // people() {
    //   return this.shared.scoreSheet.people || [];
    // },
    numDataRows() {
      return this.dataRows.length;
    },
    showTotalBids() {
      return true;
    },
    showNumCards() {
      // see if numCards changes at all
      // should be false if numCards is null
      var changeFound = false;
      var lastNum = 0;
      for (let i = 0; i < this.dataRows.length && !changeFound; i++) {
        const r = this.dataRows[i];
        if (lastNum === 0) {
          lastNum = r.numCards;
        }
        if (lastNum !== r.numCards && r.numCards !== r.id) {
          changeFound = true;
        }
      }
      return changeFound;
    }
  },
  watch: {
    'shared.people.length': function () {
      this.buildScoreTable();
    },
    'shared.rounds': function () {
      this.buildScoreTable();
    },
  },

  mounted() {
    this.buildScoreTable();

    window.addEventListener('keyup', this.keyPressed);
  },
  beforeDestroy: function () {
    window.removeEventListener('keyup', this.keyPressed);
  },

  methods: {
    keyPressed(ev) {
      if (!this.visible) return;

      switch (ev.key) {
        case 'Enter':
          this.handleClose();
          break;
      }

    },
    opened() {
      this.$el.querySelector('#body').focus({ preventScroll: true });
    },
    logoSymbolClass(suitKey) {
      return 'logo-' + suitKey;
    },
    logoSymbolUrl(suitKey) {
      if (suitKey === 'e' || suitKey === 'w') return '';
      return require(`@/assets/standard2/suit-${suitKey}-s.png`);
    },

    showSuit(key) {
      var suit = this.shared.deckType.suits ? this.shared.deckType.suits[key] : null;
      if (!suit) return key;

      return suit.symbol;
    },
    getSuitColor(key) {
      var suit = this.shared.deckType.suits ? this.shared.deckType.suits[key] : null;
      if (!suit) return key;

      return suit.class;
    },
    handleClose() {
      this.$emit('closeMe');
    },
    buildScoreTable() {
      var people = this.people = this.shared.people.slice();
      people.forEach((p, i) => {
        p.num = i;
        p.score = 0;
        p.tied = false;
        p.top = false;
      });
      people.sort((a, b) => a.name < b.name ? -1 : 1);

      this.dataRows = [];
      // debugger;
      this.shared.rounds.forEach(round => {
        var row = {
          id: round.id,
          suit: round.suit,
          numCards: round.numCards, // may be null for some games
        };

        var totalBids = 0;

        people.forEach(p => {
          var pInfo = round.players[p.id];
          // bid,score,taken
          if (!pInfo) {
            row[p.num] = {
              text: '-',
              score: p.score,
              state: 'viewer',
            }
          } else {
            p.score += pInfo.score;
            var ok = pInfo.bid === pInfo.taken;
            totalBids += pInfo.bid;
            row[p.num] = {
              bid: pInfo.bid,
              taken: pInfo.taken,
              pts: pInfo.score,
              score: p.score,
              state: ok ? 'ok' : 'missed'
            }
          }
        });

        row.totalBids = totalBids;

        var delta = (totalBids - (round.numCards || 13));
        row.bidsOverUnder = (delta <= 0 ? '' : '+') + delta;

        // find first in this row
        var isFirst = [];
        var topScore = 0;
        people.forEach(p => { if (p.score > topScore) topScore = p.score; });
        people.forEach(p => { row[p.num].top = p.score === topScore; })

        this.dataRows.push(row);
      });

      // determine ranking, look for ties too
      var scores = [];
      var topScore = 0;
      people.forEach(p => {
        if (scores.indexOf(p.score) === -1) {
          scores.push(p.score);
        }
        if (p.score > topScore) topScore = p.score;
      });

      scores.sort((a, b) => a < b ? 1 : -1); // reverse

      var rank = {};
      people.forEach(p => {
        p.rank = 1 + scores.indexOf(p.score);
        if (topScore) {
          p.pct = Math.floor(p.score / topScore * 100);
        }
        if (!rank[p.rank]) {
          rank[p.rank] = [];
        }
        rank[p.rank].push(p);
      });

      Object.keys(rank).forEach(r => {
        if (rank[r].length > 1) {
          rank[r].forEach(p => {
            p.tied = true;
          })
        }
      })
    },
  }
};
</script>

<style lang="less">
.ScoreSheet {
  .dialogFooter {
    text-align: right;
  }
  .NoScores {
    font-size: 1.1em;
    margin: 2em auto;
    text-align: center;
  }

  table {
    border-collapse: collapse;
    margin: 0 auto;
  }

  td,
  th {
    padding: 5px 3px;
    text-align: center;
    white-space: nowrap;
  }
  th.bottom {
    vertical-align: bottom;
  }
  th.suit {
    padding: 3px 0 0 3px;
    img {
      height: 18px;
    }
  }

  .bid {
    border-left: 1px solid black;
  }
  .rank {
    border-right: 1px solid black;
  }

  .rank1 {
    background-color: #c1fcc1;
  }

  .bid,
  .got {
    &.ok {
      background-color: #e1ffe1;
    }
    &.viewer {
      background-color: #fff;
    }
    &.missed {
      background-color: #ffebeb;
    }
  }

  .red {
    color: #de2626;
  }
  .black {
    color: black;
  }

  .score {
    border-left: 1px solid #aaa;
    border-right: 1px solid black;
    &.played {
      background-color: #f9f9f9;
    }
    &.topScore {
      background-color: #c1fcc1;
    }
  }

  .name {
    border: 1px solid black;
  }

  tbody {
    tr {
      border-top: 1px solid grey;
    }
  }

  tfoot {
    tr:first-child {
      border-top: 3px double #000;
    }
    td,
    th {
      padding: 2px 3px;
    }
    .rankLeftSide {
      text-align: right;
    }
  }

  .rules {
    margin: 2em auto 0;
    width: 39em;
    text-align: left;
  }
  // tbody {
  //   tr:nth-child(even) {
  //     background-color: #f7f7f7;
  //   }
  // }
}
</style>
