<template>
  <div class="FAQ">
    <div class="panel">
      <button
        class="goBack"
        v-on:click="goBack"
      >
        Go Back
      </button>
      <h1>Our Card Game</h1>
      <h2>Questions and Answers</h2>
      <ol>
        <li>
          <span>What games can we play with it?</span>
          <blockquote>
            "Our Card Game" is designed to be generic and allow you to play almost any card game. However, there are too many
            variations of card games to support them all!
          </blockquote>
          <blockquote>
            Here are the games that are implemented so far:
            <ul>
              <li><strong>Estimation</strong> - in each round, estimate how many of the 13 tricks you will win, then try to win exactly that number.</li>
              <li><strong>Wiz</strong> - similar to Estimation, but starting with 1 card in the first round and with Wizard and Jester cards. Also known as "Wizard".</li>
              <li><strong>Pinochle</strong> - the classic American card game.</li>
            </ul>

          </blockquote>
        </li>
        <li>
          <span>How much do I have to pay to use it?</span>
          <blockquote>
            This is a personal project made for our own use, and we've added some of our favourite games.
            As of June 2020, it is all free for you to use too!
          </blockquote>
        </li>
        <li>
          <span>What technology do we need to use it?</span>
          <blockquote>
            Every player must have their own direct access to a web browser connected to the Internet.
            This can be any current web browser running on a Windows or Linux laptop or computer. It will also
            work on most iPhone, iPad, Android phone or tablet, MacBook, or Chromebook, but test it first!
          </blockquote>
          <blockquote>
            An important aspect of playing Our Card Game is that all the players should also be connected by a
            video (or audio only) call. You can set that up in your favorite video conferencing system. If you
            don't have a favorite, the system will create a free and easy-to-use room at Jitsi for your game.
          </blockquote>
        </li>
        <li>
          <span>How do other player join the game that I create?</span>
          <blockquote>
            Your game is private to you and your friends. Once you, the administrator of the game, have created
            it, you will be given a new private web address to share with the others. You need to share this with
            them in whatever way you typically share information, such as email or WhatsApp or a shared document.
            (Reading the address over the phone is not recommended because the private address is cryptic and difficult to read!)
          </blockquote>
          <blockquote>
            No passwords or login accounts are used. Everyone playing your game simply accesses the site using same web address. When
            they connect to that web address, they will be asked to "claim" the spot by their name. If someone claims the wrong name
            they can change to claim the correct name. You need to ensure that everyone is satisfied that they
            have their own name.
          </blockquote>
        </li>
        <li>
          <span>How many players can there be?</span>
          <blockquote>
            Most games support four people. For "Estimation", 4 players actively play but you can have 6 players and they will rotate after each round.
            For "Wiz", 3 to 6 players can actively play at the same time. Pinochle is for 4 players.
          </blockquote>
        </li>
        <li>
          <span>Can someone just watch?</span>
          <blockquote>
            Yes, you can also invite guests to "watch" the game.
            And if the Admin activates the option to "show cards to non-players", they can see the cards in the hand of all the
            players and explore how each player thinks and what strategies are used to win or make the other players lose!
          </blockquote>
        </li>
        <li>
          <span>Can I let someone else be the administrator?</span>
          <blockquote>
            Yes. Whoever creates the game is automatically made to be an administrator of the game. However, you can
            make any number of other members to also be administrators. There must always be at least one administrator.
            The administrator is responsible to enter the member's names, configure the game settings, to control the rounds,
            and finally, to erase the game when you are done.
          </blockquote>
        </li>
        <li>
          <span>Can the system email me the results?</span>
          <blockquote>
            No. The system does not have your email address and knows nothing about you. The game will remain in the cloud (for days or months) until you delete it.
          </blockquote>
          <blockquote>
            You can copy the Score Sheet and paste it into an email or Excel to share (or gloat in your victory!).
          </blockquote>
        </li>
        <li>
          <span>How can I test the system by myself?</span>
          <blockquote>
            For testing, you can open a second tab as "incognito" or "in private" in most computer or phone
            browsers and connect into your game using the secret address. If your computer or phone has multiple types of web browsers,
            such as Chrome, Firefox, Safari and/or Edge, you can open one or two tabs in each of them to test multiple log-ins.
          </blockquote>
          <blockquote>
            You can erase the game and make a new one as many times as you wish. When you are done testing, create
            a new game.
          </blockquote>
        </li>
        <li>
          <span>Where did you get the sounds?</span>
          <blockquote>
            The sound effects were obtained from https://www.zapsplat.com.
          </blockquote>
        </li>
        <li>
          <span>Who made Our Card Game?</span>
          <blockquote>
            The system was created by Glen Little, with conceptual design and specifications by Fadi Nasr,
            coordination by Al Dhalla, and sponsorship from Sunwapta Solutions Inc.
            The final version was modified and improved from feedback received by early beta testers and by
            people like you! If you have suggestions for how to make it better,
            please feel free to send them to Glen by email at glen.little@gmail.com, or Fadi at fjnasr@gmail.com.
          </blockquote>
          <blockquote>You can also find us on Facebook at
            <a
              target="fb"
              href="https://www.facebook.com/ourcardgame/"
            >Our Card Game</a> </blockquote>
          <blockquote>If you have another question that has not been answered here, please send Glen an email!</blockquote>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>


export default {
  name: "FAQ",
  data: function () {
    return {};
  },
  computed: {
    shared: function () {
      return this.$root.shared;
    },
    version: function () {
      return _version;
    }
  },
  watch: {},
  methods: {
    goBack: function () {
      history.go(-1); // not sure why -1 doesn't work
    }
  }
};
</script>

<style lang="less">
.FAQ {
  li {
    > span {
      font-weight: bold;
    }
  }
  button {
    font-family: "Acme", sans-serif;
  }
  .goBack {
    float: right;
  }
}
</style>
