<template>
  <el-dialog
    class="ScoreSheet_Pinochle"
    title="Score Sheet"
    :visible="visible"
    fullscreen
    :before-close="handleClose"
    v-on:opened="opened"
  >
    <div id="body" tabindex="1">
      <div v-if="dataRows.length">
        <table class="scores">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th
                v-for="t in teams"
                :key="t.num"
                :class="{ rank1: t.rank === 1 }"
                class="name"
                colspan="5"
              >
                {{ t.name }}
              </th>
            </tr>
            <tr>
              <th>#</th>
              <th>Bid Winner</th>
              <th class="bid">Bid</th>
              <template v-for="t in teams">
                <th :key="'b' + t.num" class="info2">Tricks</th>
                <th :key="'a' + t.num" class="info">Meld</th>
                <th :key="'c' + t.num" class="info">Pulled</th>
                <th :key="'d' + t.num" class="scoreRound">Total</th>
                <th :key="'e' + t.num" class="score">Score</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(r, j) in dataRows"
              :key="'r' + j"
              :class="{
                failed: r.failed,
                lastTrick: r.lastTrick,
              }"
            >
              <th>{{ r.id }}</th>
              <!-- <th class="suit">
              <img
                v-if="logoSymbolUrl(r.suit)"
                class="logoSymbolUrl"
                :class="logoSymbolClass(r.suit)"
                :src="logoSymbolUrl(r.suit)"
              >
              <span v-else>-</span>
            </th> -->
              <th>{{ r.bidWinnerName }}</th>
              <th class="bid">{{ r.bid }}</th>
              <template v-for="t in teams">
                <td
                  :key="'b' + t.num"
                  class="info2"
                  v-html="r.teamsInRow[t.num].taken"
                ></td>
                <td
                  :key="'a' + t.num"
                  class="info"
                  v-html="r.teamsInRow[t.num].meld"
                ></td>

                <td
                  :key="'c' + t.num"
                  class="info"
                  v-html="r.teamsInRow[t.num].points"
                ></td>
                <td
                  :key="'d' + t.num"
                  class="scoreRound"
                  :class="{
                    failed: r.teamsInRow[t.num].failed,
                    bidWinner: r.teamsInRow[t.num].bidWinner,
                  }"
                >
                  {{ r.teamsInRow[t.num].score }}
                </td>
                <td
                  :key="'sc' + t.num"
                  class="score"
                  :class="r.teamsInRow[t.num].top ? 'topScore' : ''"
                  v-text="r.teamsInRow[t.num].runningTotal"
                ></td>
              </template>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th
                :colspan="3 + (showNumCards ? 1 : 0) + (showTotalBids ? 1 : 0)"
                class="rank"
              ></th>
              <template v-for="t in teams">
                <td
                  :key="'bl' + t.num"
                  :class="{ rank1: t.rank === 1 }"
                  class="rankLeftSide"
                  colspan="4"
                >
                  <div v-if="t.tied">Tied</div>
                </td>
                <td
                  :key="'rs' + t.num"
                  :class="{ rank1: t.rank === 1 }"
                  class="rank"
                >
                  {{ t.allRoundsTotal }}
                </td>
              </template>
            </tr>
            <tr v-if="showExtraFooter">
              <th></th>
              <th></th>
              <th></th>
              <th
                v-for="t in teams"
                :key="t.num"
                :class="{ rank1: t.rank === 1 }"
                class="name"
                colspan="5"
              >
                {{ t.name }}
              </th>
            </tr>
          </tfoot>
        </table>
        <table class="summary">
          <thead>
            <tr>
              <th>Who</th>
              <td>Took Bid</td>
              <td>Won</td>
              <td>Lost</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="s in summary" :key="s.id">
              <th>{{ s.name }}</th>
              <td>{{ s.topBids }}</td>
              <td>{{ s.won }}</td>
              <td>{{ s.lost }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="NoScores">First round not completed yet!</div>
      <div slot="footer" class="dialogFooter">
        <el-button v-on:click="handleClose">Close</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ScoreSheet",
  props: {
    visible: Boolean,
  },
  data: function () {
    return {
      teams: [],
      summary: [],
      dataRows: [],
    };
  },
  computed: {
    shared() {
      return this.$root.shared;
    },
    showExtraFooter() {
      return this.numDataRows > 8; // for long lists
    },
    numDataRows() {
      return this.dataRows.length;
    },
    showTotalBids() {
      return false;
    },
    showNumCards() {
      return false;
    },
  },
  watch: {
    "shared.people.length": function () {
      this.buildScoreTable();
    },
    "shared.rounds": function () {
      this.buildScoreTable();
    },
  },

  mounted() {
    this.buildScoreTable();

    window.addEventListener("keyup", this.keyPressed);
  },
  beforeDestroy: function () {
    window.removeEventListener("keyup", this.keyPressed);
  },

  methods: {
    keyPressed(ev) {
      if (!this.visible) return;

      switch (ev.key) {
        case "Enter":
          this.handleClose();
          break;
      }
    },
    opened() {
      this.$el.querySelector("#body").focus({ preventScroll: true });
    },
    logoSymbolClass(suitKey) {
      return "logo-" + suitKey;
    },
    logoSymbolUrl(suitKey) {
      if (suitKey === "e" || suitKey === "w") return "";
      return require(`@/assets/standard2/suit-${suitKey}-s.png`);
    },

    showSuit(key) {
      var suit = this.shared.deckType.suits
        ? this.shared.deckType.suits[key]
        : null;
      if (!suit) return key;

      return suit.symbol;
    },
    getSuitColor(key) {
      var suit = this.shared.deckType.suits
        ? this.shared.deckType.suits[key]
        : null;
      if (!suit) return key;

      return suit.class;
    },
    handleClose() {
      this.$emit("closeMe");
    },
    buildScoreTable() {
      var refPeople = this.shared.members.slice();
      refPeople.forEach((p, i) => {
        p.inTeam = i % 2; // two teams
        p.won = 0;
        p.lost = 0;
      });

      var teams = [
        {
          num: 0,
          peopleInTeam: refPeople.filter((p) => p.inTeam === 0),
          allRoundsTotal: 0,
        },
        {
          num: 1,
          peopleInTeam: refPeople.filter((p) => p.inTeam === 1),
          allRoundsTotal: 0,
        },
      ];

      this.dataRows = [];

      this.shared.rounds.forEach((round) => {
        var row = {
          id: round.id,
          suit: round.suit,
          bidWinner: round.bidWinner,
          bidWinnerName: refPeople.find((p) => p.id === round.bidWinner).name,
          bid: round.bid,
          teamsInRow: [{}, {}],
        };

        // var peopleInRound = refPeople.map(p => round.people[p.id]);

        teams.forEach((team, i) => {
          var personScoreInfos = team.peopleInTeam.map(
            (p) => round.players[p.id]
          );

          var p0 = personScoreInfos[0];
          var failed = p0.score < 0;

          team.allRoundsTotal += p0.score;

          var teamInRow = row.teamsInRow[i];

          teamInRow.runningTotal = team.allRoundsTotal;

          var sep = "<span>&</span>";
          teamInRow.meld =
            personScoreInfos
              .map((p) => p.myMeld)
              .filter((s) => s !== undefined)
              .join(sep) || p0.teamMeld;
          teamInRow.points =
            personScoreInfos
              .map((p) => p.myPoints)
              .filter((s) => s !== undefined)
              .join(sep) || p0.teamPoints;
          teamInRow.taken = personScoreInfos
            .map((p) => p.taken)
            .filter((s) => s !== undefined)
            .join(sep);
          teamInRow.bidWinner = !!personScoreInfos.find((p) => p.bidWinner);

          if (teamInRow.bidWinner) {
            var refPerson = refPeople.find((p) => p.id === round.bidWinner);
            refPerson.lost += failed ? 1 : 0;
          }

          teamInRow.failed = failed;
          teamInRow.score = failed ? p0.failedScore : p0.score;

          teamInRow.points += personScoreInfos.find(
            (p) => p.lastTrick || p.teamBonus
          )
            ? "<span>+1</span>"
            : "";

          // t.people.forEach(p => {

          //   var pInfo = round.players[p.id];

          //   // bid,score,taken
          //   t.score += pInfo.score;
          //   var ok = pInfo.bid === pInfo.taken;
          //   row[t.num] = {
          //     bid: pInfo.bid,
          //     taken: pInfo.taken,
          //     pts: pInfo.score,
          //     score: t.score,
          //     state: ok ? 'ok' : 'missed'
          //   }
          // });
        });

        // find first in this row
        var topScore = 0;
        row.teamsInRow.forEach((t) => {
          if (t.runningTotal > topScore) topScore = t.runningTotal;
        });
        row.teamsInRow.forEach((t) => {
          t.top = t.runningTotal === topScore;
        });

        row.failed = !!row.teamsInRow.find((t) => t.failed);

        this.dataRows.push(row);
      });

      var topScore = Math.max(teams[0].allRoundsTotal, teams[1].allRoundsTotal);
      this.shared.topScore = topScore;

      teams.forEach((t) => {
        t.name = t.peopleInTeam.map((p) => p.name).join(" & ");
        t.rank = t.allRoundsTotal === topScore ? 1 : 2;
      });

      var rank = {};
      teams.forEach((t) => {
        if (!rank[t.rank]) {
          rank[t.rank] = [];
        }
        rank[t.rank].push(t);
      });

      Object.keys(rank).forEach((r) => {
        if (rank[r].length > 1) {
          rank[r].forEach((t) => {
            t.tied = true;
          });
        }
      });

      this.teams = teams;

      // summary
      var summary = refPeople.map((p) => {
        var x = {
          name: p.name,
          key: p.id,
          topBids: this.shared.rounds.filter((r) => r.bidWinner === p.id)
            .length,
          lost: p.lost,
        };
        x.won = x.topBids - x.lost;
        return x;
      });

      summary.sort((a, b) => {
        if (a.topBids === b.topBids) {
          return a.won > b.won ? -1 : 1;
        }

        return a.topBids > b.topBids ? -1 : 1;
      });

      this.summary = summary;
    },
  },
};
</script>

<style lang="less">
.ScoreSheet_Pinochle {
  .dialogFooter {
    text-align: right;
  }
  .NoScores {
    font-size: 1.1em;
    margin: 2em auto;
    text-align: center;
  }

  table {
    border-collapse: collapse;
    margin: 0 auto;
  }

  td,
  th {
    padding: 5px 4px 5px 5px;
    text-align: center;
    white-space: nowrap;
  }
  th.bottom {
    vertical-align: bottom;
  }
  th.suit {
    padding: 3px 0 0 3px;
    img {
      height: 18px;
    }
  }

  .bid {
    border-right: 1px solid black;
  }
  .rank {
    border-right: 1px solid black;
  }
  .info {
    background-color: #f9f9f9;
    span {
      font-size: 75%;
      padding-left: 3px;
    }
  }
  .info2 {
    background-color: #f2f2f2;
    border-right: 1px solid #aaa;
  }
  .info,
  .info2 {
    span {
      font-size: 75%;
      color: #999;
      padding: 0 2px;
    }
  }

  .rank1 {
    background-color: #c1fcc1;
  }

  .bid,
  .got {
    &.ok {
      background-color: #e1ffe1;
    }
    &.viewer {
      background-color: #fff;
    }
    &.missed {
      background-color: #ffebeb;
    }
  }

  .red {
    color: #de2626;
  }
  .black {
    color: black;
  }

  .score {
    border-left: 1px solid #aaa;
    border-right: 1px solid black;
    &.played {
      background-color: #f9f9f9;
    }
  }

  tr.failed {
    .bid {
      background-color: #ffebeb;
      color: #de2626;
    }
  }

  .bidWinner {
    background-color: #e1ffe1;
    &.failed {
      background-color: #ffebeb;
    }
  }
  .topScore {
    background-color: #c1fcc1;
  }

  .name {
    border: 1px solid black;
  }

  .scores {
    tbody {
      tr {
        border-top: 1px solid grey;
      }
    }
  }

  tfoot {
    tr:first-child {
      border-top: 3px double #000;
    }
    td,
    th {
      padding: 2px 3px;
    }
    .rankLeftSide {
      text-align: right;
    }
  }

  .rules {
    margin: 2em auto 0;
    width: 39em;
    text-align: left;
  }
  // tbody {
  //   tr:nth-child(even) {
  //     background-color: #f7f7f7;
  //   }
  // }

  .summary {
    border: 1px solid grey;
    margin: 1em auto;

    thead {
      border-bottom: 1px solid grey;
    }
    th {
      text-align: left;
      border-right: 1px solid grey;
    }
    td:nth-child(2) {
      border-right: 1px solid grey;
    }
    th,
    td {
      padding: 1px 5px;
    }
    tbody {
      tr {
        border-bottom: 1px solid lightgrey;
        &:last-child {
          border-bottom: 1px solid grey;
        }
      }
    }
  }
}
</style>
